import { gql } from '@apollo/client';

export const InsertTicketMutation = gql`
  mutation InsertTicket($detail: jsonb, $status: String, $type: String) {
    insert_ticketing_tickets_one(
      object: { status: $status, type: $type, detail: $detail }
    ) {
      id
    }
  }
`;

export const DeleteTicketMutation = gql`
  mutation DeleteTicketMutation($ticketId: uuid!) {
    delete_ticketing_tickets_by_pk(id: $ticketId) {
      id
    }
  }
`;

export const UpdateTicketMutation = gql`
  mutation UpdateTicketMutation($ticketId: uuid!, $detail: jsonb) {
    update_ticketing_tickets_by_pk(
      pk_columns: { id: $ticketId }
      _set: { detail: $detail }
    ) {
      id
      detail
    }
  }
`;

export const TicketQuery = gql`
  query TicketQuery(
    $limit: Int
    $offset: Int
    $status: [String!]
    $type: [String!]
    $practiceFilter: jsonb_comparison_exp
  ) {
    tickets: ticketing_tickets(
      order_by: { createdAt: desc }
      where: {
        _and: {
          status: { _in: $status }
          type: { _in: $type }
          detail: $practiceFilter
        }
      }
      limit: $limit
      offset: $offset
    ) {
      detail
      id
      status
      type
      createdAt
      ticketsFiles {
        fileId
        id
      }
    }
    aggregating: ticketing_tickets_aggregate(
      where: {
        _and: {
          status: { _in: $status }
          type: { _in: $type }
          detail: $practiceFilter
        }
      }
      order_by: { createdAt: desc }
    ) {
      aggregate {
        count
      }
    }
    allPractices: practices {
      id
      handler
      name
    }
  }
`;

export const practiceProfileTicketQuery = gql`
  query practiceProfileTicketQuery(
    $limit: Int
    $offset: Int
    $practiceFilter: jsonb_comparison_exp
  ) {
    tickets: ticketing_tickets(
      order_by: { createdAt: desc }
      where: { _and: { status: { _neq: "closed" }, detail: $practiceFilter } }
      limit: $limit
      offset: $offset
    ) {
      detail
      id
      status
      type
      createdAt
      ticketsFiles {
        fileId
        id
      }
    }
    aggregating: ticketing_tickets_aggregate(
      where: { _and: { detail: $practiceFilter, status: { _neq: "closed" } } }
      order_by: { createdAt: desc }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const UpdateTicketStatus = gql`
  mutation TicketMutation($id: uuid!, $status: String) {
    update_ticketing_tickets_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
    }
  }
`;

export const insertTicketsFiles = gql`
  mutation insertTicketsFiles(
    $objects: [ticketing_tickets_files_insert_input!]!
  ) {
    insert_ticketing_tickets_files(objects: $objects) {
      returning {
        fileId
        id
        ticketId
      }
    }
  }
`;

export const VaccineRequestsQuery = gql`
  query VaccineRequestsQuery($limit: Int, $offset: Int) {
    vaccineRequests: ticketing_tickets(
      where: { type: { _eq: "Vaccine_order" }, status: { _eq: "requested" } }
      limit: $limit
      offset: $offset
    ) {
      id
      type
      status
      detail
    }
    aggregating: ticketing_tickets_aggregate(
      where: { type: { _eq: "Vaccine_order" }, status: { _eq: "requested" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const VaccineRequestByPracticeQuery = gql`
  query VaccineRequestByPracticeQuery($practiceFilter: jsonb_comparison_exp) {
    vaccineRequests: ticketing_tickets(
      where: {
        type: { _eq: "Vaccine_order" }
        status: { _eq: "requested" }
        detail: $practiceFilter
      }
    ) {
      id
      type
      status
      detail
    }
  }
`;

export const InsertVaccineRequestsMutation = gql`
  mutation InsertVaccineRequestsMutation(
    $objects: [ticketing_tickets_insert_input!]!
  ) {
    insert_ticketing_tickets(objects: $objects) {
      affected_rows
    }
  }
`;
