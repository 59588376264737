import FullLayout from '@components/FullLayout';
import React, { useState } from 'react';
import { Container, Header, Icon, Menu, Segment, Tab } from 'semantic-ui-react';
import { BorrowedVaccinesTable } from './BorrowedVaccinesTable';
import { usePractice } from '@bluefox/contexts/Practice';
import { BorrowingHistoryViewHandler } from './BorrowingHistoryViewHandler';
import CurrentCycle from './CurrentCycle';
import ReportPossibleInconsistency from './ReportPossibleInconsistency';

export const BorrowingScreen = () => {
  const practice = usePractice();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const handleTabChange = (activeIndex: number) => {
    setActiveTabIndex(activeIndex);
  };

  const tabs = [
    {
      menuItem: <Menu.Item key="borrowingCases">Borrowing Cases</Menu.Item>,
      pane: (
        <Tab.Pane>
          <BorrowedVaccinesTable practiceId={practice.id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: <Menu.Item key="borrowingSwap">Swap</Menu.Item>,
      pane: (
        <Tab.Pane>
          <CurrentCycle practiceId={practice.id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: <Menu.Item key="borrowingHistory">History</Menu.Item>,
      pane: (
        <Tab.Pane>
          <BorrowingHistoryViewHandler practiceId={practice.id} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <FullLayout>
      <Container fluid>
        <Segment
          style={{ display: 'flex', justifyContent: 'space-between' }}
          padded
          basic
        >
          <Header as="h1">
            <Icon name="calendar" />
            Borrowing
          </Header>
          <ReportPossibleInconsistency />
        </Segment>
        <Tab
          panes={tabs}
          renderActiveOnly={false}
          activeIndex={activeTabIndex}
          onTabChange={(e, data) => handleTabChange(data.activeIndex as number)}
        />
      </Container>
    </FullLayout>
  );
};
