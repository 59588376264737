import { gql } from '@apollo/client';

export const InsertInventoryOrderWithInventoryMutation = gql`
  mutation InsertInventoryOrderWithInventoryMutation(
    $practiceId: uuid!
    $amount: numeric!
    $date: timestamptz!
    $doses: Int!
    $inventoryExpiration: date
    $invoiceDue: date
    $orderNumber: String
    $lot: String
    $statusOrder: order_statuses_enum!
    $statusInventory: inventory_statuses_enum!
    $statusLog: jsonb!
    $vaccineId: uuid!
    $vfc: Boolean!
  ) {
    insert_inventory_orders_one(
      object: {
        practiceId: $practiceId
        amount: $amount
        date: $date
        doses: $doses
        inventoryExpiration: $inventoryExpiration
        lot: $lot
        status: $statusOrder
        statusLog: $statusLog
        vaccineId: $vaccineId
        vfc: $vfc
        inventory: {
          data: {
            practiceId: $practiceId
            vaccineId: $vaccineId
            lot: $lot
            expiration: $inventoryExpiration
            status: $statusInventory
            doses: $doses
            vfc: $vfc
          }
        }
      }
    ) {
      id
    }
  }
`;

export const BorrowingInventoryAdjustments = gql`
  query BorrowingInventoryAdjustments(
    $criteria: inventory_adjustments_bool_exp
  ) {
    adjustments: inventory_adjustments(
      where: $criteria
      order_by: { createdAt: asc }
    ) {
      id
      createdAt
      statusLog
    }
    aggregating: inventory_adjustments_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const BorrowingInventoryAdjustmentsDetails = gql`
  query BorrowingInventoryAdjustmentsDetails(
    $criteria: inventory_adjustment_details_bool_exp
  ) {
    details: inventory_adjustment_details(
      where: $criteria
      order_by: { inventory_adjustment: { vfc: asc }, type: asc }
    ) {
      id
      currentDoses
      newDoses
      type
      inventory {
        vaccine {
          name
          types
          saleNdc
        }
        lot
        vfc
      }
    }
  }
`;

export const BorrowingSummaryPdfExportQuery = gql`
  query BorrowingInventoryAdjustmentsDetails(
    $criteria: inventory_adjustment_details_bool_exp
  ) {
    details: inventory_adjustment_details(
      where: $criteria
      order_by: { inventory_adjustment: { vfc: asc }, type: asc }
    ) {
      id
      currentDoses
      newDoses
      type
      inventory {
        vaccine {
          name
          types
          saleNdc
        }
        lot
        vfc
      }
      targetClaimVfcInconsistencies {
        createdAt
        id
        practiceId
        practice {
          id
          name
        }
        claimId
        inventoryId
        vaccinationId
        inventory {
          id
          expiration
          vfc
          lot
          doses
          vaccine {
            id
            name
            aka
            types
            saleNdc
          }
        }
        type
        status
        claimUpdatesId
        claim {
          id
          givenAt
          insuranceCompanyName
          practicePatient {
            patientData {
              birthdate
              firstName
              lastName
            }
          }
          updates(
            where: {
              _not: {
                metadata: { _contains: { insurance: { insuranceName: "" } } }
              }
              metadata: { _has_key: "insurance" }
            }
            distinct_on: claimId
            order_by: [{ claimId: asc }, { createdAt: desc }]
          ) {
            claimId
            id
            status
            insuranceName: metadata(path: "$.insurance.insuranceName")
            insuranceCompanyId: metadata(path: "$.insurance.insuranceCompanyId")
            memberId: metadata(path: "$.insurance.memberId")
            vfcEligible: metadata(path: "$.insurance.vfcEligible")
            vfcCriteria: metadata(path: "$.insurance.vfcCriteria")
            payerId: metadata(path: "$.insurance.payerID")
          }
        }
      }
      sourceClaimVfcInconsistencies {
        createdAt
        id
        practiceId
        practice {
          id
          name
        }
        claimId
        inventoryId
        vaccinationId
        inventory {
          id
          expiration
          vfc
          lot
          doses
          vaccine {
            id
            name
            aka
            types
            saleNdc
          }
        }
        type
        status
        claimUpdatesId
        claim {
          id
          givenAt
          insuranceCompanyName
          practicePatient {
            patientData {
              birthdate
              firstName
              lastName
            }
          }
          updates(
            where: {
              _not: {
                metadata: { _contains: { insurance: { insuranceName: "" } } }
              }
              metadata: { _has_key: "insurance" }
            }
            distinct_on: claimId
            order_by: [{ claimId: asc }, { createdAt: desc }]
          ) {
            claimId
            id
            status
            insuranceName: metadata(path: "$.insurance.insuranceName")
            insuranceCompanyId: metadata(path: "$.insurance.insuranceCompanyId")
            memberId: metadata(path: "$.insurance.memberId")
            vfcEligible: metadata(path: "$.insurance.vfcEligible")
            vfcCriteria: metadata(path: "$.insurance.vfcCriteria")
            payerId: metadata(path: "$.insurance.payerID")
          }
        }
      }
    }
  }
`;
