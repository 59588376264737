import { gql } from '@apollo/client';

export const PatientDataFragment = gql`
  fragment patientFull on patients {
    id
    firstName
    middleName
    lastName
    preferredName
    sex
    birthdate
    raceAndCreed
    ethnicity
    email
    phone
    address
    fromExtension
    hasBasicInfo
    guardians(order_by: [{ active: desc }, { createdAt: desc }]) {
      id
      relationship
      active
      guardian {
        id
        firstName
        lastName
        phone
        email
        birthdate
        sex
        address
      }
    }
  }
`;

export const PatientsQuery = gql`
  query PatientsQuery(
    $criteria: practice_patients_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    aggregating: practice_patients_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
    patients: practice_patients(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { patientData: { firstName: asc, lastName: asc } }
    ) {
      id
      mrn
      active
      patientData {
        id
        preferredName
        firstName
        lastName
        middleName
        sex
        birthdate
        raceAndCreed
        ethnicity
        email
        phone
        address
      }
      insurances(where: { primary: { _eq: true } }) {
        id
        active
        primary
        vfcCriteria
        company {
          name
        }
      }
    }
  }
`;

export const IncompletePatientsQuery = gql`
  query IncompletePatientsQuery(
    $criteria: patients_bool_exp
    $limit: Int!
    $offset: Int!
  ) {
    aggregating: patients_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
    patients(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { firstName: asc }
    ) {
      id
      fullName
      birthdate
      fromExtension
      hasBasicInfo
      completedAt
      practices {
        mrn
        practice {
          name
        }
        insurances {
          id
          vfcEligible
        }
      }
    }
  }
`;

export const UpdateIncompletePatientReview = gql`
  mutation UpdateIncompletePatientReview(
    $id: uuid!
    $completedAt: timestamptz!
  ) {
    update_patients_by_pk(
      pk_columns: { id: $id }
      _set: { completedAt: $completedAt }
    ) {
      id
      fullName
      completedAt
    }
  }
`;

export const PracticePatientPickerQuery = gql`
  query PracticePatientPickerQuery($criteria: [patients_bool_exp!]) {
    patients: practice_patients(where: { patientData: { _or: $criteria } }) {
      id
      patientData {
        fullName
        birthdate
      }
    }
  }
`;

export const PracticePatientByIdQuery = (isStaff: boolean) => gql`
  query PracticePatientByIdQuery($id: uuid!) {
    practicePatient: practice_patients_by_pk(id: $id) {
      id
      consentForIis
      active
      mrn
      practice {
        id
        handler
        organization {
        id
      }
        timezone
        settings
      }
      patientData {
        ...patientFull
      }
      ${isStaff ? 'notes' : ''}
    }
  }

  ${PatientDataFragment}
`;

export const PracticePatientProfileQuery = gql`
  query PracticePatientProfileQuery($id: uuid!) {
    practicePatient: practice_patients_by_pk(id: $id) {
      id
      active
      immunizationSyncedAt
      immunizationSyncError
      consentForIis
      mrn
      vaccinations {
        id
        givenAt
        dose
        vaccine {
          id
          types
        }
      }
      practice {
        timezone
      }
      patientData {
        ...patientFull
      }
      practice {
        handler
        name
        id
        issName
        settings
        organization {
          id
          name
          handler
          practices {
            id
            name
          }
        }
      }
      insurances(
        order_by: { createdAt: desc }
        where: { primary: { _eq: true } }
        limit: 1
      ) {
        id
        verificationStatus
        verifiedAt
        vfcEligible
        vfcCriteria
        memberId
        primary
        company {
          name
        }
        active
      }
      isVfcConfirmed
      appointments(
        where: { time: { _gte: "today", _lt: "tomorrow" } }
        limit: 1
      ) {
        id
        time
        status
      }
      futureAppointments: appointments(
        where: { time: { _gte: "today" } }
        limit: 1
      ) {
        id
        time
        status
      }
    }
  }

  ${PatientDataFragment}
`;

export const SavePracticePatientMutation = gql`
  mutation SavePracticePatientMutation(
    $object: practice_patients_insert_input!
  ) {
    practicePatient: insert_practice_patients_one(
      object: $object
      on_conflict: {
        constraint: practice_patients_pkey
        update_columns: [patientId, active, consentForIis, mrn]
      }
    ) {
      id
      active
      mrn
      consentForIis
      patientData {
        ...patientFull
      }
    }
  }

  ${PatientDataFragment}
`;

export const UpdatePatientMutation = gql`
  mutation UpdatePatientMutation(
    $id: uuid!
    $firstName: String
    $lastName: String
    $sex: String
    $birthdate: date
    $raceAndCreed: String
    $email: String
    $phone: String
    $address: jsonb
  ) {
    patient: update_patients_by_pk(
      _set: {
        firstName: $firstName
        lastName: $lastName
        sex: $sex
        birthdate: $birthdate
        raceAndCreed: $raceAndCreed
        email: $email
        phone: $phone
        address: $address
      }
      pk_columns: { id: $id }
    ) {
      ...patientFull
    }
  }

  ${PatientDataFragment}
`;

export const SyncImmunizationsMutation = gql`
  mutation SyncImmunizations($practicePatientId: uuid!) {
    SyncImmunizations(practicePatientId: $practicePatientId) {
      newData
      error
    }
  }
`;

export const VfcConfirmedMutation = gql`
  mutation VfcConfirmedMutation($practicePatientId: uuid!) {
    update_practice_patients_by_pk(
      pk_columns: { id: $practicePatientId }
      _set: { isVfcConfirmed: true }
    ) {
      id
      isVfcConfirmed
    }
  }
`;

// add handler
export const PracticePatientByMrnQuery = gql`
  query PracticePatientByMrnQuery($mrn: String, $practiceIds: [uuid!]!) {
    practicePatient: practice_patients(
      where: { mrn: { _eq: $mrn }, practiceId: { _in: $practiceIds } }
    ) {
      id
    }
  }
`;

export const CreatePatientMutation = gql`
  mutation CreatePatientMutation(
    $firstname: String
    $lastname: String
    $dob: date
    $sex: String
    $address: jsonb = {}
  ) {
    createdPatient: insert_patients_one(
      object: {
        firstName: $firstname
        lastName: $lastname
        birthdate: $dob
        sex: $sex
        fromExtension: true
        address: $address
      }
    ) {
      id
    }
  }
`;

export const CreatePracticePatientMutation = gql`
  mutation CreatePracticePatientMutation(
    $patientId: uuid!
    $practiceId: uuid!
    $mrn: String!
  ) {
    createdPracticePatient: insert_practice_patients_one(
      object: {
        patientId: $patientId
        practiceId: $practiceId
        mrn: $mrn
        active: true
      }
    ) {
      id
    }
  }
`;

export const SaveConsentForIisMutation = gql`
  mutation SaveConsentForIisMutation(
    $practicePatientId: uuid!
    $consentForIis: Boolean
  ) {
    practicePatient: update_practice_patients_by_pk(
      pk_columns: { id: $practicePatientId }
      _set: { consentForIis: $consentForIis }
    ) {
      id
      consentForIis
    }
  }
`;

export const GetPatientForValidation = gql`
  query GetPatientForValidation($patientCriteria: practice_patients_bool_exp) {
    practicePatients: practice_patients(where: $patientCriteria) {
      id
      mrn
      practice {
        id
      }
      patientData {
        id
        birthdate
        firstName
        lastName
      }
    }
  }
`;

export const GuardiansByPracticePatientId = gql`
  query GuardiansByPracticePatientId($id: uuid!) {
    practicePatient: practice_patients(where: { id: { _eq: $id } }) {
      patientData {
        guardians {
          guardian {
            id
            firstName
            lastName
            birthdate
            email
            phone
          }
        }
      }
    }
  }
`;
