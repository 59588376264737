import { SemanticCOLORS } from 'semantic-ui-react';

const SemanticCOLORSToString: { [key in SemanticCOLORS]: string } = {
  red: '#DB2828',
  orange: '#F5714B',
  yellow: '#FFD700',
  olive: '#32CD32',
  green: '#016936',
  teal: '#5DBCD1',
  blue: '#467597',
  violet: '#EE82EE',
  purple: '#B413EC',
  pink: '#FF1493',
  brown: '#A52A2A',
  grey: '#A0A0A0',
  black: '#16324F',
};

export function SemanticColorToString(color: SemanticCOLORS) {
  return SemanticCOLORSToString[color];
}
// www.color-name.com
export const COLORS_BY_NAME = {
  'Carolina Blue': '#5DB6D1',
  'Pale Cornflower Blue': '#AED6F1',
  'Tiffany Blue': '#00B5AD',
  'Permanent Geranium Lake': '#DB2828',
  'Raisin Black': '#202020',
  'Granite Gray': '#666666',
  White: '#FFFFFF',
  'Roman Silver': '#868E96',
  'Metallic Seaweed': '#0C8599',
  'Pacific Blue': '#15AABF',
  'Bright Gray': '#EAE9E8',
  'Maya Blue': '#54C8FF',
  'Space Cadet': '#16324F',
  'Sea Serpent': '#5DBCD1',
  Jet: '#343434',
  'Midnight Green (Eagle Green)': '#003E53',
} as const;
