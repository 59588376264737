import { PracticeAccount } from './Account';
import { Inventory } from './Inventory';
import { PracticePatient } from './Patient';
import { Practice } from './Practice';
import { Vaccine } from './Vaccine';

export enum Limbs {
  'arm' = 'arm',
  'thigh' = 'thigh',
}

export enum Sides {
  'left' = 'left',
  'right' = 'right',
}

export enum Sites {
  'upper' = 'upper',
  'deltoid' = 'deltoid',
  'anterolateral' = 'anterolateral',
  'vastuslateralis' = 'vastuslateralis',
}

export enum VaccinationSites {
  'leftArm' = 'left_arm',
  'rightArm' = 'right_arm',
  'leftThigh' = 'left_thigh',
  'rightThigh' = 'right_thigh',
  'leftArmUpper' = 'left_arm_upper',
  'rightArmUpper' = 'right_arm_upper',
  'leftArmDeltoid' = 'left_arm_deltoid',
  'rightArmDeltoid' = 'right_arm_deltoid',
  'leftThighAnterolateral' = 'left_thigh_anterolateral',
  'rightThighAnterolateral' = 'right_thigh_anterolateral',
  'leftThighVastusLateralis' = 'left_thigh_vastuslateralis',
  'rightThighVastusLateralis' = 'right_thigh_vastuslateralis',
}

export enum VaccinationRoutes {
  IM = 'IM',
  SC = 'SC',
  PO = 'PO',
  IN = 'IN',
  ID = 'ID',
}

export interface Vaccination {
  id?: string;
  practicePatientId?: string;
  practicePatient?: PracticePatient;
  vaccineId?: string;
  vaccine?: Vaccine;
  barcode?: string;
  givenAt: Date;
  reviewedAt?: Date;
  visDate?: Date;
  visPublicationDate?: Date;
  practice?: Practice;
  providerId?: string;
  provider?: PracticeAccount;
  prescribedBy?: string;
  prescriber?: PracticeAccount;
  inventoryId?: string;
  inventory?: Inventory;
  site?: VaccinationSites | null;
  siteDescription?: {
    description?: string;
    value?: VaccinationSites;
  };
  route?: VaccinationRoutes;
  lot?: string;
  historic?: boolean;
  billed?: boolean;
  createdAt?: Date;
  immunizationObservation?: object[] | null;
  practiceId?: string;
  doseNumber?: number;
  dose?: number;
  immunizationReportedAt?: Date;
  immunizationReportError?: ImmunizationReportError | null;
  emrUploadedBy?: string;
  cirReportedBy?: string;
  accountId?: string;
  claimId?: string | null;
  warnings?: string[];
  bot?: {
    status: string;
  };
  athena?: {
    status: string;
  };
  develo?: {
    status: string;
  };
  inconsistentVaccinationError?: string;
  notes?: string;
}

export interface ImmunizationReportError {
  data: {
    msg: string;
    oldData?: any; // raw vaccination
    action: string;
    registryValidationErrors?: string[];
  };
  code: number;
  type: ErrorType;
}

type ErrorType = 'NON_FATAL_ERROR' | 'FATAL_ERROR';

export interface VaccinationCountPerWeek {
  count: number;
  week: number;
  fromDate: Date;
  toDate: Date;
  vfcEligible: boolean;
}

export interface VaccinationCountsPerWeek {
  vaccinationsPerWeek: VaccinationCountPerWeek[];
}

export interface VaccinationCountPerMonth {
  count: number;
  month: number;
  fromDate: Date;
  toDate: Date;
}

export interface MisusedInventoryCountsPerMonth {
  misusedVfc: VaccinationCountPerMonth[];
  misusedPrivate: VaccinationCountPerMonth[];
}

export interface VaccinatedPatientsPerWeek {
  count: number;
  week: number;
  fromDate: Date;
  toDate: Date;
}

export interface VaccinatedPatientsPerWeeks {
  vaccinatedPatientsPerWeeks: VaccinatedPatientsPerWeek[];
}

export const vaxSyncDropdownMenuOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed',
  },
];

export const emrOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'canid',
    text: 'Canid',
    value: 'canid',
  },
  {
    key: 'practice',
    text: 'Practice',
    value: 'practice',
  },
];

export const cirOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'auto',
    text: 'Auto',
    value: 'auto',
  },
  {
    key: 'manual',
    text: 'Manual',
    value: 'manual',
  },
];

export interface DuplicatedVaccination {
  id: string;
  lot: string;
  vaccine: Vaccine;
  saleNdc10: string;
  useNdc10: string;
  givenAt: Date;
}

export interface RecentVaccination {
  id: string;
  barcode?: string;
  lot: string;
  vaccine: Vaccine;
}

export enum ActionType {
  INSERT = 'insert',
  REMOVE = 'remove',
}

export interface VaccinationView {
  id: string;
  practiceId: string;
  patientFirstName: string;
  patientLastName: string;
  patientBirthdate: Date;
  patientAddress: {
    street: string;
    city: string;
    state: string;
    zip: string;
  };
  insuranceCompanyName: string;
  insuranceMemberId: string;
  vaccineName: string;
  types: string[];
  dosage?: {
    dose: number;
    unit: string;
  }[];
  manufacturer: string;
  saleNdc: string;
  lot: string;
  inventoryVfc: boolean; // inventory vfc
  prescriberFirstName: string;
  prescriberLastName: string;
  prescriberTitle: string;
  givenByFirstName: string;
  givenByLastName: string;
  givenByTitle: string;
  site: string;
  route: string;
  givenAt: Date;
  visPublicationDate: Date;
  createdAt: Date;
  emrUploadedBy: 'pending' | 'canid' | 'practice';
  registryReportedBy: 'pending' | 'auto' | 'manual';
  action: ActionType;
  mrn: string;
  inventoryExpiration: Date;
  vfcCriteria: string;
  vfcEligible: boolean;
  modified: boolean;
  consentForIis: boolean;
  practicePatientId: string;
}
