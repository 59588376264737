import { ScannableItem } from '@bluefox/store/scannableItems';
import {
  VaccinationRoutes,
  VaccinationSites,
} from '@bluefox/models/Vaccination';
import ScannedItemCard from './ScannedItemCard/ScannedItemCard';
import { usePractice } from '@bluefox/contexts';
interface ForwardedProps {
  onRemove: (code: string, inventoryId?: string) => void;
  onSiteChange: (code: string, site: VaccinationSites) => void;
  onRouteChange: (code: string, route: VaccinationRoutes) => void;
  onVisDateChange: (code: string, vaccinationVisDate: Date | null) => void;
}
interface ScannedItemsProps extends ForwardedProps {
  patientAge: number;
  givenAt: Date;
  scanned: ScannableItem[];
  showRemove?: boolean;
  allowRemove?: boolean;
  vfc: boolean;
  duplicatedVaccineNdcs?: (string | undefined)[] | undefined;
  practicePatientId: string;
  addWarning: (code: string, warnings: string[] | undefined) => void;
}

const ScannedItems = ({
  patientAge,
  scanned,
  showRemove = true,
  allowRemove = true,
  onRemove,
  onSiteChange,
  onRouteChange,
  onVisDateChange,
  vfc,
  duplicatedVaccineNdcs,
  givenAt,
  practicePatientId,
  addWarning,
}: ScannedItemsProps) => {
  const practice = usePractice();

  return (
    <>
      {scanned.map((item) => (
        <ScannedItemCard
          practiceTimezone={practice.timezone}
          givenAt={givenAt}
          key={item.code}
          patientAge={patientAge}
          item={item}
          showRemove={showRemove}
          allowRemove={allowRemove}
          onRemove={onRemove}
          onSiteChange={onSiteChange}
          onRouteChange={onRouteChange}
          onVisDateChange={onVisDateChange}
          vfc={vfc}
          duplicatedVaccineNdcs={duplicatedVaccineNdcs}
          practicePatientId={practicePatientId}
          addWarning={addWarning}
        />
      ))}
    </>
  );
};

export default ScannedItems;
