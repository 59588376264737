import { notificationStatusEnumToText } from '@bluefox/constants/notifications';
import { ALL, allOption } from '@bluefox/lib/options';
import { NotificationStatus } from '@bluefox/models/Notification';
import React, { CSSProperties } from 'react';
import { Form } from 'semantic-ui-react';

const statusOptionsIT = [
  ...allOption,
  {
    key: NotificationStatus.pending_approval,
    text: notificationStatusEnumToText[NotificationStatus.pending_approval],
    value: NotificationStatus.pending_approval,
  },
  {
    key: NotificationStatus.dismissed,
    text: notificationStatusEnumToText[NotificationStatus.dismissed],
    value: NotificationStatus.dismissed,
  },
  {
    key: NotificationStatus.pending,
    text: notificationStatusEnumToText[NotificationStatus.pending],
    value: NotificationStatus.pending,
  },
  {
    key: NotificationStatus.resolved,
    text: notificationStatusEnumToText[NotificationStatus.resolved],
    value: NotificationStatus.resolved,
  },
  {
    key: NotificationStatus.rejected,
    text: notificationStatusEnumToText[NotificationStatus.rejected],
    value: NotificationStatus.rejected,
  },
];

const statusOptionsPP = [
  ...allOption,
  {
    key: NotificationStatus.pending,
    text: notificationStatusEnumToText[NotificationStatus.pending],
    value: NotificationStatus.pending,
  },
  {
    key: NotificationStatus.resolved,
    text: notificationStatusEnumToText[NotificationStatus.resolved],
    value: NotificationStatus.resolved,
  },
  {
    key: NotificationStatus.rejected,
    text: notificationStatusEnumToText[NotificationStatus.rejected],
    value: NotificationStatus.rejected,
  },
];

type Props = {
  internalTools?: boolean;
  value?: NotificationStatus | typeof ALL;
  onChange?: (value: NotificationStatus | typeof ALL) => void;
  style?: CSSProperties;
};

const NotificationStatusDropdown = ({
  internalTools,
  value,
  onChange,
  style,
}: Props) => {
  return (
    <Form.Dropdown
      style={style}
      id="notification_status_dropdown_filter"
      search
      selection
      options={internalTools ? statusOptionsIT : statusOptionsPP}
      value={value}
      onChange={(_, { value }) => {
        onChange?.(value as NotificationStatus | typeof ALL);
      }}
    />
  );
};

export default NotificationStatusDropdown;
