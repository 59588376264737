import React, { useMemo } from 'react';
import {
  BillingNotificationFirstOptionsEnum,
  BillingNotificationFirstOptionsEnumToText,
} from '@bluefox/constants/notifications';
import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
} from '@bluefox/lib/formatters';
import {
  BillingNotificationContent,
  Notification,
} from '@bluefox/models/Notification';

const renderFirstOptionMessage = (content: BillingNotificationContent) => {
  switch (content.firstOption) {
    case BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION:
      return BillingNotificationFirstOptionsEnumToText[
        content.firstOption
      ].replace('[CPT Code]', content.selectedCptCodes?.join(', ') || ' - ');
    case BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY:
    case BillingNotificationFirstOptionsEnum.INSURANCE_REQUIERES_TO_CONTACT_THEM:
    case BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY:
      return BillingNotificationFirstOptionsEnumToText[content.firstOption];
    case BillingNotificationFirstOptionsEnum.FREE_TEXT:
      return content.freeText;
    default:
      return null;
  }
};

type Props = {
  notification: Notification;
};

const BillingNotificationMessage = ({ notification }: Props) => {
  const content = useMemo(
    () => notification.content as BillingNotificationContent,
    [notification.content]
  );

  return (
    <>
      <p>
        For the patient{' '}
        <b>
          {content?.fullPatienName}, MRN {content?.mrn ?? '-'}
        </b>
        {', DOS '}
        {content?.givenAt
          ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
              formatDateStringToDateTimeZero(
                content?.givenAt as unknown as string
              )
            )
          : '-'}{' '}
        {'. '}
        {renderFirstOptionMessage(content)}
        <>{'. '}</>
        {content.firstOption ===
          BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY && (
          <>
            Please confirm if the patient have an active policy and update the
            patient insurance information.
          </>
        )}
        {content.firstOption ===
          BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY && (
          <>
            Please complete and sign the{' '}
            <a
              href="https://docs.google.com/document/d/1Fgc6c5mFdPvDQxyEq8zz-KseNx6S46l9futGHPGLYYs/edit"
              target="_blank"
              rel="noopener noreferrer"
            >
              document
            </a>{' '}
            and send to the email{' '}
            <a href="mailto:billing@canid.io">billing@canid.io</a>
          </>
        )}
      </p>
      {notification.note && (
        <p>
          <b>Rejected message:</b> {notification.note}
        </p>
      )}
    </>
  );
};

export default BillingNotificationMessage;
