import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PDFPage, PDFDocument, rgb, PDFFont, StandardFonts } from 'pdf-lib';
import { Button, Icon, Modal, Popup, SemanticSIZES } from 'semantic-ui-react';
import { PracticePatient } from '@bluefox/models/Patient';
import {
  calculateTextWidth,
  buildEnrolledInText,
  getVaccineNameIndexCoords,
  getLotIndexCoords,
  getRouteSiteIndexCoords,
  getAdminByIndexCoords,
  drawTextWithUnderLine,
} from './functions';
import { humanizeText } from '@bluefox/lib/humanize';
import { ScannedVacinationConsentPDF } from '@screens/patient/Scan';
import { getAge } from '@bluefox/lib/getAge';
import { VfcCriteria } from '@bluefox/models/Insurances';
import {
  formatDateToMMDDYYYY,
  formatDateToMMDDYYYYV2,
} from '@bluefox/lib/formatters';
import { useApplicationState } from '@bluefox/contexts';

type Props = {
  isIcon?: boolean;
  size?: SemanticSIZES;
  practicePatient: PracticePatient | undefined;
  vaccinations: ScannedVacinationConsentPDF[];
  providerFullName: string | undefined;
  pageBlurred: boolean;
};

const PatientEligibilityScreeningRecordPdf = ({
  isIcon,
  size,
  practicePatient,
  vaccinations,
  providerFullName,
  pageBlurred,
}: Props) => {
  const { isEmbedded } = useApplicationState();
  const [pdfURL, setPdfURL] = useState('');
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [hasInconsistentVaccination, setHasInconsistentVaccination] =
    useState(false);

  //------------ DATE OF ISSUE ------------//
  const drawDateOfIssue = (page: PDFPage) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');

    page.drawText(month, {
      x: 304,
      y: 665,
      size: 10,
      color: rgb(0, 0, 0),
    });

    page.drawText(day, {
      x: 327,
      y: 665,
      size: 10,
      color: rgb(0, 0, 0),
    });

    page.drawText(year, {
      x: 350,
      y: 665,
      size: 10,
      color: rgb(0, 0, 0),
    });
  };

  //----------- DRAW PATIENT INFO -----------//
  const drawPatientInfo = async (
    page: PDFPage,
    pdfDoc: PDFDocument,
    practicePatient: PracticePatient | undefined
  ) => {
    const patientDob = practicePatient
      ? new Date(practicePatient?.patientData.birthdate)
      : new Date();
    const dobpatientDobString =
      practicePatient?.patientData?.birthdate.toString();
    const splittedBirthdate = dobpatientDobString?.split('-');
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const patientFullName = practicePatient?.patientData
      ? `${practicePatient?.patientData.lastName.toUpperCase()}, ${practicePatient?.patientData.firstName.toUpperCase()}`
      : '-';
    const insuranceName =
      practicePatient?.insurances[0]?.company &&
      practicePatient?.insurances[0]?.vfcCriteria !== VfcCriteria.uninsured
        ? practicePatient.insurances[0].company?.name
        : '-';
    const insuranceVfcEligible = practicePatient?.insurances[0]?.vfcEligible
      ? practicePatient?.insurances[0]?.vfcEligible
      : false;
    const vfcCriteria = practicePatient?.insurances[0]?.vfcCriteria
      ? practicePatient?.insurances[0]?.vfcCriteria
      : '-';
    const insuranceMemberId =
      practicePatient?.insurances[0]?.memberId &&
      practicePatient?.insurances[0]?.vfcCriteria !== VfcCriteria.uninsured
        ? practicePatient?.insurances[0]?.memberId
        : '-';

    // Patient's full name
    drawTextWithUnderLine(page, 229, 618, font, 10, patientFullName, 17);

    // Patient's birthdate (month)
    page.drawText(splittedBirthdate ? splittedBirthdate[1] : '', {
      x: 109,
      y: 596,
      size: 10,
      color: rgb(0, 0, 0),
    });

    // Patient's birthdate (day)
    page.drawText(splittedBirthdate ? splittedBirthdate[2] : '', {
      x: 132,
      y: 596,
      size: 10,
      color: rgb(0, 0, 0),
    });

    // Patient's birthdate (year)
    page.drawText(splittedBirthdate ? splittedBirthdate[0] : '', {
      x: 156,
      y: 596,
      size: 10,
      color: rgb(0, 0, 0),
    });

    // Enrolled in
    const enrolledInTitle = () => {
      if (
        (practicePatient?.insurances[0] &&
          practicePatient?.insurances[0].company?.name === 'FLORIDA MEDICAID' &&
          practicePatient?.insurances[0].vfcEligible) ||
        (practicePatient?.insurances[0] && !insuranceVfcEligible)
      ) {
        return 'He/She is enrolled in:';
      } else if (
        getAge(patientDob) < 19 &&
        !practicePatient?.insurances[0]?.company?.name
      ) {
        return 'He/She does not have health insurance and is:';
      } else if (
        practicePatient?.insurances[0] &&
        insuranceVfcEligible &&
        vfcCriteria !== VfcCriteria.uninsured
      ) {
        return 'He/She is enrolled in:';
      } else if (
        getAge(patientDob) < 19 &&
        (!practicePatient?.insurances[0] ||
          practicePatient?.insurances[0]?.vfcCriteria === VfcCriteria.uninsured)
      ) {
        return 'He/She does not have health insurance and is:';
      } else if (
        getAge(patientDob) > 18 &&
        (!practicePatient?.insurances[0] || insuranceVfcEligible)
      ) {
        return 'He/She does not have health insurance and is:';
      } else {
        return 'He/She is:';
      }
    };

    page.drawText(enrolledInTitle(), {
      x: 36,
      y: 571,
      size: 10,
      color: rgb(0, 0, 0),
      font: fontBold,
    });

    const enrolledIn: { text: string; coordX: number } =
      practicePatient && practicePatient?.insurances
        ? buildEnrolledInText(practicePatient.insurances[0], getAge(patientDob))
        : { text: '-', coordX: 0 };

    drawTextWithUnderLine(
      page,
      enrolledIn.coordX,
      570,
      font,
      10,
      enrolledIn.text,
      17
    );

    // Patient insurance
    drawTextWithUnderLine(page, 118, 546, font, 10, `${insuranceName}`, 17);

    // Member ID
    drawTextWithUnderLine(page, 94, 522, font, 10, insuranceMemberId, 17);
  };

  //----------- DRAW VACCINATIONS -----------//
  const drawCenteredVaccinationName = (
    page: PDFPage,
    text: string | undefined,
    font: PDFFont,
    fontSize: number,
    startX: number,
    startY: number,
    width: number,
    height: number
  ) => {
    if (text) {
      const textWidth = calculateTextWidth(font, text, fontSize);

      const centerX = startX + (width - textWidth) / 2;
      const centerY = startY + (height - fontSize) / 2;

      page.drawText(text, {
        x: centerX,
        y: centerY,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
    }
  };

  const drawCenteredLotAndExp = (
    page: PDFPage,
    text: string | undefined,
    font: PDFFont,
    fontSize: number,
    startX: number,
    startY: number,
    width: number,
    height: number
  ) => {
    if (text) {
      const textWidth = calculateTextWidth(font, text, fontSize);

      const centerX = startX + (width - textWidth) / 2;
      const centerY = startY + (height - fontSize) / 2;

      page.drawText(text, {
        x: centerX,
        y: centerY,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
    }
  };

  const drawCenteredRouteSite = (
    page: PDFPage,
    text: string | undefined,
    font: PDFFont,
    fontSize: number,
    startX: number,
    startY: number,
    width: number,
    height: number
  ) => {
    if (text) {
      const textWidth = calculateTextWidth(font, text, fontSize);

      const centerX = startX + (width - textWidth) / 2;
      const centerY = startY + (height - fontSize) / 2;

      page.drawText(text, {
        x: centerX,
        y: centerY,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
    }
  };

  const drawCenteredAdminBy = (
    page: PDFPage,
    text: string | undefined,
    font: PDFFont,
    fontSize: number,
    startX: number,
    startY: number,
    width: number,
    height: number
  ) => {
    if (text) {
      const textWidth = calculateTextWidth(font, text, fontSize);

      const centerX = startX + (width - textWidth) / 2;
      const centerY = startY + (height - fontSize) / 2;

      page.drawText(text, {
        x: centerX,
        y: centerY,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
    }
  };

  const drawVaccinations = async (
    page: PDFPage,
    pdfDoc: PDFDocument,
    vaccinations: ScannedVacinationConsentPDF[]
  ) => {
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const width = 122;
    const height = 44;

    vaccinations.forEach((v, index) => {
      const vaccineNameCoords = getVaccineNameIndexCoords(index, false);
      const vaccineLongNameCoords = getVaccineNameIndexCoords(index, true);
      const lotCoords = getLotIndexCoords(index);
      const routeSiteCoords = getRouteSiteIndexCoords(index);
      const adminByCoords = getAdminByIndexCoords(index);

      setHasInconsistentVaccination(
        vaccinations.some((item) => !item.hasOwnProperty('inventory'))
      );

      // Define max width fot text line
      const maxWidth = 122;

      // Split vaccine name text in lines
      let lines = [];
      let currentLine = '';
      v.vaccine?.name.split(' ').forEach((word) => {
        if (
          currentLine.length === 0 ||
          font.widthOfTextAtSize(currentLine + ' ' + word, 10) < maxWidth
        ) {
          if (currentLine.length > 0) currentLine += ' ';
          currentLine += word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      });
      lines.push(currentLine);

      // Draw vaccine name centered depending it's length
      const textWidth = calculateTextWidth(font, v.vaccine?.name || '', 10);
      // Long vaccine name
      if (v.vaccine?.name && textWidth > 100) {
        const fontSize = 10;
        const lineHeight = fontSize + 2;
        lines.forEach((line, index) => {
          page.drawText(line, {
            x: vaccineLongNameCoords.x,
            y: vaccineLongNameCoords.y - index * lineHeight,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
          });
        });
      } else {
        // Short vaccine Name
        drawCenteredVaccinationName(
          page,
          v.vaccine?.name,
          font,
          10,
          vaccineNameCoords.x,
          vaccineNameCoords.y,
          width,
          height
        );
      }

      // Draw centered lot
      drawCenteredLotAndExp(
        page,
        v.inventory
          ? `${v.inventory?.lot} - ${
              v.inventory
                ? formatDateToMMDDYYYYV2(v.inventory?.expiration.toString())
                : '-'
            }`
          : '',
        font,
        10,
        lotCoords.x,
        lotCoords.y,
        width,
        height
      );

      // Draw centered route and site
      drawCenteredRouteSite(
        page,
        v.site
          ? `${v.route} - ${humanizeText(v.site, {
              capitalize: 'first',
              delimiter: '_',
            })}`
          : `${v.route}`,
        font,
        10,
        routeSiteCoords.x,
        routeSiteCoords.y,
        width,
        height
      );

      // Draw centered provider name
      drawCenteredAdminBy(
        page,
        providerFullName,
        font,
        10,
        adminByCoords.x,
        adminByCoords.y,
        width,
        height
      );
    });
  };

  const fetchPdf = () => {
    const url = process.env
      .REACT_APP_CELEBRATION_PEDIATRICS_PATIENT_ELIGIBILITY_SCREENING_RECORD_URL as string;
    return axios.get(url, { responseType: 'arraybuffer' });
  };

  const downloadModifiedPDF = async () => {
    try {
      const response = await fetchPdf();
      const pdfBytes = response.data;
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const page = pdfDoc.getPages()[0];

      await drawPatientInfo(page, pdfDoc, practicePatient);
      await drawDateOfIssue(page);
      await drawVaccinations(page, pdfDoc, vaccinations);

      const modifiedPdfBytes = await pdfDoc.save();

      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: 'application/pdf',
      });

      const downloadUrl = URL.createObjectURL(modifiedPdfBlob);

      // Creates a URL for te preview of the modified PDF
      return setPdfURL(downloadUrl);

      // const anchor = document.createElement('a');
      // anchor.href = downloadUrl;
      // anchor.download = 'patient-eligibility-screening-record.pdf';
      // document.body.appendChild(anchor);
      // anchor.click();
      // document.body.removeChild(anchor);
    } catch (error) {
      console.error('Error while processing the PDF:', error);
    }
  };

  // useEffect to cache PDF from S3
  useEffect(() => {
    fetchPdf();
  }, []);

  return (
    <>
      {isIcon ? (
        <Popup
          trigger={
            <Button
              style={{ padding: '0.4rem' }}
              basic
              size="mini"
              icon="download"
              onClick={() => {
                setOpenPdfPreviewModal(true);
                downloadModifiedPDF();
              }}
            />
          }
          content="Download consent form"
        />
      ) : (
        <Button
          primary
          size={size ? size : undefined}
          content="Consent Form"
          icon="download"
          onClick={() => {
            setOpenPdfPreviewModal(true);
            downloadModifiedPDF();
          }}
        />
      )}
      <Modal
        closeIcon
        basic
        size="fullscreen"
        onClose={() => setOpenPdfPreviewModal(false)}
        onOpen={() => setOpenPdfPreviewModal(true)}
        open={openPdfPreviewModal}
      >
        <Modal.Content>
          <div
            style={{
              backgroundColor: 'grey',
              width: '100%',
              height: '80vh',
            }}
          >
            <object
              style={pageBlurred ? { display: 'none' } : {}}
              type="application/pdf"
              data={`${pdfURL}#toolbar=1&navpanes=0&scrollbar=0`}
              width="100%"
              height={isEmbedded ? '500' : '800'}
              aria-label="patient-eligibility-screening-record.pdf"
            />
          </div>
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        open={hasInconsistentVaccination}
        onClose={() => setHasInconsistentVaccination(false)}
      >
        <Modal.Header>
          <Icon name="warning sign" color="red" />
          There are inconsistent vaccinations on the form
        </Modal.Header>
        <Modal.Actions>
          <Button
            primary
            content="Ok"
            onClick={() => setHasInconsistentVaccination(false)}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default PatientEligibilityScreeningRecordPdf;
