import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Form } from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import {
  InventoryOrdersFilter,
  inventoryOrderStatusesOptions,
  OrderStatuses,
  SelectAll,
  selectAllOption,
} from '@bluefox/models/InventoryOrders';
import { debounce } from '@bluefox/lib/debounce';

type InventoryOrderTableAdvancedFiltersProps = {
  setInventoryOrdersFilter: Dispatch<SetStateAction<InventoryOrdersFilter>>;
  inventoryOrdersFilter: InventoryOrdersFilter;
  resetFilters: () => void;
};

export const InventoryOrderTableAdvancedFilters = ({
  setInventoryOrdersFilter,
  inventoryOrdersFilter,
  resetFilters,
}: InventoryOrderTableAdvancedFiltersProps) => {
  const debouncedRef = useRef<ReturnType<typeof debounce>>();
  const [searchOrderNumberInput, setSearchOrderNumberInput] = useState('');

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <Form>
      <Form.Group widths={'equal'}>
        <Form.Field>
          <label>Vaccine:</label>
          <VaccinePicker
            dropdownProps={{
              fluid: true,
              placeholder: 'Search Vaccine',
            }}
            value={inventoryOrdersFilter.vaccine}
            onChange={({ vaccine }) =>
              setInventoryOrdersFilter({
                ...inventoryOrdersFilter,
                vaccine: vaccine?.id,
              })
            }
          />
        </Form.Field>

        <Form.Input
          label="Order Number"
          value={searchOrderNumberInput}
          onChange={(_, { value }) => {
            setSearchOrderNumberInput(value);
            debouncedRef.current?.cancel();
            debouncedRef.current = debounce(() => {
              setInventoryOrdersFilter((prevVal) => ({
                ...prevVal,
                orderNumber: value,
              }));
            }, 500);
            debouncedRef.current();
          }}
          icon="search"
          placeholder="Search..."
          input={{
            autocomplete: 'off',
            autocorrect: 'off',
          }}
        />

        <Form.Select
          label="Status:"
          options={[...selectAllOption, ...inventoryOrderStatusesOptions]}
          value={inventoryOrdersFilter.status}
          onChange={(event, { value }) => {
            setInventoryOrdersFilter({
              ...inventoryOrdersFilter,
              status: value as OrderStatuses | SelectAll,
            });
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <DateTimePicker
            placeholderText="From:"
            selected={inventoryOrdersFilter.dateFrom}
            onChange={(value) => {
              setInventoryOrdersFilter((prevVal) => ({
                ...prevVal,
                dateFrom: value ? (value as Date) : undefined,
              }));
            }}
            onSelect={(value) =>
              setInventoryOrdersFilter((prevVal) => ({
                ...prevVal,
                dateFrom: value ? (value as Date) : undefined,
              }))
            }
            onClear={() =>
              setInventoryOrdersFilter((prevVal) => ({
                ...prevVal,
                dateFrom: undefined,
              }))
            }
            dateFormat={DateFormats.DATE}
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            dropdownMode="select"
            isClearable
          />
        </Form.Field>
        <Form.Field>
          <DateTimePicker
            forFilter
            placeholderText="To:"
            selected={inventoryOrdersFilter.dateTo}
            onChange={(value) => {
              setInventoryOrdersFilter((prevVal) => ({
                ...prevVal,
                dateTo: value ? (value as Date) : undefined,
              }));
            }}
            onSelect={(value) =>
              setInventoryOrdersFilter((prevVal) => ({
                ...prevVal,
                dateTo: value ? (value as Date) : undefined,
              }))
            }
            onClear={() =>
              setInventoryOrdersFilter((prevVal) => ({
                ...prevVal,
                dateTo: undefined,
              }))
            }
            dateFormat={DateFormats.DATE}
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            dropdownMode="select"
            isClearable
          />
        </Form.Field>
        <Form.Button
          content="Clear"
          primary
          onClick={() => {
            resetFilters();
            setSearchOrderNumberInput('');
          }}
        />
      </Form.Group>
    </Form>
  );
};
