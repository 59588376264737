import { useCallback, useState, useEffect } from 'react';
import {
  Button,
  Icon,
  Label,
  Popup,
  List,
  Header,
  Segment,
} from 'semantic-ui-react';

import {
  StyledCard,
  StyledCardHeader,
  StyledCardContent,
  StyledCardItem,
  StyledCardItemText,
  StyledMessage,
} from './styles';
import {
  backgroundColor,
  borderColor,
  boxShadowColor,
} from '@bluefox/constants/styles';

import { ScannableItem } from '@bluefox/store/scannableItems';

import VaccinationSitePicker from '../VaccinationSitePicker';
import {
  VaccinationRoutes,
  VaccinationSites,
} from '@bluefox/models/Vaccination';
import VaccinationRoutePicker from '../VaccinationRoutePicker';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import moment from 'moment/moment';
import { DateFormats } from '@bluefox/models/Dates';
import { useMutation } from '@apollo/client';
import { VaccineTypeWarningValidationMutation } from '@bluefox/graphql/vaccines';
import { usePracticePatient } from '@bluefox/contexts/PracticePatientProvider';

type ScannedItemCardProps = {
  patientAge: number;
  item: ScannableItem;
  showRemove: boolean;
  allowRemove: boolean;
  vfc: boolean;
  givenAt: Date;
  duplicatedVaccineNdcs?: (string | undefined)[] | undefined;
  onRemove: (code: string, inventoryId?: string) => void;
  onSiteChange: (code: string, site: VaccinationSites) => void;
  onRouteChange: (code: string, route: VaccinationRoutes) => void;
  onVisDateChange: (code: string, vaccinationVisDate: Date | null) => void;
  practiceTimezone: string;
  practicePatientId: string;
  addWarning: (code: string, warnings: string[] | undefined) => void;
};

const ScannedItemCard = ({
  patientAge,
  item,
  onRemove,
  onSiteChange,
  onRouteChange,
  onVisDateChange,
  showRemove = true,
  allowRemove = true,
  vfc,
  duplicatedVaccineNdcs,
  givenAt,
  practiceTimezone,
  practicePatientId,
  addWarning,
}: ScannedItemCardProps) => {
  const { code, inventory, vaccine, gs1 } = item;

  const practicePatient = usePracticePatient();

  const [visDate, setVisDate] = useState<Date>(new Date());
  const [hasDuplicated, setHasDuplicated] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<string[]>();

  const lot = gs1?.getLot();
  const exp = gs1?.getExp();
  const fNDC = gs1?.getFormattedNdc10();

  const handleOnSiteChange = useCallback(
    (site: VaccinationSites) => onSiteChange(code, site),
    [code, onSiteChange]
  );

  const handleOnRouteChange = useCallback(
    (route: VaccinationRoutes) => onRouteChange(code, route),
    [code, onRouteChange]
  );

  const conflictingInventory = inventory && inventory.length > 1;
  const conflictingVfc =
    inventory && !!inventory?.length && inventory[0].vfc !== vfc;

  const calcExpiration = (givenAt: Date, exp: Date) => {
    const parsedGivenAt = moment(givenAt).startOf('day');
    const parsedExp = moment(exp).startOf('day');
    const diff = parsedExp.diff(parsedGivenAt, 'days');
    return diff <= 0;
  };

  useEffect(() => {
    if (!visDate) {
      onVisDateChange(code, null);
      return;
    }
    onVisDateChange(code, visDate);
  }, [visDate, code]);

  useEffect(() => {
    if (!item) {
      setHasDuplicated(false);
    }

    setHasDuplicated(!!duplicatedVaccineNdcs?.includes(item.gs1?.ndc || ''));
  }, [item, duplicatedVaccineNdcs]);

  useEffect(() => {
    if (!givenAt || !exp) {
      return;
    }
    setIsExpired(calcExpiration(givenAt, exp));
    setVisDate(new Date(givenAt));
  }, [givenAt]);

  const [vaccineTypeWarnings] = useMutation(
    VaccineTypeWarningValidationMutation
  );

  const setWarningTexts = (code: string, texts: string[] | undefined) => {
    setWarnings(texts);
    addWarning(code, texts);
  };

  const validateWarnings = async () => {
    try {
      const {
        data: { vaccineTypeWarningsValidation },
      } = await vaccineTypeWarnings({
        variables: {
          practicePatientId,
          givenAt,
          vaccines: [
            {
              saleNdc: vaccine?.saleNdc,
              types: vaccine?.types,
            },
          ],
        },
      });

      if (vaccineTypeWarningsValidation.warning) {
        const [warningTexts] = vaccineTypeWarningsValidation.vaccineWarnings;
        setWarningTexts(code, warningTexts.text);
      } else {
        setWarningTexts(code, undefined);
      }
    } catch (error) {
      console.log('error', error);
      alert(error);
    }
  };

  useEffect(() => {
    if (!givenAt || !vaccine || !practicePatientId) return;
    validateWarnings();
  }, [givenAt, vaccine, practicePatientId]);

  if (conflictingInventory) {
    return (
      <StyledCard
        borderColor={borderColor.redWarning}
        boxShadowColor={boxShadowColor.redWarning}
      >
        <StyledCard>
          <StyledCardHeader>
            <Header as="h4">{vaccine?.name || fNDC}</Header>
          </StyledCardHeader>
          <StyledCardContent>
            <StyledCardItem>
              <label>Lot number</label>
              <div style={{ display: 'flex' }}>
                <StyledCardItemText>{lot || '(unknown)'}</StyledCardItemText>
              </div>
            </StyledCardItem>
            <StyledCardItem>
              <label>Expiration</label>
              <StyledCardItemText>
                {!!exp
                  ? moment(exp).format(DateFormats.DATE)
                  : '(undetermined)'}
              </StyledCardItemText>
            </StyledCardItem>
            <StyledCardItem>
              <label>VIS Date</label>
              <DateTimePicker
                popperClassName="date-picker-popper"
                forFilter
                selected={visDate}
                onChange={(d) => {
                  setVisDate(d as Date);
                }}
                maxDate={new Date()}
                dateFormat={DateFormats.DATE}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
                inputProps={{ style: { width: 120 } }}
              />
            </StyledCardItem>
            <StyledCardItem>
              <label>Route</label>
              <VaccinationRoutePicker
                width="5rem"
                routes={vaccine?.routes?.length ? vaccine.routes : undefined}
                onChange={handleOnRouteChange}
              />
            </StyledCardItem>
            {item.vaccinationRoute !== VaccinationRoutes.PO &&
              item.vaccinationRoute !== VaccinationRoutes.IN && (
                <StyledCardItem>
                  <label>Site</label>
                  <VaccinationSitePicker
                    defaultValue={
                      patientAge <= 3
                        ? VaccinationSites.rightThigh
                        : VaccinationSites.rightArm
                    }
                    onChange={handleOnSiteChange}
                  />
                </StyledCardItem>
              )}
            {(item.vaccinationRoute === VaccinationRoutes.PO ||
              item.vaccinationRoute === VaccinationRoutes.IN) && (
              <StyledCardItem></StyledCardItem>
            )}
          </StyledCardContent>

          <Segment
            textAlign="center"
            basic
            style={{ width: '100%', padding: '0' }}
          >
            <StyledMessage
              color={backgroundColor.yellowInfo}
              margin="0.2rem 0 0 0"
              textAlign="center"
            >
              <div>
                <Icon
                  name="exclamation circle"
                  color="orange"
                  style={{ marginRight: '0.75rem' }}
                />
                Please ensure you select the correct inventory type (
                <b>Private</b> or <b>VFC</b>) from which the vaccine was taken.
                This selection is based on the inventory source, not the
                patient's eligibility.
              </div>
            </StyledMessage>
            <Segment
              basic
              style={{
                padding: '0',
                marginTop: '0.5rem',
                width: '100%',
              }}
            >
              {inventory.map((i) => {
                return (
                  <Button
                    color={!i.vfc ? 'orange' : 'teal'}
                    onClick={() => onRemove(code, i.id)}
                    disabled={!allowRemove}
                  >
                    {!i.vfc ? 'Use VFC' : 'Use Private'}
                  </Button>
                );
              })}
            </Segment>
          </Segment>
        </StyledCard>
      </StyledCard>
    );
  }

  if (hasDuplicated || isExpired) {
    return (
      <StyledCard
        borderColor={borderColor.redWarning}
        boxShadowColor={boxShadowColor.redWarning}
      >
        <StyledCardHeader>
          <Header as="h4" color={hasDuplicated ? 'red' : 'black'}>
            {vaccine?.name || fNDC}
          </Header>
          {showRemove && (
            <Popup
              trigger={
                <Button
                  basic
                  icon="trash alternate outline"
                  onClick={() => onRemove(code)}
                  disabled={!allowRemove}
                />
              }
              content="Remove"
              size="tiny"
            />
          )}
        </StyledCardHeader>
        <StyledCardContent>
          <StyledCardItem>
            <label>Lot number</label>
            <div style={{ display: 'flex' }}>
              <StyledCardItemText>{lot || '(unknown)'}</StyledCardItemText>
              {!!inventory &&
                inventory.length &&
                (inventory.at(0)?.vfc ? (
                  <Label
                    style={{ marginLeft: '0.5rem' }}
                    color="red"
                    horizontal
                  >
                    VFC
                  </Label>
                ) : (
                  <Label
                    style={{ marginLeft: '0.5rem' }}
                    color="teal"
                    horizontal
                  >
                    Private
                  </Label>
                ))}
            </div>
          </StyledCardItem>

          <StyledCardItem>
            <label>Expiration</label>
            <StyledCardItemText color={isExpired ? 'red' : ''}>
              {!!exp ? moment(exp).format(DateFormats.DATE) : '(undetermined)'}
            </StyledCardItemText>
          </StyledCardItem>

          <StyledCardItem>
            <label>VIS Date</label>
            <DateTimePicker
              tz={practiceTimezone}
              popperClassName="date-picker-popper"
              forFilter
              selected={visDate}
              onChange={(d) => {
                setVisDate(d as Date);
              }}
              maxDate={new Date()}
              dateFormat={DateFormats.DATE}
              showYearDropdown
              showMonthDropdown
              scrollableYearDropdown
              dropdownMode="select"
              inputProps={{ style: { width: 120 } }}
            />
          </StyledCardItem>
          <StyledCardItem>
            <label>Route</label>
            <VaccinationRoutePicker
              width="5rem"
              routes={vaccine?.routes?.length ? vaccine.routes : undefined}
              onChange={handleOnRouteChange}
            />
          </StyledCardItem>
          {item.vaccinationRoute !== VaccinationRoutes.PO &&
            item.vaccinationRoute !== VaccinationRoutes.IN && (
              <StyledCardItem>
                <label>Site</label>
                <VaccinationSitePicker
                  defaultValue={
                    patientAge <= 3
                      ? VaccinationSites.rightThigh
                      : VaccinationSites.rightArm
                  }
                  onChange={handleOnSiteChange}
                />
              </StyledCardItem>
            )}
          {(item.vaccinationRoute === VaccinationRoutes.PO ||
            item.vaccinationRoute === VaccinationRoutes.IN) && (
            <StyledCardItem></StyledCardItem>
          )}
        </StyledCardContent>
        {isExpired && (
          <StyledMessage color={backgroundColor.redWarning} margin="1rem 0 0 0">
            <div>
              <Icon name="warning sign" style={{ marginRight: '0.75rem' }} />
              Vaccine has expired at the time of application. Please remove it
              or update the vaccination date to proceed.
            </div>
          </StyledMessage>
        )}
        {hasDuplicated && (
          <StyledMessage color={backgroundColor.redWarning} margin="1rem 0 0 0">
            <div>
              <Icon name="warning sign" style={{ marginRight: '0.75rem' }} />
              This vaccination has already been given today. Please remove it to
              avoid duplication.
            </div>
          </StyledMessage>
        )}
      </StyledCard>
    );
  }

  return (
    <StyledCard
      borderColor={
        (warnings && warnings.length) ||
        !item.vaccine ||
        !item.inventory ||
        conflictingVfc
          ? borderColor.yellowInfo
          : borderColor.basicGrey
      }
      boxShadowColor={
        (warnings && warnings.length) || !item.vaccine || conflictingVfc
          ? boxShadowColor.yellowInfo
          : ''
      }
    >
      <StyledCardHeader>
        <Header
          as="h4"
          color={
            !item.vaccine || (warnings && warnings.length) || conflictingVfc
              ? 'orange'
              : 'black'
          }
        >
          {vaccine?.name || fNDC}
        </Header>
        {showRemove && (
          <Popup
            trigger={
              <Button
                basic
                icon="trash alternate outline"
                onClick={() => onRemove(code)}
                disabled={!allowRemove}
              />
            }
            content="Remove"
            size="tiny"
          />
        )}
      </StyledCardHeader>
      <StyledCardContent>
        <StyledCardItem>
          <label>Lot number</label>
          <div style={{ display: 'flex' }}>
            <StyledCardItemText>{lot || '(unknown)'} </StyledCardItemText>
            {inventory &&
              !!inventory?.length &&
              (inventory[0].vfc ? (
                <Label
                  color="orange"
                  horizontal
                  style={{ marginLeft: '0.5rem' }}
                >
                  VFC
                </Label>
              ) : (
                <Label style={{ marginLeft: '0.5rem' }} color="teal" horizontal>
                  Private
                </Label>
              ))}
          </div>
        </StyledCardItem>
        <StyledCardItem>
          <label>Expiration</label>
          <StyledCardItemText>
            {!!exp ? moment(exp).format(DateFormats.DATE) : '(undetermined)'}
          </StyledCardItemText>
        </StyledCardItem>
        <StyledCardItem>
          <label>VIS Date</label>
          <DateTimePicker
            tz={practiceTimezone}
            popperClassName="date-picker-popper"
            forFilter
            selected={visDate}
            onChange={(d) => {
              setVisDate(d as Date);
            }}
            maxDate={new Date()}
            dateFormat={DateFormats.DATE}
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            dropdownMode="select"
            inputProps={{ style: { width: 120 } }}
          />
        </StyledCardItem>
        <StyledCardItem>
          <label>Route</label>
          <VaccinationRoutePicker
            width="5rem"
            routes={vaccine?.routes?.length ? vaccine.routes : undefined}
            onChange={handleOnRouteChange}
            defaultValue={vaccine?.routes?.at(0)}
          />
        </StyledCardItem>
        {item.vaccinationRoute !== VaccinationRoutes.PO &&
          item.vaccinationRoute !== VaccinationRoutes.IN && (
            <StyledCardItem>
              <label>Site</label>
              <VaccinationSitePicker
                defaultValue={
                  patientAge <= 3
                    ? VaccinationSites.rightThigh
                    : VaccinationSites.rightArm
                }
                onChange={handleOnSiteChange}
              />
            </StyledCardItem>
          )}
        {(item.vaccinationRoute === VaccinationRoutes.PO ||
          item.vaccinationRoute === VaccinationRoutes.IN) && (
          <StyledCardItem></StyledCardItem>
        )}
      </StyledCardContent>
      {!item.vaccine && (
        <StyledMessage color={backgroundColor.yellowInfo} margin="1rem 0 0 0">
          <div>
            <Icon
              name="exclamation circle"
              color="orange"
              style={{ marginRight: '0.75rem' }}
            />
            This vaccine was not found in our registry. It might take up to 24hs
            to appear in the Vax History and Vax Dash.
          </div>
        </StyledMessage>
      )}
      {item.vaccine && !item.inventory && (
        <StyledMessage color={backgroundColor.yellowInfo} margin="1rem 0 0 0">
          <div>
            <Icon
              name="exclamation circle"
              color="orange"
              style={{ marginRight: '0.75rem' }}
            />
            This vaccine does not match our inventory lot number. It is
            currently under review and may take up to 24 hours to sync with the
            EMR.
          </div>
        </StyledMessage>
      )}
      {conflictingVfc && (
        <StyledMessage color={backgroundColor.yellowInfo} margin="1rem 0 0 0">
          <div>
            <Icon
              name="exclamation circle"
              color="orange"
              style={{ marginRight: '0.75rem' }}
            />
            {`
          The scanned vaccine doesn't match the patient's criteria
          (${
            practicePatient?.insurances[0]?.vfcEligible ? 'VFC' : 'Private'
          }). Please scan the appropriate vaccine or consider
          updating the patient's criteria if necessary.
          `}
          </div>
        </StyledMessage>
      )}
      {warnings && warnings.length && (
        <StyledMessage color={backgroundColor.yellowInfo} margin="1rem 0 0 0">
          <List>
            {warnings.map((w, index) => (
              <List.Item key={index}>
                <Icon
                  name="exclamation circle"
                  color="orange"
                  style={{ marginRight: '0.75rem' }}
                />
                {w}
              </List.Item>
            ))}
          </List>
        </StyledMessage>
      )}
    </StyledCard>
  );
};

export default ScannedItemCard;
