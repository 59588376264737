import { useState, ReactNode } from 'react';
import { useMutation } from '@apollo/client';
import { usePractice } from '@bluefox/contexts/Practice';
import { toast } from 'react-semantic-toasts';
import { Form, Segment, Modal, Icon, Button } from 'semantic-ui-react';
import { InsertTicketMutation } from '@bluefox/graphql/tickets';

interface RequestFeatureModalType {
  children: ReactNode;
}
const RequestFeatureModal = ({ children }: RequestFeatureModalType) => {
  const { name, handler } = usePractice();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [purpose, setPurpose] = useState<string>();
  const [description, setDescription] = useState<string>();

  const [insertTicket] = useMutation(InsertTicketMutation);

  const handleSubmit = () => {
    const ticketDetail = {
      fields: [
        { title: 'Practice', detail: name },
        { title: 'Handler', detail: handler },
        { title: 'Feature Title', detail: title },
        { title: 'Feature Purpose', detail: purpose },
        { title: 'Feature Description', detail: description },
      ],
    };
    insertTicket({
      variables: {
        type: 'Feature_request',
        status: 'open',
        detail: ticketDetail,
      },
    })
      .then(() => {
        toast({
          title: 'You feature request has been submitted',
          type: 'success',
          time: 5000,
        });
        setOpen(false);
        setTitle(undefined);
        setPurpose(undefined);
        setDescription(undefined);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Modal
      dimmer="blurring"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={children}
    >
      <Modal.Header>
        <Icon name="bullhorn" />
        Feature Request
      </Modal.Header>
      <Modal.Content>
        Please use this form to request any feature you think it might be
        useful.
        <Segment padded>
          <Form id="feature-request-form" onSubmit={handleSubmit}>
            <Form.Input
              onChange={(e) => setTitle(e.target?.value.toString())}
              fluid
              label="Please write the feature title"
              placeholder="Feature title"
              required
            />
            <Form.TextArea
              onChange={(e) => setPurpose(e.target?.value.toString())}
              fluid
              label="What is the purpose of the feature?"
              placeholder="Feature purpose"
              required
            />
            <Form.TextArea
              onChange={(e) => setDescription(e.target?.value.toString())}
              required
              label="Please provide a detailed description of your feature suggestion"
              placeholder="Description"
            />
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button primary type="submit" form="feature-request-form">
          Submit
        </Button>

        <Button secondary type="button" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RequestFeatureModal;
