import { ReactNode, useState } from 'react';
import {
  Modal,
  Icon,
  Segment,
  Form,
  Button,
  Dropdown,
} from 'semantic-ui-react';
import { useSession } from '@bluefox/contexts/ApplicationState';
import { useMutation } from '@apollo/client';
import { usePractice } from '@bluefox/contexts/Practice';
import { toast } from 'react-semantic-toasts';
import { InsertTicketMutation } from '@bluefox/graphql/tickets';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import VaccineTypesPicker from '@bluefox/ui/VaccineTypesPicker';

interface WastedDosesModalType {
  children: ReactNode;
}

const EXPIRATION_OPTIONS = [
  { key: 'expired', text: 'Expired', value: 'expired' },
  {
    key: 'expired-shorened',
    text: 'Expired-Shortened Expiration Date',
    value: 'Expired-Shortened Expiration Date',
  },
  {
    key: 'equipment-failure',
    text: 'Refrigerator / Mechanical / Equipment Failure',
    value: 'Refrigerator / Mechanical / Equipment Failure',
  },
  {
    key: 'natural-disaster',
    text: 'Natural Disaster',
    value: 'Natural Disaster',
  },
  { key: 'power-outage', text: 'Power Outage', value: 'Power Outage' },
  {
    key: 'refrigerator-too-cold',
    text: 'Refrigerator Too Cold',
    value: 'Refrigerator Too Cold',
  },
  {
    key: 'refrigerator-too-warm',
    text: 'Refrigerator Too Warm',
    value: 'Refrigerator Too Warm',
  },
  {
    key: 'freezer-too-warm',
    text: 'Freezer Too Warm',
    value: 'Freezer Too Warm',
  },
  {
    key: 'improper-storage',
    text: 'Improper Storage Upon Receipt',
    value: 'Improper Storage Upon Receipt',
  },
  { key: 'spoiled-other', text: 'Spoiled-Other', value: 'Spoiled-Other' },
  {
    key: 'broken-vial',
    text: 'Broken Vial / Syringe',
    value: 'Broken Vial / Syringe',
  },
  {
    key: 'lost',
    text: 'Lost or Unaccounted for Vaccine',
    value: 'Lost or Unaccounted for Vaccine',
  },
  {
    key: 'non-vaccine',
    text: 'Non-vaccine Product',
    value: 'Non-vaccine Product',
  },
  {
    key: 'transferred-between-providers',
    text: 'Transferred Between Providers in Viable State',
    value: 'Transferred Between Providers in Viable State',
  },
  {
    key: 'open-vial-bult-all-doses-not-administrated',
    text: 'Open Vial But All Doses Not Administrated',
    value: 'Open Vial But All Doses Not Administrated',
  },
  {
    key: 'not-administrated',
    text: 'Vaccine Drawn Into Syringe But Not Administrated',
    value: 'Vaccine Drawn Into Syringe But Not Administrated',
  },
  { key: 'recall', text: 'Recall', value: 'Recall' },
  {
    key: 'other-vaccine-return',
    text: 'Other Vaccine Return',
    value: 'Other Vaccine Return',
  },
  { key: 'other-wastage', text: 'Other Wastage', value: 'Other Wastage' },
];

const WastedDosesModal = ({ children }: WastedDosesModalType) => {
  const { name, handler, timezone: practiceTimezone } = usePractice();
  const session = useSession();

  const [open, setOpen] = useState(false);

  const [vaccineTypesInput, setVaccineTypesInput] = useState<string>('');
  const [vaccineName, setVaccineName] = useState<string>();
  const [lot, setLot] = useState<string>();
  const [expiration, setExpiration] = useState<Date>();
  const [wastage, setWastage] = useState<Date>();
  const [numberWasted, setNumberWasted] = useState<Number>();
  const [dosesOnHand, setDosesOnHand] = useState<string>();
  const [currentDoses, setCurrentDoses] = useState<string>();
  const [inventoryType, setInventoryType] = useState<string>();
  const [reason, setReason] = useState<string>();
  const [insertTicket] = useMutation(InsertTicketMutation);

  const textToSplit = vaccineTypesInput.toString();
  const textsplitted = textToSplit?.split(',');
  const vaccineTypes = textsplitted?.join(', ');

  const handleSubmit = () => {
    const ticketDetail = {
      fields: [
        { title: 'Practice', detail: name },
        { title: 'Handler', detail: handler },
        { title: 'Email', detail: session.account?.email },
        { title: 'Vaccine Type', detail: vaccineTypes },
        { title: 'Inventory Type', detail: inventoryType },
        { title: 'Vaccine Name', detail: vaccineName },
        { title: 'Lot Number', detail: lot },
        { title: 'Expiration Date', detail: expiration },
        { title: 'Date of Wastage', detail: wastage },
        { title: 'Current Doses on Inventory', detail: currentDoses },
        { title: 'Doses on Hand', detail: dosesOnHand },
        { title: 'Number of Wasted Doses', detail: numberWasted },
        { title: 'Reason for Wastage', detail: reason },
      ],
    };

    insertTicket({
      variables: {
        type: 'Wasted_doses_report',
        status: 'open',
        detail: ticketDetail,
      },
    })
      .then(() => {
        toast({
          title: 'Your report has been submitted',
          type: 'success',
          time: 5000,
        });
        setOpen(false);
        setVaccineTypesInput('');
        setVaccineName(undefined);
        setLot(undefined);
        setExpiration(undefined);
        setWastage(undefined);
        setNumberWasted(undefined);
        setDosesOnHand(undefined);
        setCurrentDoses(undefined);
        setInventoryType(undefined);
        setReason(undefined);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Modal
      dimmer="blurring"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      closeOnEscape={false}
      trigger={children}
      data-automation-id="inventory-wasted-dose"
    >
      <Modal.Header>
        <Icon.Group>
          <Icon name="box" />
          <Icon
            name="syringe"
            size="mini"
            inverted
            style={{ marginTop: '0.3rem' }}
          />
        </Icon.Group>
        Wasted Doses Reporting Form
      </Modal.Header>
      <Modal.Content>
        Please use this form to report wasted doses.
        <Segment padded>
          <Form id="wasted-doses-form" onSubmit={handleSubmit}>
            <Form.Field required>
              <label>Vaccine Type (Ex: COVID-19)</label>
              <VaccineTypesPicker
                required
                onChange={(data) => setVaccineTypesInput(data.toString())}
                fluid
              />
            </Form.Field>
            <Form.Field required>
              <label>Inventory Type </label>
              <Dropdown
                placeholder="Inventory Type..."
                fluid
                selection
                onChange={(_, { value }) => setInventoryType(value as string)}
                options={[
                  {
                    value: 'vfc',
                    text: 'VFC',
                  },
                  {
                    value: 'private',
                    text: 'Private',
                  },
                ]}
              />
            </Form.Field>
            <Form.Group inline widths="equal">
              <Form.Input
                onChange={(e) => setVaccineName(e.target?.value.toString())}
                required
                fluid
                label="Vaccine Company Name (Ex: Pfizer)"
                placeholder="Vaccine Name"
              />
              <Form.Input
                required
                onChange={(e) => setLot(e.target?.value.toString())}
                fluid
                label="Lot Number"
                placeholder="Lot Number"
              />
            </Form.Group>
            <Form.Group inline widths="equal">
              <Form.Field required>
                <label>Expiration Date</label>
                <DateTimePicker
                  tz={practiceTimezone}
                  placeholderText="Expiration Date"
                  selected={expiration}
                  onChange={(d) => setExpiration(d ? (d as Date) : undefined)}
                  onClear={() => setExpiration(undefined)}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  isClearable
                  required
                />
              </Form.Field>
              <Form.Field required>
                <label>Date of Wastage</label>
                <DateTimePicker
                  tz={practiceTimezone}
                  placeholderText=" Date of Wastage"
                  selected={wastage}
                  onChange={(d) => setWastage(d ? (d as Date) : undefined)}
                  onClear={() => setWastage(undefined)}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  isClearable
                  required
                />
              </Form.Field>
            </Form.Group>
            <Form.Group inline widths="equal">
              <Form.Input
                onChange={(e) =>
                  setNumberWasted(Number.parseInt(e.target?.value))
                }
                required
                fluid
                label="Number of Doses Wasted"
                placeholder="Number of Doses Wasted"
              />
              <Form.Input
                onChange={(e) => setDosesOnHand(e.target?.value.toString())}
                required
                fluid
                label="Doses on Hand "
                placeholder="Doses on Hand "
              />
              <Form.Input
                onChange={(e) => setCurrentDoses(e.target?.value.toString())}
                required
                fluid
                label="Current Doses on Inventory "
                placeholder="Current Doses on Inventory "
              />
            </Form.Group>
            <Form.Dropdown
              required
              selection
              label="Reason for Wastage: 'e.g Expired, Broken Vial, etc."
              options={EXPIRATION_OPTIONS}
              onChange={(_, { value }) =>
                setReason(value ? value.toString() : undefined)
              }
            />
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          type="submit"
          form="wasted-doses-form"
          disabled={!vaccineTypes || !reason || !expiration || !wastage}
        >
          Submit
        </Button>

        <Button secondary type="button" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WastedDosesModal;
