import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  Confirm,
  Grid,
  Header,
  Icon,
  Menu,
  Segment,
  Input,
  Popup,
  Message,
  Form,
  Modal,
  Button,
  Dropdown,
  Table,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import {
  PrimaryInsuranceQuery,
  InsertInsurancesForPatientFromExtensionMutation,
  UpdatePrimaryInsuranceVfc,
  InsertInsuranceMutation,
  InsurancesQuery,
} from '@bluefox/graphql/insurances';

import {
  VfcConfirmedMutation,
  SaveConsentForIisMutation,
  PracticePatientProfileQuery,
} from '@bluefox/graphql/patients';

import {
  InsertVacciationsMutation,
  VaccinationsByTypeQuery,
  VaccinationsByPatientQuery,
  DuplicatedVaccinationsQuery,
  VaccinationsByPracticePatientQuery,
} from '@bluefox/graphql/vaccinations';

import {
  Insurance,
  VfcCriteria,
  VFC_OPTIONS,
} from '@bluefox/models/Insurances';

import {
  ScannableItem,
  useScannableItems,
} from '@bluefox/store/scannableItems';
import { RoutineTypes, VACCINE_TYPES } from '@bluefox/store/vaccines';

import DateTimePicker from '@bluefox/ui/DateTimePicker';
import GS1GunScanner from '@bluefox/ui/GS1GunScanner';

import ScannedItems from '@components/ScannedItems';
import ProviderPicker from '@bluefox/ui/ProviderPicker';

import {
  usePractice,
  useSession,
  useApplicationState,
} from '@bluefox/contexts';
import {
  Vaccination,
  VaccinationSites,
  DuplicatedVaccination,
  VaccinationRoutes,
} from '@bluefox/models/Vaccination';

import moment from 'moment-timezone';
import { Link, useHistory } from 'react-router-dom';
import { usePracticePatient } from '@bluefox/contexts/PracticePatientProvider';
import { getAge } from '@bluefox/lib/getAge';
import { DateFormats } from '@bluefox/models/Dates';
import { backgroundColor } from '@bluefox/constants/styles';
import PatientEligibilityScreeningRecordPdf from '@components/celebrationPediatrics/PatientEligibilityScreeningRecordPdf';
import { Vaccine } from '@bluefox/models/Vaccine';
import { Inventory } from '@bluefox/models/Inventory';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import { PracticeSettings } from '@bluefox/models/Practice';
import { VFC_ENROLEMENT_YEARS_LESS_THAN } from '@bluefox/constants';
import { UserTitles } from '@bluefox/models/Users';
import { humanizeText } from '@bluefox/lib/humanize';

interface SettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

export type ScannedVacinationConsentPDF = {
  vaccine: Vaccine | undefined;
  inventory?: Inventory | undefined;
  givenAt: Date;
  providerId: string | undefined;
  site: VaccinationSites | null | undefined;
  route: VaccinationRoutes | null | undefined;
};

export type ScannedVaccinationsConsentPDF = {
  vaccinations: ScannedVacinationConsentPDF[];
};

interface InsurancesData {
  insurancesData: {
    insurances: Insurance[];
    isVfcConfirmed: boolean;
  };
}

interface DuplicatedVaccinationData {
  vaccinations: DuplicatedVaccination[];
}

interface ScannerProps {
  practicePatientId: string;
  onFinish: () => void;
  getTotalEntries: (entries: number) => void;
  setIsFinished: (finished: boolean) => void;
  pageBlurred: boolean;
}

type StyledMessageProps = {
  margin?: string;
};

const validPrescribers = [
  UserTitles.MD,
  UserTitles.DO,
  UserTitles.PA,
  UserTitles.NP,
];

const Scanner = ({
  practicePatientId,
  onFinish,
  getTotalEntries,
  setIsFinished,
  pageBlurred,
}: ScannerProps) => {
  const { isEmbedded } = useApplicationState();
  const { account } = useSession();
  const history = useHistory();

  const practice = usePractice();
  const practicePatient = usePracticePatient();
  const patient = practicePatient?.patientData;

  const [scanning, setScanning] = useState(false);
  const [finished, setFinished] = useState(false);
  const [disableFinishBtn, setDisableFinishBtn] = useState(false);

  const [conflictingInventory, setConflictingInventory] = useState(false);
  const [conflictingVfc, setConflictingVfc] = useState(false);
  const [hasDuplicated, setHasDuplicated] = useState(false);
  const [existExpiredVaccine, setExistExpiredVaccine] = useState(false);
  const [vaccineWithoutVisDate, setVaccineWithoutVisDate] = useState(false);

  const [showVfcConfirm, setShowVfcConfirm] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  const [givenAt, setGivenAt] = useState<Date>(new Date());
  const [providerId, setProviderId] = useState<string | undefined>(
    account?.title !== 'Other' ? account?.id : undefined
  );

  const [providerFullName, setProviderFullName] = useState<string>();

  const isValidPrescriber = useMemo(() => {
    if (account?.profile?.permissionAs) {
      return account?.profile?.permissionAs?.prescriber;
    }

    return validPrescribers.includes(account?.title as UserTitles);
  }, [account?.profile?.permissionAs, account?.title]);

  const [prescriberId, setPrescriberId] = useState<string | undefined>(
    isValidPrescriber ? account?.id : undefined
  );

  const [vfc, setVfc] = useState<boolean>(false);
  const patientDob = patient ? new Date(patient.birthdate) : undefined;

  const [vfcChecked, setVfcChecked] = useState(false);
  const [openVfcConfirm, setOpenVfcConfirm] = useState(false);
  const [testValue, setTestValue] = useState('');

  const [showConfirmConsentForIis, setShowConfirmConsentForIis] =
    useState(false);
  const [openVfcCriteriaModal, setOpenVfcCriteriaModal] = useState(false);
  const [selectedVfcCriteria, setSelectedVfcCriteria] = useState(
    VfcCriteria.medicaid
  );

  const [vaccineNdcs, setvaccineNdcs] = useState<(string | undefined)[]>([]);
  const [duplicatedVaccineNdcs, setDuplicatedVaccineNdcs] = useState<
    (string | undefined)[]
  >([]);

  const [duplicatedVaccineTypesMessages, setDuplicatedVaccinesTypesMessages] =
    useState<string[]>([]);

  const { data: practiceSettings } = useQuery<SettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practice.id,
      },
    }
  );

  const { data: insuranceQueryData } = useQuery<InsurancesData>(
    PrimaryInsuranceQuery,
    {
      variables: {
        practicePatientId,
      },
    }
  );

  const [getDuplicatedVaccinations, { data: duplicatedVaccinations }] =
    useLazyQuery<DuplicatedVaccinationData>(DuplicatedVaccinationsQuery);

  const patientAge = getAge(patientDob);
  const minVaxDate = moment(patientDob, DateFormats.DATE)
    .add(1, 'days')
    .toDate();

  useEffect(() => {
    if (!practicePatient) return;
    if (patientAge < 19 && !practicePatient.insurances.length) {
      setSelectedVfcCriteria(VfcCriteria.uninsured);
    } else {
      setSelectedVfcCriteria(VfcCriteria.medicaid);
    }
  }, [patientAge, practicePatient]);

  useEffect(() => {
    // use the most recent insurance
    const mostRecentInsurance =
      insuranceQueryData?.insurancesData?.insurances?.[0];

    // If the patient has an insurance, use the vfcEligible from the insurance
    if (mostRecentInsurance) {
      setVfc(mostRecentInsurance.vfcEligible || false);
    } else {
      // if the patient don't have any insurance and they are is less than 19 years old is vfc eligible. Otherwise, the patient is private.
      setVfc(patientAge < VFC_ENROLEMENT_YEARS_LESS_THAN);
    }
  }, [insuranceQueryData?.insurancesData?.insurances, patientAge]);

  const defaultVaccinationSite = useMemo(() => {
    const months =
      patient && patient.birthdate
        ? moment().diff(patient.birthdate, 'months', true)
        : 0;

    return months > 36
      ? VaccinationSites.rightArm
      : VaccinationSites.rightThigh;
  }, [patient]);

  const {
    entries,
    addEntry,
    removeEntry,
    cleanEntries,
    changeVaccinationSite,
    changeVaccinationRoute,
    changeVaccinationVisDate,
    loading,
    addWarning,
  } = useScannableItems({
    vfc,
    defaultVaccinationSite,
  });

  const [insertVaccinations, insertVaccinationsResult] = useMutation<{
    vaccinations: { affected_rows: number; returning: Vaccination[] };
  }>(InsertVacciationsMutation);

  const [confirmVfc, { called: confirmVfcCalled }] =
    useMutation(VfcConfirmedMutation);

  const [saveVfcForPatientFromExtension] = useMutation(
    InsertInsurancesForPatientFromExtensionMutation
  );

  const [savePrimaryInsuranceVfc] = useMutation(UpdatePrimaryInsuranceVfc);

  const [saveConsentForIis] = useMutation(SaveConsentForIisMutation);

  const [saveInsurance] = useMutation<Insurance>(
    InsertInsuranceMutation(false)
  );

  interface EntriesGroupedByType {
    [key: string]: {
      count: number;
      vaccine: string[];
    };
  }

  const validateDuplicateTypes = (entries: ScannableItem[]) => {
    const entriesGroupedByType = entries.reduce(
      (acum: EntriesGroupedByType, cur: ScannableItem) => {
        cur.vaccine?.types?.forEach((type) => {
          if (!acum[type]) {
            acum[type] = { count: 1, vaccine: [cur.vaccine?.name as string] };
          } else {
            acum[type] = {
              count: acum[type].count + 1,
              vaccine: [...acum[type].vaccine, cur.vaccine?.name as string],
            };
          }
        });

        return acum;
      },
      {}
    );

    const duplicatedTypes = Object.keys(entriesGroupedByType).filter(
      (key) => entriesGroupedByType[key].count > 1
    );

    const duplicatedTypesMessages = duplicatedTypes.map(
      (type) =>
        `The ${type} component is present in ${entriesGroupedByType[
          type
        ].vaccine.join(` and `)} vaccines.`
    );

    setDuplicatedVaccinesTypesMessages(duplicatedTypesMessages);
  };

  const handleSaveInsurance = async () => {
    try {
      await saveInsurance({
        variables: {
          practicePatientId,
          vfcEligible: true,
          vfcCriteria: selectedVfcCriteria,
          active: true,
        },
        refetchQueries: [
          {
            query: InsurancesQuery,
            variables: {
              practicePatientId,
            },
          },
        ],
      });
      setOpenVfcConfirm(false);
    } catch (error) {
      toast({
        title: 'Error on saving insurance',
        type: 'error',
        time: 5000,
      });
    }
  };

  const handleSavePrimaryInsuranceVfc = (
    insuranceId: string,
    vfcEligible: boolean,
    vfcCriteria: string | null
  ) => {
    savePrimaryInsuranceVfc({
      variables: {
        insuranceId,
        vfcEligible,
        vfcCriteria,
      },
    }).then(() => {
      setVfcChecked(true);
      setOpenVfcConfirm(false);
    });
  };

  const handleSaveVfcForPatientFromExtension = (
    practicePatientId: string,
    vfcEligible: boolean,
    vfcCriteria: string | null
  ) => {
    saveVfcForPatientFromExtension({
      variables: {
        practicePatientId,
        vfcEligible,
        vfcCriteria,
      },
    }).then(() => {
      setVfcChecked(true);
      setOpenVfcConfirm(false);
    });
  };

  const handleConfirmVfc = (practicePatientId: string) => {
    confirmVfc({
      variables: {
        practicePatientId,
      },
      refetchQueries: [
        {
          query: PracticePatientProfileQuery,
          variables: {
            id: practicePatientId,
          },
        },
      ],
    }).then(() => {
      setVfcChecked(true);
      setOpenVfcConfirm(false);
    });
  };

  const handleSaveConsentForIis = (
    practicePatientId: string,
    consentForIis: boolean
  ) => {
    saveConsentForIis({
      variables: {
        practicePatientId,
        consentForIis,
      },
      refetchQueries: [
        {
          query: PracticePatientProfileQuery,
          variables: {
            id: practicePatientId,
          },
        },
      ],
    }).then(() => {
      toast({
        title: 'Consent for IIS saved successfully.',
        type: 'success',
        time: 3000,
      });
      setShowConfirmConsentForIis(false);
    });
  };

  const handleFinishOnClick = () => {
    let okay = true;
    if (practicePatient?.consentForIis === null && patientAge >= 19) {
      setShowConfirmConsentForIis(true);
      return;
    }

    if (providerId && prescriberId && givenAt && !vaccineWithoutVisDate) {
      setDisableFinishBtn(true);
    }

    if (!!entries.length) {
      if (!providerId) {
        toast({
          title: 'Please select an authorized provider.',
          type: 'warning',
          time: 3000,
        });
        return;
      }

      if (!prescriberId) {
        toast({
          title: 'Please select an authorized prescriber.',
          type: 'warning',
          time: 3000,
        });
        return;
      }

      if (!givenAt) {
        toast({
          title: 'Please select a valid Service Date',
          type: 'warning',
          time: 3000,
        });
        return;
      }

      if (vaccineWithoutVisDate) {
        toast({
          title: 'Please make sure that all vaccinations have VIS Date',
          type: 'warning',
          time: 3000,
        });
        return;
      }

      if (conflictingInventory) return;

      if (conflictingVfc) {
        setShowVfcConfirm(true);
        return;
      }
    }

    if (okay) finish();
  };

  const checkExpirations = () =>
    entries.reduce((isExpired, entry) => {
      if (isExpired) {
        return true;
      }
      const parsedGivenAt = moment(givenAt).startOf('day');
      const parsedExp = moment(entry.gs1?.exp).startOf('day');
      const diff = parsedExp.diff(parsedGivenAt, 'days');
      return diff <= 0;
    }, false);

  const checkVaccinationsVisDate = () =>
    entries.reduce((hasVisDate, entry) => {
      if (!hasVisDate) {
        return false;
      }
      return !!entry.vaccinationVisDate;
    }, true);

  const finish = () => {
    setShowVfcConfirm(false);
    const vaccinations = entries.map(
      ({
        code,
        inventory,
        vaccine,
        vaccinationSite,
        vaccinationRoute,
        vaccinationVisDate,
        vaccinationDose,
        warnings,
      }): Vaccination => {
        const [inventoryEntry] = inventory || [];

        const vaccineId =
          (inventoryEntry && inventoryEntry.vaccineId) ||
          vaccine?.id ||
          undefined;

        const inventoryId = (inventoryEntry && inventoryEntry.id) || undefined;

        return {
          practicePatientId,
          vaccineId,
          inventoryId,
          barcode: code,
          givenAt,
          visDate: vaccinationVisDate || givenAt,
          providerId,
          ...(prescriberId ? { prescribedBy: prescriberId } : {}),
          site:
            vaccinationRoute === VaccinationRoutes.PO ||
            vaccinationRoute === VaccinationRoutes.IN
              ? null
              : vaccinationSite,
          route: vaccinationRoute,
          dose: vaccinationDose,
          ...(warnings && warnings.length ? { warnings } : {}),
        };
      }
    );

    insertVaccinations({
      variables: {
        vaccinations,
      },
      refetchQueries: [
        {
          query: VaccinationsByPatientQuery,
          variables: {
            practicePatientId,
          },
        },
        {
          query: VaccinationsByPracticePatientQuery,
          variables: {
            practicePatientId,
          },
        },
        {
          query: VaccinationsByTypeQuery,
          variables: {
            practicePatientId,
            types: RoutineTypes,
          },
        },
        {
          query: VaccinationsByTypeQuery,
          variables: {
            practicePatientId,
            types: VACCINE_TYPES,
          },
        },
      ],
    })
      .then(() => {
        setFinished(true);
        onFinish();
        setDisableFinishBtn(false);
        setGetDuplicatedVaccinationsPreviousVariables('');
      })
      .catch((error) => {
        setFinished(false);
        toast({
          title:
            'Something went wrong on the scanning process, please check your inventory',
          type: 'error',
          description: error,
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (
      insertVaccinationsResult.data &&
      insertVaccinationsResult.data.vaccinations.affected_rows > 0
    ) {
      setFinished(true);
      toast({
        title: 'Success',
        type: 'success',
        description: 'Immunization logged!',
        time: 5000,
      });
    }
  }, [insertVaccinationsResult.data]);

  useEffect(() => {
    setConflictingInventory(
      entries.some((e) => e.inventory && e.inventory.length > 1)
    );
    setConflictingVfc(
      entries.some((e) => e.inventory?.some((i) => i.vfc !== vfc))
    );
  }, [entries, vfc]);

  const handleCancelClick = () => {
    cleanEntries();
    setShowCancelConfirm(false);
    setProviderId(account?.title !== 'Other' ? account?.id : undefined);
  };

  const handleRestartClick = () => {
    cleanEntries();
    setFinished(false);
    setProviderId(account?.title !== 'Other' ? account?.id : undefined);
  };

  useEffect(() => {
    getTotalEntries(entries.length);
    setVaccineWithoutVisDate(!checkVaccinationsVisDate());

    validateDuplicateTypes(entries);
  }, [entries]);

  useEffect(() => {
    if (!practicePatient || !entries || entries.length === 0) return;
    const _vaccineNdcs = entries
      .filter((entry) => !!entry.vaccine)
      .map((entry) => entry.gs1?.ndc);

    setvaccineNdcs(_vaccineNdcs);
  }, [entries, practicePatient]);

  /**
  The following state is used only to store the previous getDuplicatedVaccinations variables value in order to prevent multiple getDuplicatedVaccinations query calls
  **/
  const [
    getDuplicatedVaccinationsPreviousVariables,
    setGetDuplicatedVaccinationsPreviousVariables,
  ] = useState('');

  useEffect(() => {
    if (vaccineNdcs.length === 0) return;

    const variables = {
      practicePatientId: practicePatient?.id,
      givenAtFrom: moment(givenAt).startOf('day'),
      givenAtTo: moment(givenAt).endOf('day'),
      vaccineNdcs,
    };

    /**
    The following piece of code is used only to compare the previous getDuplicatedVaccinations variables value with the new one in order to prevent multiple getDuplicatedVaccinations query calls
    **/
    const variablesStringified = JSON.stringify(variables);
    if (variablesStringified === getDuplicatedVaccinationsPreviousVariables)
      return;
    setGetDuplicatedVaccinationsPreviousVariables(variablesStringified);

    getDuplicatedVaccinations({
      variables,
    });
  }, [vaccineNdcs, givenAt]);

  useEffect(() => {
    if (!duplicatedVaccinations) return;

    const duplicatedSaleNdcs = duplicatedVaccinations?.vaccinations.map((v) => {
      return v.vaccine.saleNdc10;
    });
    const duplicatedUseNdcs = duplicatedVaccinations?.vaccinations.map((v) => {
      return v.vaccine.useNdc10;
    });

    setDuplicatedVaccineNdcs([...duplicatedSaleNdcs, ...duplicatedUseNdcs]);
  }, [duplicatedVaccinations]);

  useEffect(() => {
    if (entries.length === 0 && !duplicatedVaccineNdcs) return;
    const checkDuplicatedsArray = entries.map((entry) => {
      return vaccineNdcs && duplicatedVaccineNdcs?.includes(entry.gs1?.ndc);
    });

    setHasDuplicated(checkDuplicatedsArray.includes(true));
  }, [entries, duplicatedVaccineNdcs]);

  useEffect(() => {
    if (entries.length === 0 || !givenAt) {
      return;
    }
    setExistExpiredVaccine(checkExpirations());
  }, [entries, givenAt]);

  useEffect(() => {
    setIsFinished(finished);
  }, [finished]);

  const canFinish = !(
    finished ||
    scanning ||
    conflictingInventory ||
    hasDuplicated ||
    existExpiredVaccine ||
    !entries.length ||
    duplicatedVaccineTypesMessages.length
  );
  const canCancel = scanning || !entries.length;

  useEffect(() => {
    if (!practicePatient || !confirmVfcCalled) return;
    setVfcChecked(practicePatient?.isVfcConfirmed ?? false);
  }, [practicePatient?.isVfcConfirmed]);

  const displayDuplicatedTypes = (vaccineTypesMessages: string[]) => {
    return vaccineTypesMessages.map((message) => (
      <StyledMessage color={backgroundColor.redWarning} margin="1rem 0 0 0">
        <div>
          <Icon name="warning sign" style={{ marginRight: '0.75rem' }} />
          {message}
        </div>
      </StyledMessage>
    ));
  };

  return (
    <>
      <Grid.Column>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Menu compact>
            <input
              size={1}
              style={{
                position: 'absolute',
                opacity: 0,
              }}
              data-automation-id={`test-value`}
              value={testValue}
              onChange={(e) => {
                setTestValue(e.target.value);
              }}
            />
            <Menu.Menu position="right" style={{ height: '8rem' }}>
              {vfcChecked || practicePatient?.isVfcConfirmed ? (
                <GS1GunScanner
                  style={{
                    display: 'flex',
                    width: '15rem',
                    justifyContent: 'center',
                    fontSize: '20px',
                  }}
                  testValue={testValue}
                  disabled={finished}
                  component={Menu.Item}
                  isScanning={vfcChecked}
                  renderOn={
                    <>
                      <Icon name="pause" />
                      Scanning
                    </>
                  }
                  renderOff={
                    <>
                      <Icon name="play" />
                      Scan
                    </>
                  }
                  onChange={(s) => setScanning(s)}
                  onGS1Scan={(_, value) => {
                    if (!value) return;
                    addEntry(value);
                  }}
                  onError={() =>
                    toast({
                      title: 'Unable to process code',
                      description:
                        "There was a problem trying to process the scanned QR, please make sure you're scanning the right one and try again.",
                      color: 'red',
                      time: 5000,
                    })
                  }
                />
              ) : practicePatient?.patientData.fromExtension ? (
                <Popup
                  trigger={
                    <Menu.Item
                      data-automation-id={`patient-scan-button`}
                      style={{
                        display: 'flex',
                        width: '15rem',
                        justifyContent: 'center',
                        fontSize: '20px',
                      }}
                    >
                      <Icon name="play" />
                      Scan
                      <input
                        style={{
                          position: 'absolute',
                          opacity: 0,
                        }}
                        data-automation-id={`test-value`}
                        value={testValue}
                        onChange={(e) => {
                          setTestValue(e.target.value);
                        }}
                      />
                    </Menu.Item>
                  }
                  content={
                    <div>
                      <h3>Is this a VFC patient?</h3>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          size="tiny"
                          color="olive"
                          content="Yes"
                          onClick={() => {
                            handleConfirmVfc(
                              practicePatient ? practicePatient.id : ''
                            );
                            if (!practicePatient.insurances.length) {
                              handleSaveVfcForPatientFromExtension(
                                practicePatient ? practicePatient.id : '',
                                true,
                                'medicaid'
                              );
                            } else {
                              setOpenVfcCriteriaModal(true);
                              setOpenVfcConfirm(false);
                            }
                          }}
                        />
                        <Button
                          size="tiny"
                          content="No"
                          onClick={() => {
                            handleConfirmVfc(
                              practicePatient ? practicePatient.id : ''
                            );
                            if (!practicePatient.insurances.length) {
                              handleSaveVfcForPatientFromExtension(
                                practicePatient ? practicePatient.id : '',
                                false,
                                null
                              );
                            } else {
                              handleSavePrimaryInsuranceVfc(
                                practicePatient
                                  ? practicePatient.insurances[0].id
                                  : '',
                                false,
                                null
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  }
                  on="click"
                  open={openVfcConfirm}
                  onClose={() => setOpenVfcConfirm(false)}
                  onOpen={() => setOpenVfcConfirm(true)}
                  position="left center"
                />
              ) : patientAge < 19 ||
                (patientAge >= 19 &&
                  !!practicePatient?.insurances.length &&
                  practicePatient.insurances[0].vfcEligible) ? (
                <Popup
                  trigger={
                    <Menu.Item
                      data-automation-id={`patient-scan-button`}
                      style={{
                        display: 'flex',
                        width: '15rem',
                        justifyContent: 'center',
                        fontSize: '20px',
                      }}
                    >
                      <Icon name="play" />
                      Scan
                    </Menu.Item>
                  }
                  content={
                    <div>
                      <h3>Is this a VFC patient?</h3>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          size="tiny"
                          color="olive"
                          content="Yes"
                          onClick={() => {
                            setOpenVfcCriteriaModal(true);
                            setOpenVfcConfirm(false);
                          }}
                        />
                        <Button
                          size="tiny"
                          content="No"
                          onClick={() => {
                            if (
                              patientAge < 19 &&
                              !practicePatient?.insurances.length
                            ) {
                              setOpenVfcConfirm(false);
                              history.push(
                                `/${practice.handler}/patients/${practicePatientId}/insurances/_new`
                              );
                            } else {
                              handleConfirmVfc(
                                practicePatient ? practicePatient.id : ''
                              );
                              handleSavePrimaryInsuranceVfc(
                                practicePatient
                                  ? practicePatient.insurances[0].id
                                  : '',
                                false,
                                null
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  }
                  on="click"
                  open={openVfcConfirm}
                  onClose={() => setOpenVfcConfirm(false)}
                  onOpen={() => setOpenVfcConfirm(true)}
                  position="left center"
                />
              ) : patientAge >= 19 && !practicePatient?.insurances.length ? (
                <Popup
                  trigger={
                    <Menu.Item
                      data-automation-id={`patient-scan-button`}
                      style={{
                        display: 'flex',
                        width: '15rem',
                        justifyContent: 'center',
                        fontSize: '20px',
                      }}
                    >
                      <Icon name="play" />
                      Scan
                    </Menu.Item>
                  }
                  content={
                    <div>
                      <h3>Is this a self pay patient?</h3>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          size="tiny"
                          color="olive"
                          content="Yes"
                          onClick={() => {
                            handleConfirmVfc(
                              practicePatient ? practicePatient.id : ''
                            );
                            setOpenVfcConfirm(false);
                          }}
                        />
                        <Button
                          size="tiny"
                          content="No"
                          onClick={() => {
                            setOpenVfcConfirm(false);
                            history.push(
                              `/${practice.handler}/patients/${practicePatientId}/insurances/_new`
                            );
                          }}
                        />
                      </div>
                    </div>
                  }
                  on="click"
                  open={openVfcConfirm}
                  onClose={() => setOpenVfcConfirm(false)}
                  onOpen={() => setOpenVfcConfirm(true)}
                  position="left center"
                />
              ) : (
                <GS1GunScanner
                  style={{
                    display: 'flex',
                    width: '15rem',
                    justifyContent: 'center',
                    fontSize: '20px',
                  }}
                  testValue={testValue}
                  disabled={finished}
                  component={Menu.Item}
                  isScanning={vfcChecked}
                  renderOn={
                    <>
                      <Icon name="pause" />
                      Scanning
                    </>
                  }
                  renderOff={
                    <>
                      <Icon name="play" />
                      Scan
                    </>
                  }
                  onChange={(s) => setScanning(s)}
                  onGS1Scan={(_, value) => {
                    if (!value) return;
                    addEntry(value);
                  }}
                  onError={() =>
                    toast({
                      title: 'Unable to process code',
                      description:
                        "There was a problem trying to process the scanned QR, please make sure you're scanning the right one and try again.",
                      color: 'red',
                      time: 5000,
                    })
                  }
                />
              )}

              <StyledMenuItem
                data-automation-id={`patient-scan-finish-button`}
                name="finish"
                onClick={handleFinishOnClick}
                color={
                  !canFinish
                    ? undefined
                    : !providerId ||
                      !prescriberId ||
                      !givenAt ||
                      vaccineWithoutVisDate
                    ? 'grey'
                    : 'green'
                }
                disabled={!canFinish || disableFinishBtn}
              >
                {!!entries.length &&
                (conflictingInventory ||
                  !providerId ||
                  !prescriberId ||
                  hasDuplicated ||
                  !givenAt ||
                  vaccineWithoutVisDate ||
                  existExpiredVaccine) ? (
                  <Icon name="warning sign" />
                ) : (
                  <Icon name="check" />
                )}
                Finish
              </StyledMenuItem>
              {finished ? (
                <StyledMenuItem
                  style={{ borderLeft: '1px solid #dededf' }}
                  name="restart"
                  onClick={handleRestartClick}
                >
                  <Icon name="refresh" />
                  Restart
                </StyledMenuItem>
              ) : (
                <StyledMenuItem
                  data-automation-id={`patient-scan-cancel-button`}
                  style={{ borderLeft: '1px solid #dededf' }}
                  name="cancel"
                  color={canCancel ? undefined : 'red'}
                  disabled={canCancel}
                  onClick={() => setShowCancelConfirm(true)}
                >
                  <Icon name="cancel" />
                  Cancel
                </StyledMenuItem>
              )}
            </Menu.Menu>
          </Menu>
        </div>

        {practicePatient?.consentForIis === null && patientAge >= 19 && (
          <Modal
            closeIcon
            open={showConfirmConsentForIis}
            onOpen={() => setShowConfirmConsentForIis(false)}
            onClose={() => setShowConfirmConsentForIis(false)}
            size="tiny"
          >
            <Modal.Content>
              <Modal.Description>
                <Message
                  warning
                  icon="warning sign"
                  content="To continue please set patient's consent to share record with immunization
                registry. If consent is not set immunizations won't be saved."
                />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="Don't give consent"
                labelPosition="right"
                icon="close"
                onClick={() =>
                  handleSaveConsentForIis(
                    practicePatient ? practicePatient.id : '',
                    false
                  )
                }
                negative
              />
              <Button
                content="Give consent"
                labelPosition="right"
                icon="checkmark"
                onClick={() =>
                  handleSaveConsentForIis(
                    practicePatient ? practicePatient.id : '',
                    true
                  )
                }
                positive
              />
            </Modal.Actions>
          </Modal>
        )}

        <Confirm
          data-automation-id={`patient-scan-vfc-confirm`}
          open={showVfcConfirm}
          header="Inventory selection"
          content={
            vfc
              ? 'The patient is VFC eligible, but the selected inventory is private. Continue?'
              : 'The patient is Non-VFC eligible, but the selected inventory is VFC. Continue?'
          }
          cancelButton="Change vial"
          confirmButton={vfc ? 'Use Private' : 'Use VFC'}
          size="tiny"
          onCancel={() => {
            setDisableFinishBtn(false);
            setShowVfcConfirm(false);
          }}
          onConfirm={finish}
        />

        <Confirm
          data-automation-id={`patient-scan-cancel-confirm`}
          open={showCancelConfirm}
          content="Are you sure you want to cancel the process?"
          cancelButton="No"
          confirmButton="Yes"
          size="mini"
          onCancel={() => setShowCancelConfirm(false)}
          onConfirm={handleCancelClick}
        />
        {finished && insertVaccinationsResult.data ? (
          <Segment>
            <Message success>
              <Icon name="check" />
              The following vaccinations were scanned successfully!
            </Message>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Lot</Table.HeaderCell>
                  <Table.HeaderCell>Expiration</Table.HeaderCell>
                  <Table.HeaderCell>Route</Table.HeaderCell>
                  <Table.HeaderCell>Site</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {entries.map((vaccination, index) => {
                  const routeIsNotINorPO =
                    vaccination.vaccinationRoute !== VaccinationRoutes.IN &&
                    vaccination.vaccinationRoute !== VaccinationRoutes.PO;

                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {vaccination.vaccine?.name ||
                          vaccination.gs1?.ndc ||
                          '-'}
                      </Table.Cell>
                      <Table.Cell>{vaccination.gs1?.lot || '-'}</Table.Cell>
                      <Table.Cell>
                        {vaccination.gs1
                          ? moment(vaccination.gs1?.exp).format('MM-DD-YYYY')
                          : '-'}
                      </Table.Cell>
                      <Table.Cell>{vaccination.vaccinationRoute}</Table.Cell>
                      <Table.Cell>
                        {routeIsNotINorPO && vaccination.vaccinationSite
                          ? humanizeText(vaccination.vaccinationSite, {
                              capitalize: 'first',
                              delimiter: '_',
                            })
                          : '-'}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Segment>
        ) : !!entries.length ? (
          <Segment loading={loading}>
            <Header
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <h2>Scanned vaccines</h2>
              {practiceSettings?.practice.settings.forms?.florida?.consentForm
                ?.enabled && (
                <PatientEligibilityScreeningRecordPdf
                  pageBlurred={pageBlurred}
                  practicePatient={practicePatient}
                  providerFullName={providerFullName}
                  vaccinations={entries.map(
                    ({
                      inventory,
                      vaccine,
                      vaccinationSite,
                      vaccinationRoute,
                    }): ScannedVacinationConsentPDF => {
                      return {
                        vaccine,
                        ...(inventory ? { inventory: inventory[0] } : {}),
                        givenAt,
                        providerId,
                        site:
                          vaccinationRoute === 'PO' ? null : vaccinationSite,
                        route: vaccinationRoute,
                      };
                    }
                  )}
                />
              )}{' '}
            </Header>
            <Form>
              <Form.Group>
                <Form.Field inline style={{ margin: '0 0 0.5rem 0' }}>
                  <label>Given by:</label>
                  <Input
                    input={
                      <div style={{ zIndex: '20' }}>
                        <ProviderPicker
                          placeholder="Select Provider"
                          defaultValue={
                            account?.title === 'Other' ||
                            account?.title === null ||
                            (account?.profile?.permissionAs &&
                              !account?.profile?.permissionAs?.provider)
                              ? ''
                              : providerId
                          }
                          onChange={(providerId) => setProviderId(providerId)}
                          onProviderSelected={(provider) => {
                            setProviderFullName(
                              `${provider.account.firstName} ${provider.account.lastName}`
                            );
                          }}
                          isEmbedded={isEmbedded}
                          isClereable={false}
                        />
                      </div>
                    }
                  />
                </Form.Field>
                <Form.Field inline style={{ margin: '0 0 0.5rem 0' }}>
                  <label>Prescribed by:</label>
                  <Input
                    input={
                      <div style={{ zIndex: '20' }}>
                        <ProviderPicker
                          prescriber
                          placeholder="Select Prescriber"
                          defaultValue={
                            isValidPrescriber ? prescriberId : undefined
                          }
                          onChange={(prescriberId) =>
                            setPrescriberId(prescriberId)
                          }
                          isClereable={false}
                        />
                      </div>
                    }
                  />
                </Form.Field>
                <Form.Field inline style={{ margin: '0 0 0.5rem 0' }}>
                  <label>Vaccination date:</label>
                  <Input
                    input={
                      <div style={{ zIndex: '20' }}>
                        <DateTimePicker
                          tz={practice.timezone}
                          selected={givenAt}
                          scrollableYearDropdown
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          maxDate={new Date()}
                          minDate={minVaxDate}
                          yearDropdownItemNumber={18}
                          onChange={(date) => setGivenAt(date as Date)}
                          inputProps={{ style: { width: 114 } }}
                          dateFormat={DateFormats.DATE}
                        />
                      </div>
                    }
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            {!!duplicatedVaccineTypesMessages.length &&
              displayDuplicatedTypes(duplicatedVaccineTypesMessages)}
            <ScannedItems
              givenAt={givenAt}
              patientAge={patientAge}
              scanned={entries}
              allowRemove={!finished}
              onRemove={removeEntry}
              onSiteChange={changeVaccinationSite}
              onRouteChange={changeVaccinationRoute}
              onVisDateChange={changeVaccinationVisDate}
              vfc={vfc}
              duplicatedVaccineNdcs={duplicatedVaccineNdcs}
              practicePatientId={practicePatientId}
              addWarning={addWarning}
            />
          </Segment>
        ) : (
          <Segment placeholder loading={loading}>
            <Header icon>
              <Icon name="qrcode" />
              Start scanning using the Scan Gun
            </Header>
          </Segment>
        )}
        {practicePatient?.consentForIis === null && patientAge >= 19 ? (
          <Message
            icon="warning sign"
            warning
            size="tiny"
            content={
              <>
                For Patients over 19 years old, consent is required to share
                their record with immunization registry. You can set the consent
                option from the{' '}
                <Link
                  to={`/${practice.handler}/patients/${practicePatientId}/info`}
                >
                  patient info window
                </Link>
                .
              </>
            }
          />
        ) : null}
        {!!entries.length &&
        patientAge >= 19 &&
        practicePatient?.consentForIis !== null ? (
          <Message
            positive
            negative={!practicePatient?.consentForIis}
            size="tiny"
            content={
              !practicePatient?.consentForIis ? (
                <p>
                  This patient has not given consent to share their record with
                  immunization registry. You can set the consent option from the{' '}
                  <Link
                    to={`/${practice.handler}/patients/${practicePatientId}/info`}
                  >
                    patient info window
                  </Link>
                  .
                </p>
              ) : (
                <p>
                  This patient has given consent to share their record with
                  immunization registry. You can set the consent option from the{' '}
                  <Link
                    to={`/${practice.handler}/patients/${practicePatientId}/info`}
                  >
                    patient info window
                  </Link>
                  .
                </p>
              )
            }
            icon={!practicePatient?.consentForIis ? 'warning circle' : 'check'}
          />
        ) : null}
      </Grid.Column>
      <Modal
        onClose={() => setOpenVfcCriteriaModal(false)}
        onOpen={() => setOpenVfcCriteriaModal(true)}
        open={openVfcCriteriaModal}
        size="mini"
        closeIcon
      >
        <Modal.Header>Select VFC criteria</Modal.Header>
        <Modal.Content>
          <Dropdown
            placeholder="Filter by practice"
            fluid
            selection
            value={selectedVfcCriteria}
            onChange={(e, data) =>
              setSelectedVfcCriteria(data.value as VfcCriteria)
            }
            options={
              practice.profile.address?.state === 'NY'
                ? [
                    ...VFC_OPTIONS,
                    {
                      key: 'ch_plus_b',
                      value: 'ch_plus_b',
                      text: 'CHPlusB',
                    },
                  ]
                : VFC_OPTIONS
            }
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Cancel"
            onClick={() => setOpenVfcCriteriaModal(false)}
          />
          <Button
            primary
            type="submit"
            content="Confirm"
            onClick={() => {
              if (patientAge < 19 && !practicePatient?.insurances.length) {
                handleSaveInsurance();
              } else {
                handleSavePrimaryInsuranceVfc(
                  practicePatient ? practicePatient.insurances[0].id : '',
                  true,
                  selectedVfcCriteria
                );
              }
              handleConfirmVfc(practicePatient ? practicePatient.id : '');
              setOpenVfcCriteriaModal(false);
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  justify-content: center;
  width: 15rem;
  font-size: 20px;
  color: ${(props) => props.color} !important;
  background-color: #f2f2f2 !important;
  &:hover {
    background-color: #f9fafb !important;
  }
`;

export const StyledMessage = styled.div<StyledMessageProps>`
  background-color: ${(props) => props.color};
  color: #c92a2a;
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 4px;
  margin: ${(props) => props.margin};
`;

export default Scanner;
