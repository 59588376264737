import {
  useApplicationState,
  usePatientData,
  usePractice,
} from '@bluefox/contexts';
import { Link } from 'react-router-dom';
import {
  Button,
  Icon,
  SemanticICONS,
  Popup,
  ButtonProps,
} from 'semantic-ui-react';

interface PatientMenuButtonType {
  icon: SemanticICONS;
  label: string;
  alert?: string;
  to: string;
  buttonProps?: ButtonProps;
}

const PatientMenuButton = ({
  icon,
  label,
  alert,
  to,
  buttonProps = {},
}: PatientMenuButtonType) => {
  const practice = usePractice();
  const patient = usePatientData();

  return (
    <Button
      as={Link}
      to={`/${practice.handler}/patients/${patient?.practicePatientId}/${to}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      basic
      {...buttonProps}
    >
      {!!alert && (
        <Popup
          style={{ backgroundColor: '#FCF3CF' }}
          size="small"
          basic
          position="right center"
          content={alert}
          trigger={
            <Icon
              name="warning circle"
              size="large"
              corner="top right"
              color="red"
            />
          }
        />
      )}
      <Icon name={icon} size="big" style={{ margin: '0 auto 1rem' }} />
      {label}
    </Button>
  );
};
export default PatientMenuButton;
