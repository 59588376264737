import React, {
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-semantic-toasts';
import { Button } from 'semantic-ui-react';

export interface GunScannerProps {
  component: React.ElementType;
  onScan?: (value: string) => void;
  renderOn: ReactNode;
  renderOff: ReactNode;
  onChange?: (scanning: boolean) => void;
  isScanning?: boolean;
  testValue?: string;
  [k: string]: any;
}

const GunScanner = ({
  component: Component,
  renderOn,
  renderOff,
  onScan,
  onChange = () => {},
  isScanning = false,
  testValue,
  ...rest
}: GunScannerProps) => {
  const [scanning, setScanning] = useState(isScanning);

  const value = useRef<string>(testValue ?? '');

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (!scanning) {
      return;
    }

    let key = e.key;
    let done = false;

    if (key === 'F8') {
      key = '|';
    }

    if (key === 'Enter') {
      done = true;
    } else if (key === '\\') {
      key = '';
      done = value.current !== '';
    }

    if (!done && key.length > 1) return;

    if (done) {
      if (onScan) onScan(value.current as string);
      value.current = '';
      return;
    }

    value.current += key;
  };

  const handleOnClick = useCallback(
    (e) => {
      // hacky way to differenciate between click and Enter, this is Enter
      if (value.current !== '' && e.clientX === 0 && e.clientY === 0) {
        if (onScan) onScan(value.current as string);
        value.current = '';
      } else {
        setScanning(!scanning);
      }
      value.current = '';
    },
    [scanning, onScan]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (e.relatedTarget?.id === '__scanButton') return;
      value.current = '';
      if (scanning) {
        setScanning(false);
        toast({
          type: 'warning',
          title: 'Scanning has stopped.',
          description:
            'The scanning process has stopped, click Scan button to resume.',
        });
      }
    },
    [scanning, value, setScanning]
  );

  useEffect(() => onChange(scanning), [onChange, scanning]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (scanning) inputRef.current?.focus();
  }, [scanning]);

  useEffect(() => {
    setScanning(isScanning);
  }, [isScanning]);

  // useEffect just for automatization testing purposes
  useEffect(() => {
    if (!testValue) return;
    if (onScan) onScan(testValue as string);
  }, [testValue]);

  return (
    <>
      <Component
        {...rest}
        id="__scanButton"
        as={Button}
        onClick={handleOnClick}
        content={scanning ? renderOn : renderOff}
      />
      <input
        ref={inputRef}
        // onKeyPress={handleKeyDown}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        type="text"
        style={{
          height: 0,
          padding: 0,
          margin: 0,
          border: 0,
          position: 'absolute',
        }}
      />
    </>
  );
};

export default GunScanner;
