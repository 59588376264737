import { useQuery } from '@apollo/client';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import FullLayout from '@components/FullLayout';
import Welcome from '@components/Welcome';
import { PatientsQuery } from '@bluefox/graphql/patients';
import { Sex, PracticePatient } from '@bluefox/models/Patient';
import PatientForm from '@bluefox/ui/PatientForm';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { Link, Route, useHistory } from 'react-router-dom';
import {
  Header,
  Icon,
  Input,
  Menu,
  Message,
  Modal,
  Pagination,
  Placeholder,
  Segment,
  Table,
  Label,
  Dropdown,
  Popup,
  Transition,
  Image,
} from 'semantic-ui-react';
import { usePractice, useSession } from '@bluefox/contexts';
import { VfcCriteria } from '@bluefox/models/Insurances';
import { useApplicationState } from '@bluefox/contexts';
import AppointmentsScreen from './appointments';
import { DateFormats } from '@bluefox/models/Dates';

export interface PatientsData {
  patients: PracticePatient[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface PracticeOption {
  text: string;
  value: string;
}

const ENTRIES_PER_PAGE = 15;

const PatientsScreen = () => {
  const session = useSession();
  const { isEmbedded } = useApplicationState();
  const history = useHistory();
  const practice = usePractice();

  const [searchQuery, setSearchQuery] = useState('');
  const [dobQuery, setDobQuery] = useState<Date | undefined>(undefined);
  const [criteria, setCriteria] = useState<object>({});
  const [searchIsActive, setSearchIsActive] = useState<string>('allPatients');
  const [orgPractices, setOrgPractices] = useState<PracticeOption[]>([]);
  const [practiceHandler, setPracticeHandler] = useState(
    practice.organization?.practices.map((p) => p.handler)
  );

  const [showWelcome, setShowWelcome] = useState(false);
  const [typing, setTyping] = useState(false);
  const [page, setPage] = useState(0);
  const [actionClicked, setActionClicked] = useState<string>('');

  const url = `/${practice.handler}/patients`;

  const { data, loading, error, refetch } = useQuery<PatientsData>(
    PatientsQuery,
    {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      skip:
        (!searchQuery && !dobQuery) || (!dobQuery && !!searchQuery && typing),
    }
  );

  const handleOnSave = (practicePatient: PracticePatient) => {
    refetch();
    if (actionClicked === 'save') {
      history.push(`${url}/${practicePatient.id}`);
    } else if (actionClicked === 'saveAndContinue') {
      history.push(`${url}/${practicePatient.id}/insurances/_new`);
    }
  };

  useEffect(() => {
    let _criteria = {};

    if (searchQuery) {
      _criteria = {
        _or: [
          {
            patientData: {
              lookup: {
                _ilike: `%${searchQuery}%`,
              },
            },
          },
          { mrn: { _ilike: `%${searchQuery}%` } },
        ],
        practice: { handler: { _in: practiceHandler } },
      };
    }

    if (dobQuery) {
      _criteria = {
        _and: [
          { ..._criteria },
          { patientData: { birthdate: { _eq: dobQuery } } },
        ],
        practice: { handler: { _in: practiceHandler } },
      };
    }

    if (searchIsActive !== 'allPatients') {
      if (searchIsActive === 'activePatients') {
        _criteria = {
          _and: [{ ..._criteria }, { active: { _in: [true] } }],
        };
      } else {
        _criteria = {
          _and: [{ ..._criteria }, { active: { _in: [false] } }],
        };
      }
    }

    setPage(0);
    setCriteria(_criteria);
  }, [searchQuery, dobQuery, searchIsActive, practiceHandler]);

  useEffect(() => {
    if (!!searchQuery && searchQuery.length < 3) {
      setTyping(true);
      setPage(0);
    } else {
      setTyping(false);
    }
  }, [searchQuery]);

  const handleOnClose = () => {
    history.push(url);
  };

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const handlePracticeValue = (value: string) => {
    setPracticeHandler(value.split(','));
  };

  useEffect(() => {
    if (
      !practice.organization?.practices ||
      practice.organization?.practices.length < 1
    )
      return;

    setOrgPractices(
      practice.organization?.practices.map((p) => ({
        text: p.name,
        value: p.handler,
      }))
    );
  }, [practice]);

  useEffect(() => {
    if (searchQuery || dobQuery) setShowWelcome(true);
  }, [searchQuery, dobQuery]);

  return (
    <FullLayout data-automation-id="patients">
      <div
        style={{
          minHeight: !showWelcome ? '180px' : '10px',
          transition: 'min-height 1s ease-out',
          overflow: 'hidden',
        }}
      >
        <Transition visible={!showWelcome} animation="fade down" duration={200}>
          <div
            style={{
              width: '100%',
              position: 'absolute',
            }}
          >
            <Welcome />
          </div>
        </Transition>
        <Transition visible={showWelcome}>
          <div>
            <Segment
              padded
              basic
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Header as="h1">
                <Icon name="users" />
                Patients
              </Header>
              <div>
                {practice.profile.logo ? (
                  <Image size="small" src="/canid-dark-logo.svg" />
                ) : (
                  <Header as={'h1'}>{practice.name.toUpperCase()}</Header>
                )}
              </div>
            </Segment>
          </div>
        </Transition>
      </div>

      <Menu borderless style={{ marginTop: '0px' }}>
        <Menu.Item
          as={Link}
          to={`${url}/_new-basic`}
          data-automation-id="patients-add-new"
          name="newPatient"
          icon="user plus"
        />

        <Menu.Menu
          position="right"
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          <Menu.Item>
            <Input
              data-automation-id="patients-search"
              value={searchQuery}
              onChange={(_, { value }) => {
                setSearchQuery(value);
              }}
              icon="search"
              placeholder="Search..."
              loading={typing || loading}
              input={{
                autocomplete: 'off',
                autocorrect: 'off',
              }}
            />
          </Menu.Item>
          <Menu.Item>
            <DateTimePicker
              tz={practice.timezone}
              dataAutomationId="patients-search-date-of-birth"
              placeholderText="Search by Date of Birth"
              selected={dobQuery}
              onChange={(d) => setDobQuery(d ? (d as Date) : undefined)}
              onClear={() => setDobQuery(undefined)}
              maxDate={new Date()}
              showYearDropdown
              showMonthDropdown
              scrollableYearDropdown
              dropdownMode="select"
              isClearable
            />
          </Menu.Item>
          {practice.organization?.practices &&
            practice.organization?.practices.length > 1 && (
              <Menu.Item>
                <Dropdown
                  style={{ minWidth: '15rem' }}
                  placeholder="Filter by practice"
                  fluid
                  selection
                  onChange={(e, data) => {
                    handlePracticeValue(data.value?.toString() || '');
                  }}
                  options={[
                    {
                      text: 'All practices',
                      value: practice.organization.practices
                        .map((p) => {
                          return p.handler;
                        })
                        .join(','),
                    },
                    ...orgPractices,
                  ]}
                />
              </Menu.Item>
            )}
          <Menu.Item>
            <Dropdown
              style={{ minWidth: '11rem' }}
              placeholder="Filter by patient status"
              fluid
              selection
              onChange={(e, data) => {
                const dataValue = data.value ?? 'allPatients';
                setSearchIsActive(dataValue.toString());
              }}
              options={[
                { text: 'All patients', value: 'allPatients' },
                { text: 'Active patients', value: 'activePatients' },
                { text: 'Inactive patients', value: 'inactivePatients' },
              ]}
              defaultValue="allPatients"
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Transition
        animation="fade"
        duration={300}
        visible={!!searchQuery || !!dobQuery}
      >
        <div>
          {error ? (
            <Message error>{error.message}</Message>
          ) : (
            <Table
              selectable
              style={{
                display: !searchQuery && !dobQuery ? 'none' : undefined,
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>First Name</Table.HeaderCell>
                  <Table.HeaderCell>Middle Name</Table.HeaderCell>
                  <Table.HeaderCell>Last Name</Table.HeaderCell>
                  <Table.HeaderCell>Date of Birth / Age</Table.HeaderCell>
                  <Table.HeaderCell>Sex</Table.HeaderCell>
                  <Table.HeaderCell>Insurance</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {loading ? (
                  <Table.Row>
                    <Table.Cell colSpan={6}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                ) : !!data?.patients.length ? (
                  data?.patients.map(
                    ({
                      id,
                      patientData: {
                        firstName,
                        middleName,
                        lastName,
                        birthdate,
                        sex,
                      },
                      active,
                      insurances,
                    }) => (
                      <Table.Row
                        key={id}
                        onClick={() => history.push(`${url}/${id}`)}
                        style={{
                          cursor: 'pointer',
                          color: !active ? 'lightgrey' : undefined,
                        }}
                        data-automation-id={`patients-${id}`}
                      >
                        <Table.Cell>{firstName}</Table.Cell>
                        <Table.Cell>{middleName}</Table.Cell>
                        <Table.Cell>{lastName}</Table.Cell>
                        <Table.Cell>
                          {`${moment(birthdate).format(DateFormats.DATE)} `}
                          <Moment
                            durationFromNow
                            date={birthdate}
                            trim
                            format="(y [years], M [months], d [days])"
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {sex === Sex.Female ? (
                            <Icon name="venus" />
                          ) : (
                            <Icon name="mars" />
                          )}
                          {sex}
                        </Table.Cell>
                        <Table.Cell>
                          {(() => {
                            const i = insurances.at(0);
                            if (!i || i.vfcCriteria === VfcCriteria.uninsured)
                              return (
                                <Label
                                  size="small"
                                  content="No active insurance"
                                  icon="warning sign"
                                  color="orange"
                                />
                              );
                            return i && i.active ? (
                              <p>{i.company?.name}</p>
                            ) : null;
                          })()}
                        </Table.Cell>
                        <Table.Cell width={1}>
                          {!active && (
                            <Popup
                              trigger={
                                <Icon
                                  name="user outline"
                                  circular
                                  size="small"
                                />
                              }
                              content="Inactive patient"
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={6}>
                      <Message>No Patients found.</Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                  <Table.HeaderCell colSpan={6} textAlign="right">
                    <Pagination
                      activePage={page || 1}
                      disabled={!total || total < ENTRIES_PER_PAGE}
                      defaultActivePage={1}
                      boundaryRange={0}
                      siblingRange={1}
                      onPageChange={(e, { activePage }) =>
                        setPage(activePage as number)
                      }
                      totalPages={totalPages}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          )}
        </div>
      </Transition>
      {isEmbedded && <AppointmentsScreen />}

      <Route path={`/${practice.handler}/patients/_new-basic/:mrn?`} exact>
        <Modal open={true} onClose={handleOnClose} closeIcon>
          <Modal.Content>
            <PatientForm
              shortForm
              onSave={handleOnSave}
              leftActions={[
                {
                  'data-automation-id': 'patient-basic-cancel',
                  type: 'button',
                  content: 'Cancel',
                  icon: 'cancel',
                  name: 'cancel',
                  onClick: handleOnClose,
                },
              ]}
              rightActions={[
                {
                  'data-automation-id': 'patient-basic-submit',
                  basic: true,
                  type: 'submit',
                  content: 'Save',
                  icon: 'save',
                  name: 'save',
                  onClick: () => {
                    setActionClicked('save');
                  },
                },
                {
                  'data-automation-id': 'patient-basic-submit-and-continue',
                  primary: true,
                  type: 'submit',
                  content: 'Save & Add Insurance',
                  icon: 'save',
                  name: 'saveAndContinue',
                  onClick: () => {
                    setActionClicked('saveAndContinue');
                  },
                },
              ]}
            />
          </Modal.Content>
        </Modal>
      </Route>
      <Route path={`/${practice.handler}/patients/_new`} exact>
        <Modal open={true} onClose={handleOnClose} closeIcon>
          <Modal.Content>
            <PatientForm
              shortForm={false}
              onSave={handleOnSave}
              leftActions={[
                {
                  'data-automation-id': 'patient-basic-cancel',
                  type: 'button',
                  content: 'Cancel',
                  icon: 'cancel',
                  name: 'cancel',
                  onClick: handleOnClose,
                },
              ]}
              rightActions={[
                {
                  'data-automation-id': 'patient-basic-submit',
                  basic: true,
                  type: 'submit',
                  content: 'Save',
                  icon: 'save',
                  name: 'save',
                  onClick: () => {
                    setActionClicked('save');
                  },
                },
                {
                  'data-automation-id': 'patient-basic-submit-and-continue',
                  primary: true,
                  type: 'submit',
                  content: 'Save & Add Insurance',
                  icon: 'save',
                  name: 'saveAndContinue',
                  onClick: () => {
                    setActionClicked('saveAndContinue');
                  },
                },
              ]}
            />
          </Modal.Content>
        </Modal>
      </Route>
    </FullLayout>
  );
};

export default PatientsScreen;
