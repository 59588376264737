import { ReactNode, useEffect, useState } from 'react';
import { Button, Icon, Label, Menu, Popup } from 'semantic-ui-react';

import {
  VaccinationSites,
  Limbs,
  Sides,
  Sites,
} from '@bluefox/models/Vaccination';

interface VaccinationSitePickerProps {
  defaultValue: VaccinationSites;
  onChange?: (site: VaccinationSites) => void;
  disabled?: boolean;
}

const VaccinationSitePicker = ({
  defaultValue,
  onChange,
  disabled = false,
}: VaccinationSitePickerProps) => {
  const [value, setValue] = useState<VaccinationSites>(
    defaultValue === null ? VaccinationSites.rightArm : defaultValue
  );

  useEffect(() => {
    if (onChange) onChange(value);
  }, [onChange, value]);

  const getIcon = (predicate: boolean): ReactNode => {
    return predicate ? <Icon name="syringe" /> : <Icon name="circle outline" />;
  };

  const handleSiteChange = (
    newSide?: string,
    newLimb?: string,
    newPosition?: string
  ) => {
    const [site, limb, position] = value.split('_');

    const currentLimb = newLimb ?? limb;

    if (newSide) {
      const currentPosition =
        position && newLimb === limb ? `_${position}` : '';
      setValue(
        `${newSide}_${currentLimb}${currentPosition}` as VaccinationSites
      );
    }

    if (newPosition) {
      const currentSite = site ? site : '';
      setValue(
        `${currentSite}_${currentLimb}_${newPosition}` as VaccinationSites
      );
    }
  };

  return (
    <>
      {disabled ? (
        <Label content="No site required" />
      ) : (
        <Popup
          on={'click'}
          flowing
          position="top right"
          trigger={
            <Button
              fluid
              size="tiny"
              color="teal"
              content={value && value.includes(Limbs.arm) ? 'Arm' : 'Thigh'}
              label={value && value.includes(Sides.left) ? 'Left' : 'Right'}
              labelPosition="left"
            />
          }
        >
          <div style={{ width: '30rem' }}>
            <Menu attached="top">
              <Menu.Item header style={{ width: '5.5rem' }}>
                ARM
              </Menu.Item>
              <Menu.Item
                active={value.includes(VaccinationSites.leftArm)}
                onClick={() => handleSiteChange(Sides.left, Limbs.arm)}
              >
                {getIcon(value.includes(VaccinationSites.leftArm))}
                Left
              </Menu.Item>
              <Menu.Item
                active={value.includes(VaccinationSites.rightArm)}
                onClick={() => handleSiteChange(Sides.right, Limbs.arm)}
              >
                {getIcon(value.includes(VaccinationSites.rightArm))}
                Right
              </Menu.Item>
              <Menu vertical style={{ border: 'none' }}>
                <Menu.Item
                  active={value.includes(`${Limbs.arm}_${Sites.upper}`)}
                  onClick={() =>
                    handleSiteChange(undefined, undefined, Sites.upper)
                  }
                  disabled={!value.includes(Limbs.arm)}
                >
                  {getIcon(value.includes(`${Limbs.arm}_${Sites.upper}`))}
                  Upper Arm
                </Menu.Item>
                <Menu.Item
                  active={value.includes(`${Limbs.arm}_${Sites.deltoid}`)}
                  onClick={() =>
                    handleSiteChange(undefined, undefined, Sites.deltoid)
                  }
                  disabled={!value.includes(Limbs.arm)}
                >
                  {getIcon(value.includes(`${Limbs.arm}_${Sites.deltoid}`))}
                  Deltoid
                </Menu.Item>
              </Menu>
            </Menu>

            <Menu attached="bottom">
              <Menu.Item header style={{ width: '5.5rem' }}>
                THIGH
              </Menu.Item>
              <Menu.Item
                active={value.includes(VaccinationSites.leftThigh)}
                onClick={() => handleSiteChange(Sides.left, Limbs.thigh)}
              >
                {getIcon(value.includes(VaccinationSites.leftThigh))}
                Left
              </Menu.Item>
              <Menu.Item
                active={value.includes(VaccinationSites.rightThigh)}
                onClick={() => handleSiteChange(Sides.right, Limbs.thigh)}
              >
                {getIcon(value.includes(VaccinationSites.rightThigh))}
                Right
              </Menu.Item>
              <Menu vertical style={{ border: 'none' }}>
                <Menu.Item
                  active={value.includes(Sites.anterolateral)}
                  onClick={() =>
                    handleSiteChange(undefined, undefined, Sites.anterolateral)
                  }
                  disabled={!value.includes(Limbs.thigh)}
                >
                  {getIcon(value.includes(Sites.anterolateral))}
                  Anterolateral
                </Menu.Item>
                <Menu.Item
                  active={value.includes(Sites.vastuslateralis)}
                  onClick={() =>
                    handleSiteChange(
                      undefined,
                      undefined,
                      Sites.vastuslateralis
                    )
                  }
                  disabled={!value.includes(Limbs.thigh)}
                >
                  {getIcon(value.includes(Sites.vastuslateralis))}
                  Vastus Lateralis
                </Menu.Item>
              </Menu>
            </Menu>
          </div>
        </Popup>
      )}
    </>
  );
};

export default VaccinationSitePicker;
