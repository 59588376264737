import { gql } from '@apollo/client';

export const VaccinationsByPracticeQuery = gql`
  query VaccinationsByPracticeQuery(
    $criteria: vaccinations_view_bool_exp
    $limit: Int
    $offset: Int
  ) {
    aggregating: vaccinations_view_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
    vaccinations: vaccinations_view(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { patientLastName: asc }
    ) {
      id
      site
      route
      practiceId
      registryReportedBy
      emrUploadedBy
      visPublicationDate
      mrn
      patientFirstName
      patientLastName
      patientAddress
      patientBirthdate
      insuranceCompanyName
      insuranceMemberId
      vfcEligible
      vfcCriteria
      types
      vaccineName
      saleNdc
      manufacturer
      dosage
      inventoryExpiration
      inventoryVfc
      lot
      givenByTitle
      givenByLastName
      givenByFirstName
      prescriberTitle
      prescriberLastName
      prescriberFirstName
      action
      createdAt
      givenAt
      modified
      consentForIis
      practicePatientId
    }
  }
`;

export const VaccinationsByPracticePatientQuery = gql`
  query VaccinationsByPracticePatientQuery($practicePatientId: uuid!) {
    vaccinations(
      where: { practicePatientId: { _eq: $practicePatientId } }
      order_by: { givenAt: asc }
    ) {
      id
      historic
      givenAt
      visDate
      lot
      createdAt
      immunizationObservation
      vaccine {
        id
        saleNdc
        name
        types
        manufacturer
      }
      prescriber {
        account {
          firstName
          lastName
          email
          signatureUrl
        }
      }
    }
  }
`;

export const VaccinationsByPatientQuery = gql`
  query VaccinationsQuery($practicePatientId: uuid!) {
    vaccinations(
      where: { practicePatientId: { _eq: $practicePatientId } }
      order_by: { givenAt: desc }
    ) {
      id
      historic
      givenAt
      visDate
      lot
      createdAt
      immunizationObservation
      vaccine {
        id
        saleNdc
        name
        types
        manufacturer
      }
      practice {
        name
        profile
      }
      inventory {
        lot
      }
    }
    vaccines {
      id
      name
      types
      manufacturer
    }
  }
`;

export const InsertVacciationsMutation = gql`
  mutation InsertVacciations($vaccinations: [vaccinations_insert_input]!) {
    vaccinations: insert_vaccinations(objects: $vaccinations) {
      affected_rows
      returning {
        id
        vaccine {
          name
        }
        inventory {
          lot
          expiration
        }
        route
        site
        siteDescription: site_description {
          description
        }
        dose
      }
    }
  }
`;

export const VaccinationsByTypeQuery = gql`
  query VaccinationsByTypeQuery($practicePatientId: uuid!, $types: [String!]) {
    vaccinations(
      where: {
        practicePatientId: { _eq: $practicePatientId }
        vaccine: { types: { _has_keys_any: $types } }
      }
      order_by: { givenAt: asc }
    ) {
      id
      createdAt
      givenAt
      route
      site
      siteDescription: site_description {
        description
      }
      vaccineId
      visDate
      createdAt
      immunizationObservation
      historic
      lot
      practicePatient {
        id
        patientData {
          birthdate
        }
      }
      prescriber {
        id
        account {
          firstName
          lastName
        }
      }
      provider {
        id
        account {
          firstName
          lastName
        }
      }
      vaccine {
        id
        saleNdc
        name
        manufacturer
        types
        cvx
        routes
      }
      inventory {
        id
        lot
        expiration
      }
      emrUploadedBy
      bot {
        status
      }
      athena {
        status
      }
    }
  }
`;

export const InconsistentVaccinationsByPatientQuery = gql`
  query InconsistentVaccinationsByPatientQuery($practicePatientId: uuid!) {
    vaccinations(
      where: {
        practicePatientId: { _eq: $practicePatientId }
        historic: { _eq: false }
        _or: [
          { vaccineId: { _is_null: true } }
          { inventoryId: { _is_null: true } }
        ]
      }
    ) {
      id
      givenAt
      barcode
      inventory {
        lot
        expiration
      }
      vaccine {
        name
        saleNdc
      }
    }
  }
`;

export const VaccinationsPerWeekQuery = gql`
  query VaccinationsPerWeekQuery($startingDate: date!, $endingDate: date!) {
    vaccinationsPerWeek: vaccinations_per_week(
      distinct_on: [toDate]
      where: { fromDate: { _gte: $startingDate, _lte: $endingDate } }
      order_by: { toDate: desc }
    ) {
      week
      vfcEligible
      fromDate
      toDate
      count
    }
  }
`;

export const MisusedInventoryPerMonth = gql`
  query MisusedInventoryPerMonth($startingDate: date!, $endingDate: date!) {
    misusedPrivate: vaccinations_per_month(
      where: {
        patientVfc: { _eq: true }
        inventoryVfc: { _eq: false }
        fromDate: { _gte: $startingDate, _lte: $endingDate }
      }
      limit: 3
    ) {
      count
      month
    }
    misusedVfc: vaccinations_per_month(
      where: {
        patientVfc: { _eq: false }
        inventoryVfc: { _eq: true }
        fromDate: { _gte: $startingDate, _lte: $endingDate }
      }
      limit: 3
    ) {
      count
      month
    }
  }
`;

export const VaccinatedPatientsPerWeekQuery = gql`
  query VaccinatedPatientsPerWeekQuery(
    $startingDate: date!
    $endingDate: date!
  ) {
    vaccinatedPatientsPerWeeks: vaccinated_patients_per_week(
      where: { fromDate: { _gte: $startingDate, _lte: $endingDate } }
      order_by: { toDate: asc }
    ) {
      count
      week
      toDate
      fromDate
    }
  }
`;

export const VaccinationsPerDay = gql`
  query VaccinationsPerDay(
    $limit: Int
    $offset: Int
    $criteria: vaccinations_bool_exp
  ) {
    vaccinations(
      limit: $limit
      offset: $offset
      where: $criteria
      order_by: { givenAt: desc }
    ) {
      id
      barcode
      billed
      givenAt
      route
      site
      prescriber {
        account {
          firstName
          lastName
        }
        title
      }
      provider {
        id
        account {
          firstName
          lastName
        }
        title
      }
      vaccine {
        name
        types
        useNdc
        useNdc10
        saleNdc
      }
      inventory {
        lot
        vfc
        expiration
      }
      practicePatient {
        id
        mrn
        patientData {
          fullName
          firstName
          lastName
          birthdate
        }
        insurances(where: { primary: { _eq: true } }) {
          company {
            name
          }
          memberId
          vfcEligible
          vfcCriteria
        }
      }
      emrUploadedBy
      bot {
        status
      }
      athena {
        status
      }
      develo {
        status
      }
    }
    aggregating: vaccinations_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const EmrUploadedByMutation = gql`
  mutation EmrUploadedByMutation($id: uuid!, $option: String!) {
    updateEmrUploadedBy: update_emr_uploaded_by(
      args: { vacid: $id, status: $option }
    ) {
      id
    }
  }
`;

export const RegistryReportedByMutation = gql`
  mutation RegistryReportedByMutation($id: uuid!, $option: String!) {
    updateRegistryReportedBy: update_registry_reported_by(
      args: { vacid: $id, status: $option }
    ) {
      id
    }
  }
`;

export const RetrieveAthenaPatientImmunizationsMutation = gql`
  mutation RetrieveAthenaPatientImmunizationsMutation(
    $practicePatientId: uuid!
  ) {
    retrieveAthenaPatientImmunizations(practicePatientId: $practicePatientId) {
      newData
      error
    }
  }
`;

export const DuplicatedVaccinationsQuery = gql`
  query DuplicatedVaccinationsQuery(
    $practicePatientId: uuid!
    $givenAtFrom: timestamptz
    $givenAtTo: timestamptz
    $vaccineNdcs: [String!]
  ) {
    vaccinations(
      where: {
        practicePatient: { id: { _eq: $practicePatientId } }
        givenAt: { _gte: $givenAtFrom, _lt: $givenAtTo }
        vaccine: {
          _or: [
            { saleNdc10: { _in: $vaccineNdcs } }
            { useNdc10: { _in: $vaccineNdcs } }
          ]
        }
      }
    ) {
      id
      givenAt
      vaccine {
        id
        name
        saleNdc10
        useNdc10
      }
      lot
    }
  }
`;

export const RecentVaccinationsByPatient = gql`
  query RecentVaccinationsByPatient(
    $practicePatientId: uuid!
    $givenAtFrom: timestamptz
    $givenAtTo: timestamptz
  ) {
    vaccinations(
      where: {
        practicePatient: { id: { _eq: $practicePatientId } }
        givenAt: { _gte: $givenAtFrom, _lt: $givenAtTo }
      }
    ) {
      id
      barcode
      vaccine {
        id
        name
      }
      lot
    }
  }
`;

export const DeleteVaccinationsMutation = gql`
  mutation DeleteVaccinationsMutation($vaccinationIds: [uuid!]) {
    delete_vaccinations(where: { id: { _in: $vaccinationIds } }) {
      affected_rows
    }
  }
`;

export const DeleteVaccinationMutation = gql`
  mutation DeleteVaccinationMutation($id: uuid!) {
    delete_vaccinations_by_pk(id: $id) {
      id
    }
  }
`;

export const UpdateVacciantion = gql`
  mutation UpdateVacciantion(
    $vaccinationId: uuid!
    $object: vaccinations_set_input
  ) {
    update_vaccinations_by_pk(
      pk_columns: { id: $vaccinationId }
      _set: $object
    ) {
      id
    }
  }
`;
