import {
  NotificationStatus,
  NotificationType,
} from '@bluefox/models/Notification';

export enum BillingNotificationFirstOptionsEnum {
  CPT_CODE_REJECTION = 'CPT_CODE_REJECTION',
  PATIENT_WITH_NO_ACTIVE_POLICY = 'PATIENT_WITH_NO_ACTIVE_POLICY',
  INSURANCE_REQUIERES_TO_CONTACT_THEM = 'INSURANCE_REQUIERES_TO_CONTACT_THEM',
  INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY = 'INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY',
  FREE_TEXT = 'FREE_TEXT',
}

export const BillingNotificationFirstOptionsEnumToText: Record<
  BillingNotificationFirstOptionsEnum,
  string
> = {
  [BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION]: `The CPT code [CPT Code] was rejected because it is inconsistent with the patient’s age.`,
  [BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY]:
    'The patient does not have an active policy registered and was vaccinated using a private inventory',
  [BillingNotificationFirstOptionsEnum.INSURANCE_REQUIERES_TO_CONTACT_THEM]:
    'The insurance is requesting that the subscriber contact them to update his/her personal info',
  [BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY]:
    'The patient received a booster doses and the insurance is requesting demostrate the medical necessity',
  [BillingNotificationFirstOptionsEnum.FREE_TEXT]: 'Free text',
};

export enum VaxSyncIntegrationFirstOptionsEnum {
  INMUNIZATION_NOT_REPORTED_EMR = 'INMUNIZATION_NOT_REPORTED_EMR',
  INMUNIZATION_NOT_REPORTED_IIS = 'INMUNIZATION_NOT_REPORTED_IIS',
  INMUNIZATION_INCONCISTENT_EMR_CANID = 'INMUNIZATION_INCONCISTENT_EMR_CANID',
  FREE_TEXT = 'FREE_TEXT',
}

export const vaxSyncIntegrationFirstOptionsEnumToText: Record<
  VaxSyncIntegrationFirstOptionsEnum,
  string
> = {
  [VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR]: `immunization could not be reported to the EMR`,
  [VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS]:
    'immunization could not be reported to the IIS',
  [VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID]:
    'Information is inconsistent between EMR and Canid',
  [VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT]: 'Free text',
};

export enum VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum {
  CREATE_ENCOUNTER = 'CREATE_ENCOUNTER',
  OPEN_ENCOUNTER = 'OPEN_ENCOUNTER',
  CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE = 'CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE',
}

export const vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText: Record<
  VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum,
  string
> = {
  [VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER]: `create the encounter`,
  [VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER]:
    'open de encounter',
  [VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE]:
    'confirm guardian information or create the profile in the registry',
};

export enum VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum {
  ENCOUNTER_CLOSED = 'ENCOUNTER_CLOSED',
  ENCOUNTER_MISSED = 'ENCOUNTER_MISSED',
  GUARDIAN_INFO_MISSING = 'GUARDIAN_INFO_MISSING',
}

export const vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText: Record<
  VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum,
  string
> = {
  [VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_CLOSED]: `the encounter is closed`,
  [VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_MISSED]:
    'the encounter is missed',
  [VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.GUARDIAN_INFO_MISSING]:
    'guardian information is missed',
};

export const notificationStatusEnumToText: Record<NotificationStatus, string> =
  {
    [NotificationStatus.pending_approval]: 'Pending Approval',
    [NotificationStatus.dismissed]: 'Dismissed',
    [NotificationStatus.pending]: 'Pending',
    [NotificationStatus.resolved]: 'Resolved',
    [NotificationStatus.rejected]: 'Rejected',
  };

export const notificationTypeEnumToText: Record<NotificationType, string> = {
  [NotificationType.athena]: 'Data Sync',
  [NotificationType.claim]: 'Billing',
  [NotificationType.develo]: 'Data Sync',
  [NotificationType.ecw]: 'Data Sync',
  [NotificationType.order]: 'Order',
  [NotificationType.vax_sync]: 'Data Sync',
};

export const notificationTypePP_ALL = [
  NotificationStatus.pending,
  NotificationStatus.resolved,
  NotificationStatus.rejected,
];

export const PendingNotificationsCountQueryDefaultWhere = {
  status: { _eq: NotificationStatus.pending },
};
