import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';

import {
  Accordion,
  Button,
  DropdownItemProps,
  DropdownProps,
  Form,
  Grid,
  Icon,
  InputProps,
  Label,
  Message,
  Modal,
  Popup,
  Segment,
  Table,
  TextAreaProps,
} from 'semantic-ui-react';

import { InventoryAdjustmentReasonByValues } from '@bluefox/graphql/inventoryAdjustment';
import {
  AdjustmentReason,
  AdjustmentReasons,
} from '@bluefox/models/InventoryAdjustment';
import { COLORS_BY_NAME } from '@bluefox/constants';
import { DateFormats } from '@bluefox/models/Dates';
import { VaccineWithInventory } from '@bluefox/models/Inventory';
import { vaccineTypeNames } from '@bluefox/store/vaccines';
import { InsertTicketMutation } from '@bluefox/graphql/tickets';
import { TicketDetailType } from '@bluefox/models/Tickets';
import { usePractice } from '@bluefox/contexts';
import { joinTypesArrayStrings } from './inventory';

type InventoryAccordionProps = {
  vaccines?: VaccineWithInventory[];
  privateInventory?: boolean;
  vaccineTypesCount?: { [key: string]: number };
};

interface AdjustmentReasonsData {
  adjustmentReasons: AdjustmentReason[];
}

enum FormInputKeys {
  adjustmentReason = 'adjustmentReason',
  additionalNotes = 'additionalNotes',
  countedDoses = 'countedDoses',
}

interface FormValues {
  [FormInputKeys.adjustmentReason]: string;
  [FormInputKeys.additionalNotes]: string;
  [FormInputKeys.countedDoses]: number | null;
}

const formInitialValues = {
  [FormInputKeys.adjustmentReason]: '',
  [FormInputKeys.additionalNotes]: '',
  [FormInputKeys.countedDoses]: null,
};

const InventoryAccordion = ({
  vaccines = [],
  privateInventory,
  vaccineTypesCount,
}: InventoryAccordionProps) => {
  const practice = usePractice();
  const [openDosesEdit, setOpenDosesEdit] = useState<boolean>(false);
  const [selectedVaccine, setSelectedVaccine] = useState<{
    vaccine: VaccineWithInventory;
    lot: string;
    doses: number;
  }>();
  const [formValues, setFormValues] = useState<FormValues>(formInitialValues);

  const { data: adjustmentReasonData } = useQuery<AdjustmentReasonsData>(
    InventoryAdjustmentReasonByValues,
    {
      variables: {
        values: [
          AdjustmentReasons.EXPIRED,
          AdjustmentReasons.WASTAGE,
          AdjustmentReasons.MISSING_VACCINE,
          AdjustmentReasons.INVALID_ENTRY,
          AdjustmentReasons.OTHER,
        ],
      },
    }
  );

  const [insertTicket] = useMutation(InsertTicketMutation);

  const handleAdjustDoses = (
    currentVaccine: VaccineWithInventory,
    lot: string,
    doses: number
  ) => {
    setOpenDosesEdit(true);
    setSelectedVaccine({ vaccine: currentVaccine, lot, doses });
  };

  const handleFormChange = (
    _: any,
    data: DropdownProps | TextAreaProps | InputProps
  ) => {
    setFormValues((prev) => ({ ...prev, [data.name]: data.value }));
  };

  const handleSumbit = async (e: React.FormEvent) => {
    e.preventDefault();

    const ticketDetail: TicketDetailType = {
      fields: [
        {
          title: 'Practice',
          detail: practice.name,
        },
        {
          title: 'Handler',
          detail: practice.handler,
        },
        {
          title: 'Vaccine Name',
          detail: selectedVaccine?.vaccine.name || '',
        },
        {
          title: 'Vaccine Lot',
          detail: selectedVaccine?.lot || '',
        },
        {
          title: 'Initial doses',
          detail: selectedVaccine?.doses.toString() || '',
        },
        {
          title: 'Counted doses',
          detail: formValues[FormInputKeys.countedDoses]?.toString() || '',
        },
        {
          title: 'Adjustment reason',
          detail:
            adjustmentReasonData?.adjustmentReasons.find(
              ({ value }) =>
                value === formValues[FormInputKeys.adjustmentReason]
            )?.text || '',
        },
        {
          title: 'Additional notes',
          detail: formValues[FormInputKeys.additionalNotes],
        },
      ],
    };
    try {
      await insertTicket({
        variables: {
          type: 'Inventory_adjustment',
          status: 'open',
          detail: ticketDetail,
        },
      });

      toast({
        title: 'Inventory adjustment request sent',
        type: 'success',
        time: 5000,
      });
    } catch (err) {
      toast({
        title: `Callback error: ${err}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      cleanAndClose();
    }
  };

  const cleanAndClose = () => {
    setOpenDosesEdit(false);
    setFormValues(formInitialValues);
  };

  if (!vaccines.length) {
    return (
      <Message content="There is no inventory for the vaccine you are looking for." />
    );
  }

  const inventoryPanels = vaccines.map((vaccine) => {
    // 12 weeks = 3 months
    const weeksNumberVariable = 12;

    const vaccineTypesKey =
      vaccine.types && joinTypesArrayStrings(vaccine.types);

    return {
      key: vaccine.id,
      title: (
        <Accordion.Title style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Icon name="dropdown" />
              <b style={{ marginRight: '0.2rem' }}>{vaccine.name}</b>
              <Label
                size="small"
                content={vaccine.saleNdc}
                style={{ marginRight: '0.2rem' }}
              />
              {vaccine.types?.map((type) => (
                <Label
                  key={type}
                  size="small"
                  color={vaccine.inventory[0].vfc ? 'orange' : 'teal'}
                >
                  {vaccineTypeNames[type]}
                </Label>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <p
                style={{ fontSize: '12px', marginRight: '1.2rem' }}
              >{`Stock: ${vaccine.stock}`}</p>
              <p style={{ fontSize: '12px' }}>{`Expires: (${moment(
                vaccine.inventory[0].expiration
              ).format(DateFormats.DATE)})`}</p>
            </div>
          </div>
        </Accordion.Title>
      ),
      content: (
        <Accordion.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>LOT</Table.HeaderCell>
                <Table.HeaderCell>STOCK</Table.HeaderCell>
                <Table.HeaderCell>EXPIRATION</Table.HeaderCell>
                <Table.HeaderCell>ADJUST DOSES</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {vaccine.inventory.map((inventory) => {
                return (
                  <Table.Row key={inventory.id}>
                    <Table.Cell>
                      {inventory.lot}
                      {inventory.vfc && (
                        <Label
                          size="small"
                          color="orange"
                          style={{ marginLeft: '5px' }}
                        >
                          VFC
                        </Label>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {inventory.doses < 0 ? (
                        <NegativeDosesWarning />
                      ) : (
                        inventory.doses
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(inventory.expiration).fromNow()}(
                      {moment(inventory.expiration).format(DateFormats.DATE)})
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Popup
                        content="Adjust vaccine doses"
                        size="tiny"
                        trigger={
                          <Icon
                            name="edit"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              handleAdjustDoses(
                                vaccine,
                                inventory.lot,
                                inventory.doses
                              )
                            }
                          />
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {privateInventory && (
            <Segment
              style={{
                padding: '0.5rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {vaccine.inventory[0].threshold ? (
                <div style={{ flex: '1' }}>
                  <Label basic size="small" color="blue">
                    Threshold:
                    <Label.Detail>
                      {vaccine.inventory[0].threshold.noVfc}
                    </Label.Detail>
                  </Label>
                </div>
              ) : (
                <div style={{ flex: '1' }}></div>
              )}
              <div style={{ flex: '1' }}>
                <Label basic size="small" color="blue">
                  Average weekly use:
                  <Label.Detail>
                    {/* Vaccine average weekly use in last 3 months */}

                    {vaccineTypesCount && vaccineTypesKey
                      ? (
                          vaccineTypesCount[vaccineTypesKey] /
                          weeksNumberVariable
                        ).toFixed(2)
                      : 0}
                  </Label.Detail>
                </Label>
              </div>
              {!!vaccine.expiredInventory?.length ? (
                <Popup
                  style={{ overflowY: 'auto', maxHeight: '16rem' }}
                  basic
                  on="click"
                  trigger={
                    <Button
                      style={{ flex: '0.5' }}
                      basic
                      size="mini"
                      content="Show expired"
                    />
                  }
                  content={
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>LOT</Table.HeaderCell>
                          <Table.HeaderCell>STOCK</Table.HeaderCell>
                          <Table.HeaderCell>EXPIRATION</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {vaccine.expiredInventory.map((i) => {
                          return (
                            <Table.Row>
                              <Table.Cell>{i.lot}</Table.Cell>
                              <Table.Cell>{i.doses}</Table.Cell>
                              <Table.Cell>{i.expiration}</Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  }
                />
              ) : (
                <div style={{ flex: '0.7' }}></div>
              )}
            </Segment>
          )}
        </Accordion.Content>
      ),
    };
  });

  return (
    <>
      <Accordion
        defaultActiveIndex={[]}
        fluid
        styled
        exclusive={false}
        panels={inventoryPanels}
      />
      <Modal
        open={openDosesEdit}
        closeIcon
        onClose={cleanAndClose}
        centered
        style={{ width: '50vw' }}
      >
        <Modal.Header>Adjust Vaccine Doses</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Segment
                  style={{ backgroundColor: COLORS_BY_NAME['Bright Gray'] }}
                >
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Grid.Row>
                          <Grid.Column>
                            <Grid.Row style={{ paddingBottom: '.1rem' }}>
                              <label style={{ fontSize: '1rem' }}>
                                <strong>{selectedVaccine?.vaccine.name}</strong>
                              </label>
                            </Grid.Row>
                            <Grid.Row>
                              {selectedVaccine?.vaccine.types?.map((type) => (
                                <Label
                                  key={type}
                                  size="small"
                                  color={
                                    selectedVaccine?.vaccine.inventory[0].vfc
                                      ? 'orange'
                                      : 'teal'
                                  }
                                >
                                  {vaccineTypeNames[type]}
                                </Label>
                              ))}
                            </Grid.Row>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column>
                        <Grid.Row style={{ paddingTop: '.3rem' }}>
                          <p
                            style={{
                              fontSize: '.9rem',
                            }}
                          >
                            <b>Lot</b>
                          </p>
                        </Grid.Row>
                        <Grid.Row>{selectedVaccine?.lot}</Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment vertical textAlign="center">
                  <label>
                    <b> Stock in Canid</b>
                  </label>
                  <p style={{ fontSize: '24px', fontWeight: '600' }}>
                    {selectedVaccine?.doses}
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form id="adjust-doses-form" onSubmit={handleSumbit}>
                  <Form.Input
                    required
                    name={FormInputKeys.countedDoses}
                    value={formValues[FormInputKeys.countedDoses]}
                    label="Counted doses"
                    type="number"
                    onWheel={(e: any) => e.target.blur()}
                    onChange={handleFormChange}
                  />
                  <Form.Select
                    required
                    name={FormInputKeys.adjustmentReason}
                    value={formValues[FormInputKeys.adjustmentReason]}
                    label="Selected a reason for the adjustment"
                    placeholder="Select a reason"
                    options={
                      adjustmentReasonData?.adjustmentReasons as DropdownItemProps[]
                    }
                    onChange={handleFormChange}
                  />
                  <Form.TextArea
                    label="Additional notes"
                    name={FormInputKeys.additionalNotes}
                    value={formValues[FormInputKeys.additionalNotes]}
                    onChange={handleFormChange}
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            type="button"
            onClick={() => setOpenDosesEdit(false)}
          >
            Cancel
          </Button>
          <Button
            primary
            type="submit"
            form="adjust-doses-form"
            disabled={!formValues[FormInputKeys.adjustmentReason]}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const NegativeDosesWarning = () => (
  <Message color="yellow" size="mini">
    <Icon size="small" bordered inverted name="warning" color="yellow" />
    Contact Canid to confirm the correct inventory
  </Message>
);

export default InventoryAccordion;
