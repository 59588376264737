import React, { useMemo } from 'react';
import {
  VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum,
  VaxSyncIntegrationFirstOptionsEnum,
  vaxSyncIntegrationFirstOptionsEnumToText,
  vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText,
  VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum,
  vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText,
} from '@bluefox/constants/notifications';
import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
} from '@bluefox/lib/formatters';
import {
  Notification,
  VaxSyncIntegrationNotificationContent,
} from '@bluefox/models/Notification';

const renderFirstOptionMessage = (
  content: VaxSyncIntegrationNotificationContent
) => {
  switch (content.firstOption) {
    case VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR:
    case VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS:
    case VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID:
      return `the ${
        vaxSyncIntegrationFirstOptionsEnumToText[content.firstOption]
      }`;
    case VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT:
      return content.freeText;
    default:
      return null;
  }
};

const renderInmunizationNotReportedSecondOptionMessage = (
  content: VaxSyncIntegrationNotificationContent
) => {
  switch (
    content.secondOption as VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
  ) {
    case VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_CLOSED:
    case VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_MISSED:
    case VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.GUARDIAN_INFO_MISSING:
      return vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText[
        content.secondOption as VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
      ];
    default:
      return null;
  }
};

const renderInmunizationIncosistentSecondOptionMessage = (
  content: VaxSyncIntegrationNotificationContent
) => {
  switch (
    content.secondOption as VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum
  ) {
    case VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER:
    case VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER:
    case VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE:
      return vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText[
        content.secondOption as VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum
      ];
    default:
      return null;
  }
};

type Props = {
  notification: Notification;
};

const VaxSyncIntegrationNotificationMessage = ({ notification }: Props) => {
  const content = useMemo(
    () => notification.content as VaxSyncIntegrationNotificationContent,
    [notification.content]
  );
  return (
    <>
      <p>
        For the patient{' '}
        <b>
          {content?.fullPatienName}, MRN {content?.mrn ?? '-'}
        </b>
        {', DOS '}
        {content?.givenAt
          ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
              formatDateStringToDateTimeZero(
                content?.givenAt as unknown as string
              )
            )
          : '-'}{' '}
        {', '}
        {renderFirstOptionMessage(content)}
        {(content?.firstOption ===
          VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR ||
          content?.firstOption ===
            VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS) &&
          ` because ${renderInmunizationNotReportedSecondOptionMessage(
            content
          )}`}
        {content?.firstOption ===
          VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID &&
          `. Please ${renderInmunizationIncosistentSecondOptionMessage(
            content
          )}`}
      </p>
      {notification.note && (
        <p>
          <b>Rejected message:</b> {notification.note}
        </p>
      )}
    </>
  );
};

export default VaxSyncIntegrationNotificationMessage;
