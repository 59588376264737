import { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import { useReactToPrint } from 'react-to-print';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import {
  Accordion,
  Button,
  Card,
  Header,
  Icon,
  Input,
  Menu,
  Segment,
  Table,
  Label,
  Grid,
} from 'semantic-ui-react';

import FullLayout from '@components/FullLayout';
import WastedDosesModal from '@components/WastedDosesModal';
import ReportAnIssueModal from '@components/ReportAnIssueModal';

import {
  ReceivedInventoryQuery,
  InventoryListForPdfQuery,
} from '@bluefox/graphql/inventory';
import { PracticeInventoryOrdersQuery } from '@bluefox/graphql/inventoryOrders';
import {
  InventoryOrders,
  OrderStatuses,
} from '@bluefox/models/InventoryOrders';
import { VaccinesStockData } from '@bluefox/models/Inventory';
import { Vaccine } from '@bluefox/models/Vaccine';
import { Inventory } from '@bluefox/models/Inventory';
import LoadingBox from '@bluefox/ui/LoadingBox';
import VaccineTypesPicker from '@bluefox/ui/VaccineTypesPicker';
import { useLSHandler, usePractice } from '@bluefox/contexts';
import { humanizeText } from '@bluefox/lib/humanize';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import { PracticeSettings } from '@bluefox/models/Practice';
import { GetNotAppliedAdjustmentsQuery } from '@bluefox/graphql/inventoryAdjustment';

import InventoryListPdf from './InventoryListPdf';
import InventoryAccordion from './inventoryAccordion';
import RequestVaccinesModal from '@components/RequestVaccinesModal';
import PlacedOrders from './PlacedOrders';
import { formatDateToMMDDYYYY } from '@bluefox/lib/formatters';
import { confirmOrderMutation } from '@bluefox/graphql/notifications';
import InventoryReceptionMissingDataModal, {
  FormValues,
} from './InventoryReceptionMissingDataModal';

type InventoryOrderVaccineType = Pick<
  Vaccine,
  'saleNdc' | 'manufacturer' | 'name' | 'id'
>;

interface InventoryListForPdfData {
  privateInventory: Inventory[];
  vfcInventory: Inventory[];
}

interface PracticeSettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

type InventoryOrderType = Omit<InventoryOrders, 'vaccine' | 'vaccineId'> & {
  vaccine: InventoryOrderVaccineType;
};

interface InventoryOrderDataType {
  inventory_orders: InventoryOrderType[];
}

export const joinTypesArrayStrings = (types: string[]) => types.join('-');

const InventoryScreen = () => {
  const practice = usePractice();
  const lsHandler = useLSHandler();

  const pdfComponentRef = useRef<HTMLDivElement>(null);
  const [isPdf, setIsPdf] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [vaccineTypes, setVaccineTypes] = useState<string[]>([]);
  const [hasPendingAdjustments, setHasPendingAdjustments] = useState(false);
  const [typesFilter, setTypesFilter] = useState({});

  const { data, loading, refetch } = useQuery<VaccinesStockData>(
    ReceivedInventoryQuery,
    {
      variables: {
        likeQuery: `%${searchQuery}%`,
        typesFilter,
      },
    }
  );

  const { data: inventoryListForPdfData, refetch: refetchInventoryPdf } =
    useQuery<InventoryListForPdfData>(InventoryListForPdfQuery, {
      variables: {
        practiceId: practice.id,
      },
    });

  const { data: settingsData } = useQuery<PracticeSettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practice.id,
      },
    }
  );

  const { loading: notAppliedAdjustmentsLoading } = useQuery(
    GetNotAppliedAdjustmentsQuery,
    {
      variables: {
        id: practice.id,
      },
      onCompleted: (notAppliedAdjustments) => {
        const { notAppliedVFCAdjustments, notAppliedPrivateAdjustments } =
          notAppliedAdjustments;

        const hasPrivatePendingAdjustments =
          notAppliedPrivateAdjustments?.length !== 0;
        const hasVFCPendingAdjustments = notAppliedVFCAdjustments?.length !== 0;

        const blockButton =
          hasPrivatePendingAdjustments && hasVFCPendingAdjustments;

        setHasPendingAdjustments(blockButton);
      },
    }
  );

  const handleRefetchInventory = () => {
    refetch();
    refetchInventoryPdf();
  };

  const handlePrint = useReactToPrint({
    content: () => pdfComponentRef.current,
    onAfterPrint: () => {
      setIsPdf(false);
    },
    documentTitle: `${practice.name} - Inventory list`,
  });

  const existAdjustmentProcessStarted =
    !!lsHandler.getItem('adjustment_data_vfc') ||
    !!lsHandler.getItem('adjustment_data_private');

  useEffect(() => {
    setTypesFilter(
      vaccineTypes.length
        ? {
            _has_keys_any: vaccineTypes,
          }
        : {}
    );
  }, [vaccineTypes]);

  const privateVaccines = data?.vaccines
    .map((v) => {
      return {
        ...v,
        inventory: v.inventory.filter((i) => !i.vfc),
        stock: v.inventory
          .filter((i) => !i.vfc)
          .reduce((accum, inventory) => accum + inventory.doses, 0),
      };
    })
    .filter((v) => v.inventory.length > 0);

  const publicVaccines = data?.vaccines
    .map((v) => {
      return {
        ...v,
        inventory: v.inventory.filter((i) => i.vfc),
        stock: v.inventory
          .filter((i) => i.vfc)
          .reduce((accum, inventory) => accum + inventory.doses, 0),
      };
    })
    .filter((v) => v.inventory.length > 0);

  const privateVaccinesTypesMap = privateVaccines?.reduce(
    (acc: any, vaccine) => {
      const type = vaccine.types ? joinTypesArrayStrings(vaccine.types) : null;
      if (!type) return acc;
      const countByType = vaccine.inventory.reduce(
        (acc: number, el) => acc + (el.vaccinationsCount ?? 0),
        0
      );
      if (acc[type]) {
        acc[type] += countByType;
      } else {
        acc[type] = countByType;
      }

      return acc;
    },
    {}
  );

  useEffect(() => {
    if (data) {
      if (!isPdf) return;
      handlePrint();
    }
  }, [data, handlePrint, isPdf]);

  return (
    <FullLayout>
      <Segment padded basic>
        <Header as="h1">
          <Icon name="boxes" />
          Inventory
        </Header>
      </Segment>

      <PendingOrders
        onUpdate={() =>
          setTimeout(() => {
            refetch();
          }, 2000)
        }
      />

      <div style={{ marginTop: '1rem' }}>
        <PlacedOrders />
      </div>

      <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Menu.Item>
          <Header as="h2">All Vaccines</Header>
        </Menu.Item>
        <Menu.Menu
          position="right"
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          <Menu.Item>
            <Input
              value={searchQuery}
              onChange={(_, { value }) => setSearchQuery(value)}
              icon="search"
              placeholder="Search..."
              data-automation-id="inventory-search"
              input={{
                autocomplete: 'off',
                autocorrect: 'off',
              }}
            />
          </Menu.Item>
          <Menu.Item>
            <VaccineTypesPicker
              placeholder="All Vaccine Types"
              multiple
              onChange={(types) => setVaccineTypes(types as string[])}
            />
          </Menu.Item>
          {settingsData?.practice.settings.inventory?.addVaccine && (
            <Menu.Item>
              <RequestVaccinesModal onRefetchInventory={handleRefetchInventory}>
                <Button
                  secondary
                  content="Add Inventory"
                  basic
                  data-automation-id="inventory-request-vaccines"
                />
              </RequestVaccinesModal>
            </Menu.Item>
          )}
          <Menu.Item>
            <WastedDosesModal>
              <Button
                secondary
                content="Wasted Doses Report"
                basic
                data-automation-id="inventory-wasted-doses-button"
              />
            </WastedDosesModal>
          </Menu.Item>
          {settingsData?.practice.settings.features?.remoteInventoryAdjustment
            ?.enabled && (
            <Menu.Item>
              {!hasPendingAdjustments ? (
                <Button
                  loading={notAppliedAdjustmentsLoading}
                  as={Link}
                  secondary={!existAdjustmentProcessStarted}
                  negative={existAdjustmentProcessStarted}
                  to={`/${practice.handler}/inventory/adjustment`}
                  content={
                    !existAdjustmentProcessStarted
                      ? 'Inventory Adjustment'
                      : 'Continue Adjustment'
                  }
                  basic
                />
              ) : (
                <Button
                  loading={notAppliedAdjustmentsLoading}
                  disabled
                  negative
                  content="Adjustment pending approval"
                  basic
                />
              )}
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <Grid
            style={{
              border: '1px solid #cccccc',
              borderRadius: '6px',
              margin: '0.5rem 0 1rem 0',
            }}
          >
            <Grid.Row>
              <Grid.Column width={8}>
                <Card fluid>
                  <Card.Content>
                    <Card.Header>Private Vaccines</Card.Header>
                  </Card.Content>
                  <Card.Content>
                    <InventoryAccordion
                      vaccines={privateVaccines}
                      privateInventory
                      vaccineTypesCount={privateVaccinesTypesMap}
                    />
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column width={8}>
                <Card fluid>
                  <Card.Content>
                    <Card.Header>VFC Vaccines</Card.Header>
                  </Card.Content>
                  <Card.Content>
                    <InventoryAccordion vaccines={publicVaccines} />
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div
            style={{
              width: '100%',
              marginBottom: '3rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={() => setIsPdf(true)}
              floated="right"
              icon="print"
              content="Print / Save as PDF"
            />
          </div>
        </>
      )}
      {isPdf && (
        <div
          ref={pdfComponentRef}
          style={
            isPdf
              ? {
                  margin: '2rem 3rem 2rem 3rem',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }
              : {}
          }
        >
          <Header as="h1" textAlign="center">
            {practice.name}
            <Header.Subheader>Inventory list</Header.Subheader>
          </Header>
          <InventoryListPdf inventoryData={inventoryListForPdfData} />
        </div>
      )}
    </FullLayout>
  );
};

interface PendingOrdersProps {
  onUpdate: () => void;
}
const PendingOrders = ({ onUpdate }: PendingOrdersProps) => {
  const practice = usePractice();
  const [pendingOrders, setPendingOrders] = useState<InventoryOrders[]>();
  const { data } = useQuery<InventoryOrderDataType>(
    PracticeInventoryOrdersQuery(false),
    {
      variables: {
        practiceId: practice.id,
        criteria: {},
      },
      onError: () => {
        setPendingOrders([]);
      },
      onCompleted: (data) => {
        const newPendingOrders = data.inventory_orders?.filter(
          (o) => o.status === OrderStatuses.ORDERED
        );
        setPendingOrders(newPendingOrders as InventoryOrders[]);
      },
    }
  );

  const [show, setShow] = useState(false);
  const [openIssue, setOpenIssue] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<
    FormValues & { order?: InventoryOrders }
  >();

  const handleCopyText = (str: string | undefined) => {
    navigator.clipboard.writeText(str || '');

    toast({
      title: 'Copied!',
      type: 'success',
      time: 1000,
    });
  };

  const handleCloseModalIssue = () => {
    setOpenIssue(false);
  };

  const handleConfirmReceivedButton = useCallback(
    (order: InventoryOrders) => {
      setFormValues({
        lot: order?.lot,
        fillLot: !order?.lot,
        expiration: order?.inventoryExpiration,
        fillExpiration: !order?.inventoryExpiration,
        timeZone: practice.timezone,
        order,
      });
    },
    [practice.timezone]
  );

  const [confirmOrder, { loading }] = useMutation(confirmOrderMutation);

  const handleConfirmReceivedOrder = useCallback(
    async (order: InventoryOrders, lot?: string, expiration?: Date) => {
      try {
        if (onUpdate) onUpdate();
        const response = await confirmOrder({
          variables: {
            orderId: order?.id,
            ...(lot ? { lotNumber: lot } : {}),
            ...(expiration ? { inventoryExpiration: expiration } : {}),
          },
          refetchQueries: [
            {
              query: PracticeInventoryOrdersQuery(false),
              variables: {
                practiceId: practice.id,
                criteria: {},
              },
            },
          ],
        });
        if (response?.data?.confirmOrder?.code !== 200) {
          toast({
            title: `Failed to confirm order or order information. Error: ${response?.data?.confirmOrder?.message}`,
            type: 'error',
            time: 5000,
          });
          return;
        }
      } catch (error) {
        toast({
          title: `Failed to confirm order or order information. Error: ${error}`,
          type: 'error',
          time: 5000,
        });
        return;
      }
    },
    [confirmOrder, onUpdate, practice.id]
  );

  if (!data || data.inventory_orders.length === 0) {
    return null;
  }

  return (
    <Accordion fluid styled>
      <Accordion.Title onClick={() => setShow(!show)} active={show}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Icon name="dropdown" />
            Pending orders{' '}
            {pendingOrders?.length ? `(${pendingOrders?.length})` : null}
          </div>
          <Button
            primary
            size="small"
            icon="file alternate"
            content="Historical Orders"
            as={Link}
            to={`/${practice.handler}/orders`}
            onClick={(e) => {
              //stopPropagation for the "Place Order" button. So when it's clicked, the "Placed orders" accordion desn't open.
              e.stopPropagation();
            }}
          />
        </div>
      </Accordion.Title>
      <Accordion.Content active={show}>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Vaccine</Table.HeaderCell>
              <Table.HeaderCell>Lot</Table.HeaderCell>
              <Table.HeaderCell>Doses</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Tracking Number</Table.HeaderCell>
              <Table.HeaderCell>Est. Shipping Date</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell width={3}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {pendingOrders?.map((order) => {
              const { id, lot, doses, status, vaccine, vfc, trackingNumber } =
                order;
              return (
                <Table.Row key={id}>
                  <Table.Cell>{vaccine?.name}</Table.Cell>
                  <Table.Cell>
                    {lot ? (
                      <>
                        {lot}{' '}
                        <StyledIcon
                          name="copy"
                          color="grey"
                          onClick={() => {
                            handleCopyText(lot);
                          }}
                        />
                      </>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell>{doses}</Table.Cell>
                  <Table.Cell>
                    <Label
                      size="tiny"
                      color={vfc ? 'orange' : 'teal'}
                      content={vfc ? 'VFC' : 'Private'}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {trackingNumber ? (
                      <>
                        <a
                          target="_blank"
                          href={`https://www.google.com/search?q=${trackingNumber}`}
                          rel="noreferrer"
                        >
                          {trackingNumber}
                        </a>
                        <StyledIcon
                          name="copy"
                          color="grey"
                          onClick={() => {
                            handleCopyText(trackingNumber);
                          }}
                        />
                      </>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {order.estimatedShippingDate
                      ? formatDateToMMDDYYYY(
                          order.estimatedShippingDate.toString()
                        )
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                      basic
                      content={humanizeText(status, { capitalize: 'first' })}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      content="Confirm Received"
                      color="green"
                      icon="check"
                      basic
                      size="small"
                      disabled={loading}
                      loading={loading}
                      data-automation-id="inventory-pending-orders-received-button"
                      onClick={() => handleConfirmReceivedButton(order)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Button
          content="Report an Issue"
          color="orange"
          icon="exclamation"
          basic
          size="small"
          onClick={() => setOpenIssue(true)}
        />
        <InventoryReceptionMissingDataModal
          open={!!formValues}
          formValues={formValues}
          setFormValues={setFormValues}
          onClose={() => {
            setFormValues(undefined);
          }}
          onSubmit={async () => {
            await handleConfirmReceivedOrder(
              formValues?.order as InventoryOrders,
              formValues?.lot,
              formValues?.expiration
            );
            setFormValues(undefined);
          }}
        />
      </Accordion.Content>
      <ReportAnIssueModal
        isOpen={openIssue}
        closeModal={handleCloseModalIssue}
      />
    </Accordion>
  );
};

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

export default InventoryScreen;
