import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { usePractice } from '@bluefox/contexts/Practice';
import { toast } from 'react-semantic-toasts';
import {
  Form,
  Segment,
  Modal,
  Icon,
  Button,
  Label,
  Header,
  Container,
} from 'semantic-ui-react';
import { InsertTicketMutation } from '@bluefox/graphql/tickets';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import { VaccinationsPerDay } from '@bluefox/graphql/vaccinations';
import { Vaccination } from '@bluefox/models/Vaccination';
import InsuranceCompanyPicker from '@components/InsuranceCompanyPicker';
import { PracticePatient } from '@bluefox/models/Patient';

interface Patients {
  practicePatient: {
    id: string;
    patientData: {
      fullName: string;
      birthdate: string;
    };
  };
}

interface UniquePatient {
  value: string;
  text: string;
}

interface Option {
  text: string;
  value: string;
}

const ReportPossibleInconsistency = () => {
  const { name, handler } = usePractice();
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [dateOfService, setDateOfService] = useState<Date | undefined>();
  const [patientOptions, setPatientOptions] = useState<UniquePatient[]>([]);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patient, setPatient] = useState<PracticePatient>();
  const [vaccinations, setVaccinations] = useState<Vaccination[]>([]);
  const [issue, setIssue] = useState<string>('');
  const [insuranceCompanyId, setInsuranceCompanyId] = useState<string>('');
  const [selectedVaccinations, setSelectedVaccinations] = useState<string[]>();
  const [selectedVaccinationsOptions, setSelectedVaccinationsOptions] =
    useState<Option[]>([]);

  const issueOptions = [
    {
      value: 'insurance',
      text: 'Insurance Issue',
    },
    {
      value: 'vaccine',
      text: 'Vaccine Issue',
    },
    {
      value: 'insurance_and_vaccine',
      text: 'Insurance and Vaccine Issues',
    },
  ];

  const [getVaccinations] = useLazyQuery(VaccinationsPerDay, {
    onCompleted(data) {
      setVaccinations(data.vaccinations);
      const getUniquePatients = (vaccinations: Patients[]): UniquePatient[] => {
        const patientsMap = new Map<string, UniquePatient>();

        vaccinations.forEach((vaccination) => {
          const { id, patientData } = vaccination.practicePatient;
          const { fullName, birthdate } = patientData;

          if (!patientsMap.has(id)) {
            patientsMap.set(id, {
              value: id,
              text: `${fullName} (${birthdate})`,
            });
          }
        });

        return Array.from(patientsMap.values());
      };

      // Assuming the vaccinations data is available in the component's scope
      const uniquePatientsList = getUniquePatients(data.vaccinations);
      setPatientOptions(uniquePatientsList);
    },
    nextFetchPolicy: 'network-only',
  });

  const [insertTicket] = useMutation(InsertTicketMutation);

  const handleSubmit = () => {
    const insurance = patient?.insurances[0]?.company?.name;
    const ticketDetail = {
      fields: [
        { title: 'Practice', detail: name },
        { title: 'Handler', detail: handler },
        {
          title: 'Patient',
          detail: `${patient?.patientData.fullName} (${patient?.patientData.birthdate})`,
        },
        {
          title: 'Insurance',
          detail: insurance
            ? insurance
            : 'This Patient does not have an insurance.',
        },
        { title: 'Date of Service', detail: dateOfService },
        { title: 'Issue', detail: issue },
        { title: 'Corrected Insurance', detail: insuranceCompanyId },
        { title: 'Relevant Vaccines', detail: selectedVaccinations },

        { title: 'Issue', detail: description },
      ],
    };
    insertTicket({
      variables: {
        type: 'Borrowing_request',
        status: 'open',
        detail: ticketDetail,
      },
    })
      .then(() => {
        toast({
          title: 'The request has been submitted',
          type: 'success',
          time: 5000,
        });
        setOpen(false);
        onClose();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const onClose = () => {
    setPatientOptions([]);
    setSelectedPatient('');
    setDateOfService(undefined);
    setDescription('');
    setPatient(undefined);
    setOpen(false);
    setPatientOptions([]);
    setSelectedPatient('');
    setPatient(undefined);
    setVaccinations([]);
    setIssue('');
    setInsuranceCompanyId('');
    setSelectedVaccinations([]);
    setSelectedVaccinationsOptions([]);
  };

  const handleSelectedVaccinations = (value: [string]) => {
    const selectedVaccinationsValue = !value?.length ? [] : value;
    setSelectedVaccinations(selectedVaccinationsValue);
  };

  useEffect(() => {
    if (!dateOfService) return;
    const from = new Date(dateOfService.getTime());
    from.setUTCHours(0, 0, 0, 0);
    const to = new Date(dateOfService.getTime());
    to.setUTCHours(23, 59, 59, 999);

    getVaccinations({
      variables: {
        criteria: {
          _and: [
            {
              givenAt: {
                _gte: from,
                _lte: to,
              },
            },
          ],
        },
      },
    });
  }, [dateOfService]);

  useEffect(() => {
    if (!selectedPatient) {
      setPatient(undefined);
      return;
    }

    const patient = vaccinations.find(
      (patient) => patient.practicePatient?.id === selectedPatient
    )?.practicePatient;
    if (patient) {
      setPatient(patient);
      const vaccinationOptions = vaccinations
        .filter((vaccination) => {
          return (
            vaccination.practicePatient?.id === patient.id &&
            !!vaccination.vaccine?.name
          );
        })
        .map((vax) => {
          return {
            value: `${vax.barcode}`,
            text: `${vax.vaccine?.name} [${vax.vaccine?.saleNdc}] (${vax
              .inventory?.lot}) - ${vax.inventory?.vfc ? 'VFC' : 'Private'}`,
          };
        });
      vaccinationOptions.length > 0 &&
        setSelectedVaccinationsOptions(vaccinationOptions);
    }
  }, [selectedPatient, patient]);

  return (
    <Modal
      dimmer="blurring"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button
          primary
          content="Add borrowing case"
          onClick={() => setOpen(true)}
        />
      }
    >
      <Modal.Header>
        <Icon name="bullhorn" />
        Add an inconsistency
      </Modal.Header>
      <Modal.Content>
        Please use this form to request adding this case as an inconsistency.
        <Segment padded>
          <Form
            id="borrowing-new-inconsistency-request-form"
            onSubmit={handleSubmit}
          >
            <Form.Field>
              <label>Date of Service</label>
              <DateTimePicker
                placeholderText="Date of Service"
                selected={dateOfService}
                onChange={(d) => {
                  setDateOfService(d ? (d as Date) : undefined);
                  setSelectedPatient('');
                }}
                onSelect={(value) => {
                  setDateOfService(value ? (value as Date) : undefined);
                  setSelectedPatient('');
                }}
                onClear={() => {
                  setDateOfService(undefined);
                  setSelectedPatient('');
                }}
                maxDate={new Date()}
                dateFormat={DateFormats.DATE}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
                isClearable
              />
            </Form.Field>
            <Form.Dropdown
              fluid
              labeled
              label={'Patient (DOB)'}
              selection
              search
              placeholder="Select patient"
              value={selectedPatient}
              options={patientOptions}
              onChange={(_, data) => {
                setSelectedPatient(data.value?.toString() || '');
              }}
            />
            {patient && (
              <>
                <Container>
                  <Header as={'h5'}>Canid's Patient Info:</Header>
                  <Form.Input
                    fluid
                    label="Insurance"
                    value={
                      patient?.insurances[0]?.company?.name ||
                      'No insurance found'
                    }
                  />
                  {patient?.insurances[0]?.company?.name && (
                    <>
                      <Form.Input
                        fluid
                        label="Member ID"
                        value={patient.insurances[0].memberId}
                      />
                      <Form.Input
                        fluid
                        label="Eligibility"
                        value={
                          patient.insurances[0].vfcEligible
                            ? `VFC Eligible (${patient.insurances[0].vfcCriteria})`
                            : 'Private'
                        }
                      />
                    </>
                  )}

                  <Header as={'h5'}>
                    Below are all the vaccines applied on the selected day for
                    the selected patient:
                  </Header>
                  <div
                    style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}
                  >
                    {vaccinations
                      .filter((vaccination) => {
                        return vaccination.practicePatient?.id === patient.id;
                      })
                      .map((vax) => {
                        if (!vax.vaccine?.name) return;
                        return (
                          <div
                            style={{
                              padding: '5px',
                              border: '1px solid',
                              borderRadius: '0.5rem',
                            }}
                          >
                            <Header>
                              {vax.vaccine?.name}
                              <Label
                                content={vax.inventory?.vfc ? 'VFC' : 'Private'}
                                size="tiny"
                                color={vax.inventory?.vfc ? 'orange' : 'teal'}
                              />
                              <Header.Subheader>
                                {vax.vaccine?.saleNdc} <br />
                                {vax.inventory?.lot}
                                <br />
                                {vax.vaccine?.types?.map((t) => (
                                  <Label key={t} color="black" size="mini">
                                    {t}
                                  </Label>
                                ))}
                              </Header.Subheader>
                            </Header>
                          </div>
                        );
                      })}
                  </div>
                </Container>
                <br />
                <Header as={'h4'}>
                  Please, complete the following fields to review this case.
                </Header>
                <Form.Dropdown
                  fluid
                  labeled
                  label={'Select the issue subject'}
                  selection
                  placeholder="Select the issue subject"
                  value={issue}
                  options={issueOptions}
                  onChange={(_, { value }) => setIssue(value as string)}
                />
                {(issue === 'insurance' ||
                  issue === 'insurance_and_vaccine') && (
                  <>
                    <label>Select the correct Insurance Company</label>
                    <InsuranceCompanyPicker
                      value={insuranceCompanyId}
                      onChange={(value) => {
                        setInsuranceCompanyId(value as string);
                      }}
                      data-automation-id="insurances-form-company"
                    />
                    <br />
                  </>
                )}
                {(issue === 'vaccine' || issue === 'insurance_and_vaccine') && (
                  <>
                    <Form.Dropdown
                      placeholder="Select the relevant vaccines"
                      labeled
                      label={'Select the relevant vaccines to review'}
                      search
                      fluid
                      multiple
                      selection
                      onChange={(e, data) => {
                        handleSelectedVaccinations(data.value as [string]);
                      }}
                      options={selectedVaccinationsOptions}
                    />
                  </>
                )}
                <Form.TextArea
                  onChange={(e) => setDescription(e.target?.value.toString())}
                  required
                  label="Please describe what is wrong with this case."
                  placeholder="Description"
                />
              </>
            )}
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          type="submit"
          form="borrowing-new-inconsistency-request-form"
        >
          Submit
        </Button>

        <Button secondary type="button" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ReportPossibleInconsistency;
