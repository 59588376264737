import { useCallback, useEffect, useReducer, useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';

import GS1 from '@bluefox/lib/gs1';

import { Inventory } from '@bluefox/models/Inventory';
import { usePractice } from '@bluefox/contexts';
import { Vaccine } from '@bluefox/models/Vaccine';
import {
  VaccinationRoutes,
  VaccinationSites,
} from '@bluefox/models/Vaccination';

const ScannableInventoryQuery = gql`
  query ScannableInventoryQuery(
    $practiceId: uuid!
    $criterias: [inventories_bool_exp!]!
  ) {
    inventories(
      where: { practiceId: { _eq: $practiceId }, _or: $criterias }
      order_by: { vaccine: { name: asc } }
    ) {
      id
      lot
      expiration
      doses
      vaccineId
      status
      vfc
      vaccine {
        id
        name
        saleNdc
        manufacturer
        aka
        routes
      }
    }
  }
`;

const ScannableVaccineQuery = gql`
  query ScannableVaccineQuery($criterias: [vaccines_bool_exp!]!) {
    vaccines(where: { _or: $criterias }, order_by: { name: asc }) {
      id
      name
      saleNdc
      manufacturer
      aka
      routes
      types
    }
  }
`;

interface ScannableInventoryData {
  inventories: Inventory[];
}

interface ScannableVaccineData {
  vaccines: Vaccine[];
}

class Criterias {
  gs1: GS1;
  lot?: string;
  expiration?: Date;
  ndc10?: string;
  useNdc10?: string;
  code: string;

  constructor(code: string) {
    this.code = code;
    this.gs1 = new GS1(code);

    this.lot = this.gs1.getLot();
    this.expiration = this.gs1.getExp();
    this.ndc10 = this.gs1.getNdc();
  }

  getInventoryExpresion() {
    if (!this.lot || !this.expiration) return;
    const vaccineExpresion = this.getVaccineExpresion();
    return {
      _or: [
        {
          lot: { _ilike: this.lot },
        },
        { alternativeLotNumber: { _contains: [this.lot] } },
      ],

      ...(vaccineExpresion ? { vaccine: vaccineExpresion } : {}),
      status: { _eq: 'received' },
    };
  }

  getVaccineExpresion() {
    if (!this.ndc10) return;
    return {
      _or: [
        { saleNdc10: { _eq: this.ndc10 } },
        { useNdc10: { _eq: this.ndc10 } },
      ],
    };
  }
}

export interface ScannableItem {
  code: string;
  gs1?: GS1;
  vaccine?: Vaccine;
  inventory?: Inventory[];
  vaccinationSite?: VaccinationSites;
  vaccinationSiteDescription?: string;
  vaccinationRoute?: VaccinationRoutes;
  vaccinationDose?: number;
  vaccinationVisDate?: Date;
  warnings?: string[];
}

enum EntriesReducerActionType {
  CREATE_ITEM,
  REMOVE_ITEM,
  ASSIGN_ITEM_ATTRIBUTES,
  CLEAN_ITEMS,
}

interface EntriesReducerPayload {
  code: string;
  item: Partial<ScannableItem>;
  inventoryId: string;
}

interface EntriesReducerAction {
  type: EntriesReducerActionType;
  payload?: Partial<EntriesReducerPayload>;
}

function isScannableItem(i: any): i is ScannableItem {
  return 'code' in i;
}

const entriesReducer = (
  state: Record<string, ScannableItem>,
  action: EntriesReducerAction
) => {
  switch (action.type) {
    case EntriesReducerActionType.CREATE_ITEM:
      if (!action.payload?.code || !isScannableItem(action.payload.item))
        return state;
      return {
        ...state,
        [action.payload.code]: action.payload.item,
      };

    case EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES:
      if (!action.payload?.code) return state;
      return {
        ...state,
        [action.payload.code]: {
          ...state[action.payload.code],
          ...action.payload.item,
        },
      };

    case EntriesReducerActionType.REMOVE_ITEM:
      if (!action.payload?.code) return state;
      const filteredInventory = action.payload.inventoryId
        ? state[action.payload.code].inventory?.filter(
            (i) => i.id !== action.payload?.inventoryId
          )
        : undefined;
      if (!filteredInventory) {
        delete state[action.payload.code];
      } else {
        state[action.payload.code].inventory = filteredInventory;
      }

      return { ...state };

    case EntriesReducerActionType.CLEAN_ITEMS:
      return {};
  }
};

interface useScannableItemsOptions {
  vfc?: boolean;
  defaultVaccinationSite?: VaccinationSites;
}

export const useScannableItems = ({
  vfc,
  defaultVaccinationSite,
}: useScannableItemsOptions = {}) => {
  const practice = usePractice();
  const client = useApolloClient();

  const [entriesRecord, dispatch] = useReducer(entriesReducer, {});
  const [entries, setEntries] = useState<ScannableItem[]>([]);

  const [loadingInventory, setLoadingInventory] = useState(false);
  const [loadingVaccine, setLoadingVaccine] = useState(false);

  const addEntry = useCallback(
    (code: string) => {
      if (!code) return;

      const item: ScannableItem = {
        code,
        gs1: new GS1(code),
        vaccinationSite: defaultVaccinationSite,
      };

      dispatch({
        type: EntriesReducerActionType.CREATE_ITEM,
        payload: {
          code,
          item,
        },
      });

      const criterias = new Criterias(code);

      const inventoryExpresion = criterias.getInventoryExpresion();

      if (inventoryExpresion) {
        setLoadingInventory(true);
        client
          .query<ScannableInventoryData>({
            query: ScannableInventoryQuery,
            variables: {
              practiceId: practice.id,
              criterias: inventoryExpresion,
            },
          })
          .then(({ data }) => {
            if (!data || !data.inventories.length) return;

            dispatch({
              type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
              payload: {
                code,
                item: {
                  inventory: data.inventories,
                },
              },
            });
          })
          .finally(() => setLoadingInventory(false));
      }

      const vaccineExpresion = criterias.getVaccineExpresion();
      if (vaccineExpresion) {
        setLoadingVaccine(true);
        client
          .query<ScannableVaccineData>({
            query: ScannableVaccineQuery,
            variables: {
              criterias: vaccineExpresion,
            },
          })
          .then(({ data }) => {
            if (!data || !data.vaccines.length) return;
            const vaccine = data.vaccines.at(0);

            dispatch({
              type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
              payload: {
                code,
                item: {
                  vaccine: vaccine,
                  vaccinationRoute: vaccine?.routes?.at(0),
                },
              },
            });
          })
          .finally(() => setLoadingVaccine(false));
      }
    },
    [entriesRecord]
  );

  const removeEntry = (code: string, inventoryId?: string) => {
    dispatch({
      type: EntriesReducerActionType.REMOVE_ITEM,
      payload: { code, inventoryId },
    });
  };

  const cleanEntries = () => {
    setEntries([]);
    dispatch({
      type: EntriesReducerActionType.CLEAN_ITEMS,
    });
  };

  const changeVaccinationSite = useCallback(
    (code: string, site: VaccinationSites) => {
      dispatch({
        type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
        payload: {
          code,
          item: {
            vaccinationSite: site,
          },
        },
      });
    },
    [dispatch]
  );

  const changeVaccinationRoute = useCallback(
    (code: string, route: VaccinationRoutes) => {
      dispatch({
        type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
        payload: {
          code,
          item: {
            vaccinationRoute: route,
          },
        },
      });
    },
    [dispatch]
  );

  const changeVaccinationVisDate = useCallback(
    (code: string, visDate: Date | null) => {
      dispatch({
        type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
        payload: {
          code,
          item: {
            vaccinationVisDate: visDate ? visDate : undefined,
          },
        },
      });
    },
    [dispatch]
  );

  const changeDose = useCallback(
    (code: string, vaccinationDose: number) => {
      dispatch({
        type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
        payload: {
          code,
          item: {
            vaccinationDose,
          },
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const _entries = Object.values(entriesRecord);
    setEntries(_entries as ScannableItem[]);
  }, [entriesRecord]);

  const addWarning = (code: string, warnings: string[] | undefined) => {
    dispatch({
      type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
      payload: {
        code,
        item: {
          warnings,
        },
      },
    });
  };

  return {
    entries,
    loading: loadingInventory || loadingVaccine,
    // methods
    addEntry,
    removeEntry,
    cleanEntries,
    changeVaccinationSite,
    changeVaccinationRoute,
    changeDose,
    changeVaccinationVisDate,
    addWarning,
  };
};
