import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { UsersQuery } from '@bluefox/graphql/users';
import { UsersData, User, UserTitles } from '@bluefox/models/Users';
import {
  Input,
  Table,
  Menu,
  Message,
  Placeholder,
  Segment,
  Modal,
  Icon,
  Dropdown,
  Label,
} from 'semantic-ui-react';
import UserForm from '@bluefox/ui/UserForm';

const PracticeAccounts = () => {
  const [practiceAccountId, setPracticeAccountId] = useState<string>('');

  const [searchIsSuspended, setSearchIsSuspended] = useState<string>('active');

  const [userData, setUserdata] = useState<User>();

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [criteria, setCriteria] = useState<object>({});
  const [typing, setTyping] = useState(false);

  const closeModal = () => {
    setPracticeAccountId('');
    setUserdata({
      account: {
        id: '',
        firstName: '',
        lastName: '',
        npi: '',
        email: '',
        password: '',
        taxonomyCode: '',
      },
      id: '',
      title: UserTitles.Other,
      pcp: false,
      role: '',
      suspended: false,
    });

    setOpen(false);
  };

  useEffect(() => {
    let _criteria = {};
    if (searchQuery) {
      _criteria = {
        _or: [
          { account: { firstName: { _ilike: `%${searchQuery}%` } } },
          { account: { lastName: { _ilike: `%${searchQuery}%` } } },
        ],
      };
    }

    if (searchIsSuspended === 'suspended') {
      _criteria = {
        ..._criteria,
        suspended: { _eq: true },
      };
    } else if (searchIsSuspended === 'active') {
      _criteria = {
        _and: [
          {
            _or: [
              { account: { firstName: { _ilike: `%${searchQuery}%` } } },
              { account: { lastName: { _ilike: `%${searchQuery}%` } } },
            ],
          },
          {
            _or: [
              {
                suspended: { _eq: false },
              },
              {
                suspended: { _is_null: true },
              },
            ],
          },
        ],
      };
    }

    setCriteria(_criteria);
  }, [searchQuery, searchIsSuspended]);

  const { data, loading, error, refetch } = useQuery<UsersData>(UsersQuery, {
    variables: {
      criteria,
    },
    skip: !!searchQuery && typing,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!!searchQuery && searchQuery.length < 3) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchQuery]);

  return (
    <>
      <Menu>
        <Menu.Item
          name="addMember"
          icon="user plus"
          onClick={() => setOpen(true)}
          data-automation-id="providers-add-button"
        />
        <Menu.Menu position="right">
          <Menu.Item>
            <Input
              value={searchQuery}
              onChange={(_, { value }) => {
                setSearchQuery(value);
              }}
              icon="search"
              placeholder="Search..."
              loading={false}
              data-automation-id="providers-search"
              input={{
                autocomplete: 'off',
                autocorrect: 'off',
              }}
            />
          </Menu.Item>
          <Menu.Item>
            <Dropdown
              style={{ minWidth: '9rem' }}
              placeholder="Filter by status"
              fluid
              selection
              onChange={(e, data) => {
                const dataValue = data.value ?? 'active';
                setSearchIsSuspended(dataValue.toString());
              }}
              options={[
                { text: 'Active', value: 'active' },
                { text: 'Suspended', value: 'suspended' },
                { text: 'All', value: 'all' },
              ]}
              defaultValue="active"
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {error ? (
        <Message error>{error.message}</Message>
      ) : (
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Fisrt Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell>PCP</Table.HeaderCell>
              <Table.HeaderCell>NPI</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Edit</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading ? (
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <Segment basic>
                    <Placeholder fluid>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            ) : !!data?.practice_accounts.length ? (
              data?.practice_accounts.map(
                ({ account, id, role, title, pcp, suspended, profile }) => (
                  <Table.Row key={id}>
                    <Table.Cell>{account.firstName}</Table.Cell>
                    <Table.Cell>{account.lastName}</Table.Cell>
                    <Table.Cell>{title}</Table.Cell>
                    <Table.Cell>
                      <Label color="teal">
                        {role === 'practiceManager'
                          ? 'Practice Manager'
                          : role === 'biller'
                          ? 'Biller'
                          : 'User'}
                      </Label>
                    </Table.Cell>
                    <Table.Cell>
                      {pcp === true ? (
                        <Icon name="check" size="large" color="teal" />
                      ) : null}
                    </Table.Cell>
                    <Table.Cell>{account.npi}</Table.Cell>
                    <Table.Cell>
                      {suspended === true ? (
                        <Label>Suspended</Label>
                      ) : (
                        <Label color="teal">Active</Label>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Icon
                        name="edit"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true);
                          setPracticeAccountId(id);
                          setUserdata({
                            account: {
                              id: account.id ?? '',
                              firstName: account.firstName ?? '',
                              lastName: account.lastName ?? '',
                              npi: account.npi ?? '',
                              email: account.email ?? '',
                              taxonomyCode: account.taxonomyCode ?? '',
                            },
                            id: id ?? '',
                            title: (title as UserTitles) ?? UserTitles.Other,
                            pcp: pcp ?? false,
                            role: role ?? '',
                            suspended: suspended ?? false,
                            profile: profile ?? {},
                          });
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              )
            ) : (
              <Table.Row>
                <Table.Cell colSpan="8">
                  <Message>No Members found.</Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )}
      <Modal
        onClose={() => closeModal()}
        onOpen={() => setOpen(open)}
        open={open}
      >
        {!practiceAccountId ? (
          <Modal.Header>Add Member</Modal.Header>
        ) : (
          <Modal.Header>Edit Member</Modal.Header>
        )}
        <Modal.Content>
          <UserForm userData={userData} close={closeModal} onSave={refetch} />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default PracticeAccounts;
