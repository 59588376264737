export const backgroundColor = {
  redWarning: '#FFE3E3',
  yellowInfo: '#FFF3BF',
};

export const borderColor = {
  basicGrey: '#D9E0E8',
  redWarning: '#FF8787',
  yellowInfo: '#FCC419',
};

export const boxShadowColor = {
  redWarning: 'rgba(201, 42, 42, 0.20)',
  yellowInfo: 'rgba(230, 119, 0, 0.20)',
};
