import { DbData, Enum } from './Generics';
export enum VFCInconsistencyStatuses {
  CREATED = 'created',
  PENDING = 'pending',
  SOLVED = 'solved',
  REDUNDANT = 'redundant',
}
export enum VFCInconsistencyTypes {
  BORROW = 'borrow',
  BAD_VFC = 'bad_vfc',
  UNKNOWN = 'unknown',
}

export interface VFCInconsistencyStatus extends Enum {}
export interface VFCInconsistencyType extends Enum {}

interface claimUpdate {
  claimId: string;
  id: string;
  insuranceCompanyId: string;
  insuranceName: string;
  memberId: string;
  payerId: string;
  status: string;
  vfcCriteria: string;
  vfcEligible: boolean;
}

export interface VFCInconsistency extends DbData {
  inventory?: {
    id?: string;
    vfc: false;
    lot: string;
    vaccine: {
      name: string;
      aka: string;
      types?: string[];
      saleNdc: string;
    };
  };
  practice?: { name: string };
  claimId: string;
  inventoryId?: string;
  vaccinationId: string;
  practiceId: string;
  claimUpdatesId: string;
  claimUpdate?: {
    id: string;
    metadata: {
      cpid: number;
      payerID: number;
      memberId: number;
      vfcEligible: boolean;
      insuranceName: string;
      insuranceCompanyId: string;
    };
  };
  type: VFCInconsistencyTypes;
  status: VFCInconsistencyStatuses;
  claim?: {
    id: string;
    givenAt: Date;
    insuranceVfcEligible: boolean;
    insuranceCompanyName: string;
    practicePatient: {
      patientData: {
        birthdate: Date;
        firstName: string;
        lastName: string;
      };
    };
    updates: claimUpdate[];
  };
  notes?: string;
  //FOR USE ON FRONT
  changed?: boolean;
  initialStatus?: VFCInconsistencyStatuses;
  initialType?: VFCInconsistencyTypes | null;
  redundantWithId?: string;
  redundantWith?: VFCInconsistency;
  readyToBeSwapped?: boolean;
}

export interface GetVFCInconsistenciesStatusesAndTypesData {
  types: Enum[];
  statuses: Enum[];
}
