import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSession } from '@bluefox/contexts/ApplicationState';
import { useAuth } from '@bluefox/contexts/Auth';
import { usePractice } from '@bluefox/contexts/Practice';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, Header, Icon, Image, Label, Menu } from 'semantic-ui-react';
import { ADMIN_ROLE, BILLER_ROLE, USER_ROLE } from './../generalSettings';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import SearchPatient from './SearchPatient';
import ChangePasswordModal from '@bluefox/ui/ChangePasswordModal';
import BugReportModal from '@components/BugReportModal';
import RequestFeatureModal from '@components/RequestFeatureModal';
import UsersToLoginModal from '@bluefox/ui/UsersToLoginModal';
import { PracticeSettings } from '@bluefox/models/Practice';
import {
  PendingNotificationsCountDefaultPollInterval,
  PendingNotificationsCountQuery,
  PendingNotificationsCountResponse,
} from '@bluefox/graphql/notifications';
import { notifyPendingNotificationCountToExtension } from '@messaging/extension';
import { NotificationType } from '@bluefox/models/Notification';
import { PendingNotificationsCountQueryDefaultWhere } from '@bluefox/constants/notifications';

interface SidebarComponentProps {
  version?: 'mobile' | 'tablet' | 'computer';
}

interface SettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

const SidebarComponent = ({ version = 'computer' }: SidebarComponentProps) => {
  const history = useHistory();
  const practice = usePractice();
  const session = useSession();
  const auth = useAuth();

  const [openUsersToLoginModal, setOpenUsersToLoginModal] = useState(false);

  const isMobile = version === 'mobile';
  const isComputer = version === 'computer';

  const { data: practiceSettings } = useQuery<SettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practice.id,
      },
    }
  );

  const handleNotifyPendingNotificationCountToExtension = useCallback(
    async (data: PendingNotificationsCountResponse) => {
      await notifyPendingNotificationCountToExtension(
        data.pendingNotifications.aggregate.count
      );
    },
    []
  );

  const { data: notificationsCount } =
    useQuery<PendingNotificationsCountResponse>(
      PendingNotificationsCountQuery,
      {
        variables: {
          where: {
            ...PendingNotificationsCountQueryDefaultWhere,
            ...(session?.account?.role === BILLER_ROLE
              ? {
                  type: { _eq: NotificationType.claim },
                }
              : {}),
          },
        },
        pollInterval: PendingNotificationsCountDefaultPollInterval,
        onCompleted: handleNotifyPendingNotificationCountToExtension,
        skip:
          !!!practiceSettings ||
          !!!practiceSettings.practice.settings.notifications?.enabled,
      }
    );

  const closeUsersToLoginModal = () => {
    setOpenUsersToLoginModal(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        height: '100vh',
        width: 'inherit',
        zIndex: '10',
      }}
    >
      <Menu
        style={{ flex: 1 }}
        icon={['mobile', 'tablet'].includes(version) ? 'labeled' : undefined}
        attached
        inverted
        vertical
        borderless
      >
        <Menu.Item>
          <Image src="/canid-header-logo-white.svg" fluid />
        </Menu.Item>
        {[ADMIN_ROLE, USER_ROLE].includes(session?.account?.role ?? '') && (
          <>
            <Menu.Item disabled />
            {!isMobile && (
              <Menu.Item header>
                <Header as="h4" inverted>
                  Patients
                </Header>
              </Menu.Item>
            )}
            <Menu.Item>{isComputer && <SearchPatient />}</Menu.Item>
            <Menu.Item
              data-automation-id="sidebar-menu-patients"
              as={Link}
              to={`/${practice.handler}/patients`}
            >
              <Icon name="users" />
              {!isMobile && 'All Patients'}
            </Menu.Item>
            <Menu.Item
              as={Link}
              to={`/${practice.handler}/patients/_new`}
              data-automation-id="sidebar-menu-new-patient"
              name={!isMobile ? 'AddNewPatient' : ''}
              icon="user plus"
            />
            <Menu.Item
              data-automation-id="sidebar-menu-appointments"
              as={Link}
              to={`/${practice.handler}/appointments`}
            >
              <Icon name="calendar" />
              {!isMobile && 'Appointments'}
            </Menu.Item>

            <Menu.Item
              data-automation-id="sidebar-menu-vaccinations"
              as={Link}
              to={`/${practice.handler}/vaccinations`}
            >
              <Icon name="syringe" />
              {!isMobile && 'Vaccination List'}
            </Menu.Item>

            <Menu.Item disabled />
            {!isMobile && (
              <Menu.Item header>
                <Header as="h4" inverted>
                  Administration
                </Header>
              </Menu.Item>
            )}
            <Menu.Item
              data-automation-id="sidebar-menu-inventory"
              as={Link}
              to={`/${practice.handler}/inventory`}
            >
              <Icon name="boxes" />
              {!isMobile && 'Inventory'}
            </Menu.Item>

            {!!practiceSettings?.practice.settings
              .borrowingOnPracticePortal && (
              <Menu.Item
                data-automation-id="sidebar-menu-borrowing"
                as={Link}
                to={`/${practice.handler}/borrowing`}
              >
                <Icon name="exchange" />
                {!isMobile && 'Borrowing'}
              </Menu.Item>
            )}
            {/* <Menu.Item
              data-automation-id="sidebar-menu-analytics"
              as={Link}
              to={`/${practice.handler}/analytics`}
            >
              <Icon name="chart bar" />
              {!isMobile && "Analytics"}
            </Menu.Item>
            {/* {[ADMIN_ROLE, BILLER_ROLE].includes(
              session?.account?.role ?? ""
            ) && (
              <Dropdown.Item
                data-automation-id="sidebar-menu-billing"
                icon="money bill alternate outline"
                text={!isMobile ? "Billing" : undefined}
                as={Link}
                to={`/${practice.handler}/claims`}
              />
            )} */}
          </>
        )}
        {[ADMIN_ROLE].includes(session?.account?.role ?? '') && (
          <Menu.Item
            data-automation-id="sidebar-menu-billing"
            as={Link}
            to={`/${practice.handler}/billing`}
          >
            <Icon name="dollar" />
            {!isMobile && 'Billing'}
          </Menu.Item>
        )}

        {[BILLER_ROLE].includes(session?.account?.role ?? '') && (
          <>
            <Menu.Item
              data-automation-id="sidebar-menu-billing"
              as={Link}
              to={`/${practice.handler}/billing`}
            >
              <Icon name="dollar" />
              {!isMobile && 'Billing'}
            </Menu.Item>
            {practiceSettings?.practice.settings.billing
              ?.showVaxListToBiller && (
              <Menu.Item
                data-automation-id="sidebar-menu-vaccinations"
                as={Link}
                to={`/${practice.handler}/vaccinations`}
              >
                <Icon name="syringe" />
                {!isMobile && 'Vaccination List'}
              </Menu.Item>
            )}
          </>
        )}
        {[ADMIN_ROLE, USER_ROLE, BILLER_ROLE].includes(
          session?.account?.role ?? ''
        ) &&
          practiceSettings?.practice.settings.notifications?.enabled && (
            <Menu.Item
              data-automation-id="sidebar-menu-billing"
              as={Link}
              to={`/${practice.handler}/notifications`}
            >
              <Icon name="bell">
                {notificationsCount &&
                  notificationsCount?.pendingNotifications?.aggregate.count >
                    0 && (
                    <Label
                      circular
                      color="red"
                      size="mini"
                      empty
                      style={{
                        position: 'absolute',
                        margin: '-0.7rem -0.5rem 0 0',
                      }}
                    />
                  )}
              </Icon>
              {!isMobile && 'Notifications'}
            </Menu.Item>
          )}
      </Menu>
      <Menu
        attached
        inverted
        vertical
        borderless
        icon={['mobile', 'tablet'].includes(version) ? 'labeled' : undefined}
      >
        {session?.account?.role === ADMIN_ROLE && (
          <Dropdown.Item
            data-automation-id="sidebar-menu-settings"
            icon="settings"
            text={!isMobile ? 'Settings' : undefined}
            as={Link}
            to={`/${practice.handler}/settings`}
          />
        )}
        <Dropdown
          item
          text={!isMobile ? 'Support' : undefined}
          icon={isMobile ? 'help' : undefined}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              data-automation-id="sidebar-menu-help"
              icon="help circle"
              text="Help Center"
              as={'a'}
              href="https://canidvaccines.notion.site/FAQs-f63687595c8544a3922332dda0a3282f"
              target="_blank"
            />

            {session?.account?.role !== BILLER_ROLE && (
              <>
                <RequestFeatureModal>
                  <Dropdown.Item
                    data-automation-id="sidebar-menu-request-feature"
                    icon="bullhorn"
                    text="Request a Feature"
                  />
                </RequestFeatureModal>

                <BugReportModal>
                  <Dropdown.Item
                    data-automation-id="sidebar-menu-report"
                    icon="bug"
                    text="Report a Problem"
                  />
                </BugReportModal>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          item
          text={
            !isMobile
              ? `${session?.account?.firstName} ${session?.account?.lastName}`
              : undefined
          }
          icon={isMobile ? 'user' : undefined}
        >
          <Dropdown.Menu>
            <ChangePasswordModal>
              <Dropdown.Item
                data-automation-id="sidebar-menu-change-password"
                icon="edit"
                text="Change password"
              />
            </ChangePasswordModal>
            <Dropdown.Item
              data-automation-id="sidebar-menu-sign-out"
              icon="sign-out"
              text="Sign Out"
              onClick={() => {
                //localStorage.clear();
                auth.actions.signOut(() =>
                  history.push(`/${practice.handler}`)
                );
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item
          data-automation-id="sidebar-menu-change-user"
          onClick={() => setOpenUsersToLoginModal(true)}
        >
          <Icon name="user" />
          {!isMobile && 'Change User'}
        </Menu.Item>
        <UsersToLoginModal
          open={openUsersToLoginModal}
          onClose={closeUsersToLoginModal}
          closeIcon={true}
        />
      </Menu>
    </div>
  );
};

export default SidebarComponent;
