import { useEffect, useState } from 'react';
import { Button, Menu, Popup } from 'semantic-ui-react';

import { VaccinationRoutes } from '@bluefox/models/Vaccination';

interface VaccinationRoutePickerProps {
  width?: string;
  routes?: VaccinationRoutes[];
  defaultValue?: VaccinationRoutes;
  onChange: (route: VaccinationRoutes) => void;
}

const VaccinationRoutePicker = ({
  width,
  routes = [
    VaccinationRoutes.IM,
    VaccinationRoutes.SC,
    VaccinationRoutes.PO,
    VaccinationRoutes.IN,
  ],
  defaultValue,
  onChange,
}: VaccinationRoutePickerProps) => {
  const [selected, setSelected] = useState<VaccinationRoutes | undefined>(
    defaultValue || routes.at(0)
  );

  useEffect(() => {
    if (selected) onChange(selected);
  }, [selected, onChange]);

  useEffect(() => {
    if (!defaultValue) return;
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <Popup
      on={'click'}
      flowing
      trigger={
        <Button
          size="tiny"
          icon="edit"
          content={selected}
          style={{ width: width ? width : null }}
        />
      }
    >
      <Menu>
        {routes.map((r) => {
          return (
            <Menu.Item
              active={r === selected}
              onClick={() => setSelected(r)}
              key={r}
            >
              {r}
            </Menu.Item>
          );
        })}
      </Menu>
    </Popup>
  );
};

export default VaccinationRoutePicker;
